import { Card, CustomFlowbiteTheme } from "flowbite-react";
import { FaQuestionCircle } from "react-icons/fa";
import { useAuthenticationStore } from "../model/store";
import { contactEmailAddress } from "../utils/contants";

const customCardTheme: CustomFlowbiteTheme["card"] = {
  root: {
    children: "flex h-full flex-col justify-center gap-4 p-4",
  },
};
export const NeedHelpAlert = () => {
  const user = useAuthenticationStore((state) => state.user);
  const mail_to = encodeURIComponent(
    `subject=Question about Django Compose - my Django Compose account is ${user?.email}&body=`,
  );
  return (
    <div className="max-w-fit mx-auto">
      <Card theme={customCardTheme}>
        <div className="max-w-fit flex items-center gap-2">
          <FaQuestionCircle size={20} />
          <p className="text-sm">
            Need help? Let us know at{" "}
            <a
              className="underline"
              href={`mailto:${contactEmailAddress}?${mail_to}`}
            >
              {contactEmailAddress}
            </a>
          </p>
        </div>
      </Card>
    </div>
  );
};
