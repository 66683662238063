import axios from "axios";
import Cookies from "js-cookie";
import {
  CustomHeaderName,
  JWT_AUTH_COOKIE,
  SessionStorageKey,
  notify,
} from "./contants";

const apiClient = axios.create({
  timeout: 5000,
  headers: {
    Accept: "application/json",
  },
});

apiClient.interceptors.request.use((config) => {
  const auth_token = Cookies.get(JWT_AUTH_COOKIE);
  if (auth_token != undefined && auth_token != null) {
    config.headers["Authorization"] = "Bearer " + auth_token;
  }
  config.headers[CustomHeaderName.X_DJANGO_COMPOSE_CID] =
    sessionStorage.getItem(SessionStorageKey.CORRELATION_ID);
  return config;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setupResponseInterceptor = (navigate: any) => {
  apiClient.interceptors.response.use(undefined, (error) => {
    switch (error.response.status) {
      case 401:
        Cookies.remove(JWT_AUTH_COOKIE);
        navigate("/");
        notify("Unauthorized. Try again", true);
        break;
      case 403:
        Cookies.remove(JWT_AUTH_COOKIE);
        navigate("/");
        notify("Invalid login token. Try again", true);
        break;
      default:
        return Promise.reject(error);
    }
  });
};

export default apiClient;
