import { ToastOptions, toast } from "react-toastify";
import {
  BlogTemplate,
  LibraryTemplate,
  PollTemplate,
} from "../predefined_template";
import { ProjectType } from "../fields/field";

export const documentationBaseUrl = "https://docs.djangocompose.com/";
export const websiteURL = "djangocompose.com";
export const contactEmailAddress = "support@djangocompose.com";

let apiBaseUrl = "https://api.djangocompose.com";
let ALLOW_CODE_DOWNLOAD = true;
let SENTRY_ENVIRONMENT: string | undefined = "production";
if (import.meta.env.MODE == "staging") {
  apiBaseUrl = "https://staging-api.djangocompose.com";
  SENTRY_ENVIRONMENT = "staging";
} else if (import.meta.env.MODE == "development") {
  apiBaseUrl = "http://127.0.0.1:8003";
  ALLOW_CODE_DOWNLOAD = false;
  SENTRY_ENVIRONMENT = undefined;
}

export const JWT_AUTH_COOKIE = "django-compose-auth";

let PADDLE_CLIENT_TOKEN = "test_d5355df8c1fbb82383539e93e4a";

if (import.meta.env.MODE == "production") {
  PADDLE_CLIENT_TOKEN = "live_bbdfec9952bdbd20c9e320741c0";
}

export enum LocalStorageKey {
  SELECTED_PRICE_ID = "django-compose-selected-plan",
}
export enum SessionStorageKey {
  CORRELATION_ID = "correlation-id",
}

export enum CustomHeaderName {
  X_DJANGO_COMPOSE_CID = "X-Django-Compose-Cid",
  X_DJANGO_COMPOSE_STATE = "X-Django-Compose-State",
}

export const baseColor = "#0e7490";
export const baseDarkColor = "#0891b2";

const errorTextClass = "text-red-500 bg:text-red-500 text-sm my-1";

const containerBackground = "bg-white dark:bg-gray-900";

enum RELATIONSHIP_FIELDS {
  ForeignKey = "ForeignKey",
  ManyToManyField = "ManyToManyField",
  OneToOneField = "OneToOneField",
}

enum SUPPORTED_FIELDS {
  AutoField = "AutoField",
  BigAutoField = "BigAutoField",
  BigIntegerField = "BigIntegerField",
  BinaryField = "BinaryField",
  BooleanField = "BooleanField",
  CharField = "CharField",
  DateField = "DateField",
  DateTimeField = "DateTimeField",
  DecimalField = "DecimalField",
  DurationField = "DurationField",
  EmailField = "EmailField",
  FileField = "FileField",
  FilePathField = "FilePathField",
  FloatField = "FloatField",
  GenericIPAddressField = "GenericIPAddressField",
  ImageField = "ImageField",
  IntegerField = "IntegerField",
  JSONField = "JSONField",
  PositiveBigIntegerField = "PositiveBigIntegerField",
  PositiveIntegerField = "PositiveIntegerField",
  PositiveSmallIntegerField = "PositiveSmallIntegerField",
  SlugField = "SlugField",
  SmallAutoField = "SmallAutoField",
  SmallIntegerField = "SmallIntegerField",
  TextField = "TextField",
  TimeField = "TimeField",
  URLField = "URLField",
  UUIDField = "UUIDField",
}

export const drawerGenericOptions = {
  placement: "right",
  backdrop: true,
  bodyScrolling: false,
  edge: false,
  edgeOffset: "",
  backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30",
};

export type Color = {
  name: string;
  light: string;
  dark: string;
};

const colors: Color[] = [
  { name: "Red", light: "#FF0000", dark: "#800000" },
  { name: "Red", light: "#FFCDD2", dark: "#B71C1C" },
  { name: "Pink", light: "#F8BBD0", dark: "#880E4F" },
  { name: "Purple", light: "#E1BEE7", dark: "#4A148C" },
  { name: "Deep Purple", light: "#D1C4E9", dark: "#311B92" },
  { name: "Indigo", light: "#C5CAE9", dark: "#1A237E" },
  { name: "Blue", light: "#BBDEFB", dark: "#0D47A1" },
  { name: "Light Blue", light: "#B3E5FC", dark: "#01579B" },
  { name: "Cyan", light: "#B2EBF2", dark: "#006064" },
  { name: "Teal", light: "#B2DFDB", dark: "#004D40" },
  { name: "Green", light: "#C8E6C9", dark: "#1B5E20" },
  { name: "Light Green", light: "#DCEDC8", dark: "#33691E" },
  { name: "Lime", light: "#F0F4C3", dark: "#827717" },
  { name: "Yellow", light: "#FFF9C4", dark: "#F57F17" },
  { name: "Amber", light: "#FFE082", dark: "#FF6F00" },
  { name: "Orange", light: "#FFCCBC", dark: "#E65100" },
  { name: "Deep Orange", light: "#FFAB91", dark: "#BF360C" },
  { name: "Brown", light: "#D7CCC8", dark: "#3E2723" },
  { name: "Grey", light: "#EEEEEE", dark: "#212121" },
  { name: "Blue Grey", light: "#B0BEC5", dark: "#263238" },
  { name: "Black", light: "#000000", dark: "#FFFFFF" },
  { name: "Green", light: "#00FF00", dark: "#008000" },
  { name: "Blue", light: "#0000FF", dark: "#000080" },
  { name: "Yellow", light: "#FFFF00", dark: "#808000" },
  { name: "Orange", light: "#FFA500", dark: "#FF4500" },
  { name: "Purple", light: "#800080", dark: "#4B0082" },
  { name: "Pink", light: "#FFC0CB", dark: "#FF69B4" },
  { name: "Teal", light: "#008080", dark: "#008B8B" },
  { name: "Cyan", light: "#00FFFF", dark: "#00CED1" },
  { name: "Lime", light: "#00FF00", dark: "#00FA9A" },
  { name: "Magenta", light: "#FF00FF", dark: "#FF1493" },
  { name: "Indigo", light: "#4B0082", dark: "#483D8B" },
  { name: "Brown", light: "#A52A2A", dark: "#8B4513" },
  { name: "Maroon", light: "#800000", dark: "#8B0000" },
  { name: "Navy", light: "#000080", dark: "#00008B" },
  { name: "Olive", light: "#808000", dark: "#6B8E23" },
  { name: "SkyBlue", light: "#87CEEB", dark: "#00BFFF" },
  { name: "SlateBlue", light: "#6A5ACD", dark: "#483D8B" },
  { name: "Violet", light: "#EE82EE", dark: "#8A2BE2" },
  { name: "Turquoise", light: "#40E0D0", dark: "#00CED1" },
];

const DJANGO_VERSION = "5.0";

const MODEL_FIELD_DOCS_BASE_URL = `https://docs.djangoproject.com/en/${DJANGO_VERSION}/ref/models/fields/#`;

export type Template = {
  name: string;
  templateProject: ProjectType;
};

// This should contain keys in schemas present in 'fields.tsx' or labels generated to include the SOON badge
// USE CAREFULLY as adding a generic key might disable all fields with such key e.g 'name'
export const comingSoonNamespace: string[] = ["include_email_setup"];

// This should contain keys in schemas present in 'fields.tsx' or labels generated to include the NEW badge
export const newNamespace: string[] = [];

export const PREDEFINED_TEMPLATES: Template[] = [
  { name: "Blog", templateProject: BlogTemplate },
  { name: "Poll", templateProject: PollTemplate },
  { name: "LocalLibrary", templateProject: LibraryTemplate },
];

const defaultNotifyOptions: ToastOptions<unknown> = {
  position: "top-right",
  autoClose: 8000,
};

export const notify = (
  message?: React.ReactNode | string,
  isError: boolean = false,
  options?: ToastOptions,
  isInfo: boolean = false,
) => {
  isError
    ? toast.error(message ?? "Error", options ?? defaultNotifyOptions)
    : isInfo
      ? toast.info(
          message ?? "Update Successful",
          options ?? defaultNotifyOptions,
        )
      : toast.success(
          message ?? "Update Successful",
          options ?? defaultNotifyOptions,
        );
};

export {
  SENTRY_ENVIRONMENT,
  PADDLE_CLIENT_TOKEN,
  MODEL_FIELD_DOCS_BASE_URL,
  ALLOW_CODE_DOWNLOAD,
  RELATIONSHIP_FIELDS,
  SUPPORTED_FIELDS,
  colors,
  containerBackground,
  errorTextClass,
  apiBaseUrl,
};
