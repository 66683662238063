import { Badge, Checkbox, Label, Select, TextInput } from "flowbite-react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { ZodFirstPartyTypeKind, z } from "zod";
import { errorTextClass } from "../utils/contants";
import { isComingSoon, isNew } from "../utils/utils";

interface ICustomBadge {
  namespace: string;
  featureList?: string[] | null;
}
export const CustomBadge = (props: ICustomBadge) => {
  const namespace = props.namespace;
  const isNewNamespace = isNew(namespace);
  const comingSoon = isComingSoon(namespace);
  const proList =
    props.featureList == null || props.featureList == undefined
      ? []
      : props.featureList;

  const isPro = proList.length == 0 ? false : !proList.includes(namespace);
  return (
    <div>
      {comingSoon || isNewNamespace || isPro ? (
        <Badge size="xs">
          <span className="text-xs">
            {comingSoon ? "Soon" : isPro ? "Pro" : "New"}
          </span>
        </Badge>
      ) : (
        <></>
      )}
    </div>
  );
};

export function decideFieldInputType({
  typeName,
  fieldName,
  registerHook,
  _def,
  errors,
  label,
  featureList,
}: {
  typeName: string;
  fieldName: string;
  registerHook: UseFormRegister<any>; //eslint-disable-line  @typescript-eslint/no-explicit-any
  _def: z.ZodTypeDef;
  errors: FieldErrors | null;
  label?: string | null;
  featureList?: string[] | null;
}) {
  if (label == undefined || label == null) {
    label = fieldName;
  }
  const disableField = (_fieldName: string) => {
    const proList =
      featureList == null || featureList == undefined ? [] : featureList;

    return isComingSoon(_fieldName) || !proList.includes(_fieldName);
  };
  switch (typeName) {
    case ZodFirstPartyTypeKind.ZodOptional: {
      return decideFieldInputType({
        typeName: (_def as z.ZodOptionalDef).innerType._def.typeName,
        fieldName: fieldName,
        registerHook: registerHook,
        _def: (_def as z.ZodOptionalDef).innerType._def,
        errors: errors,
        label: label,
        featureList: featureList,
      });
    }
    case ZodFirstPartyTypeKind.ZodNullable: {
      return decideFieldInputType({
        typeName: (_def as z.ZodNullableDef).innerType._def.typeName,
        fieldName: fieldName,
        registerHook: registerHook,
        _def: (_def as z.ZodNullableDef).innerType._def,
        errors: errors,
        label: label,
        featureList: featureList,
      });
    }
    case ZodFirstPartyTypeKind.ZodDefault: {
      return decideFieldInputType({
        typeName: (_def as z.ZodDefaultDef).innerType._def.typeName,
        fieldName: fieldName,
        registerHook: registerHook,
        _def: (_def as z.ZodDefaultDef).innerType._def,
        errors: errors,
        label: label,
        featureList: featureList,
      });
    }
    case ZodFirstPartyTypeKind.ZodNumber || ZodFirstPartyTypeKind.ZodBigInt:
      return (
        <>
          <div className="mb-1 flex justify-between items-center">
            <Label htmlFor={fieldName + "Label"} value={label} />
            <CustomBadge namespace={fieldName} featureList={featureList} />
          </div>
          <TextInput
            sizing="sm"
            type="number"
            key={fieldName + "key"}
            {...registerHook(fieldName, {
              valueAsNumber: true,
              disabled: isComingSoon(fieldName),
            })}
          />
          <div className={errorTextClass}>
            {errors && errors[fieldName]?.message && (
              <p>{errors[fieldName]!.message!.toString()}</p>
            )}
          </div>
        </>
      );
    case ZodFirstPartyTypeKind.ZodBoolean:
      return (
        <>
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <Checkbox
                id={fieldName as string}
                {...registerHook(fieldName, {
                  disabled: isComingSoon(fieldName),
                })}
                key={fieldName + "key"}
              />
              <Label
                htmlFor={fieldName as string}
                className={disableField(fieldName) ? "opacity-55" : ""}
              >
                {label}
              </Label>
            </div>
            <div>
              <CustomBadge namespace={fieldName} featureList={featureList} />
            </div>
          </div>
          <div className={errorTextClass}>
            {errors && errors[fieldName]?.message && (
              <p>{errors[fieldName]!.message?.toString()}</p>
            )}
          </div>
        </>
      );
    case ZodFirstPartyTypeKind.ZodEnum:
      return (
        <div>
          <div className="mb-1 flex justify-between items-center">
            <Label
              htmlFor={fieldName as string}
              className={disableField(fieldName) ? "opacity-75" : ""}
            >
              {label}
            </Label>
            <CustomBadge namespace={fieldName} featureList={featureList} />
          </div>
          <Select
            sizing="sm"
            {...registerHook(fieldName, {
              disabled: isComingSoon(fieldName),
            })}
            key={fieldName + "key"}
          >
            {(_def as z.ZodEnumDef).values.map((value) => (
              <option key={value + "key"}>{value}</option>
            ))}
          </Select>
        </div>
      );
    default:
      return (
        <>
          <div className="mb-1 flex justify-between items-center">
            <Label
              htmlFor={fieldName + "Label"}
              className={disableField(fieldName) ? "opacity-75" : ""}
              value={label}
            />
            <CustomBadge namespace={fieldName} featureList={featureList} />
          </div>
          <TextInput
            sizing="sm"
            type="text"
            key={fieldName + "key"}
            {...registerHook(fieldName, {
              disabled: isComingSoon(fieldName),
            })}
          />
          <div className={errorTextClass}>
            {errors && errors[fieldName]?.message && (
              <p>{errors[fieldName]!.message!.toString()}</p>
            )}
          </div>
        </>
      );
  }
}
