import { contactEmailAddress, websiteURL } from "../../utils/contants";
import { Footer } from "../footer";
import { NavBar } from "../navbar";

export const TermsAndConditon = () => {
  return (
    <div>
      <NavBar
        extraClass="fixed w-full z-20 top-0 start-0"
        activeLink="licenses"
      />
      <section className="mt-20 pt-12 pb-16 px-4 mx-auto max-w-screen-xl  lg:px-6">
        <div className="px-4 max-w-screen-lg mx-auto pb-12 lg:px-6">
          <h4 className="text-3xl  my-5">Terms and Conditions</h4>
          These terms and conditions ("Agreement") sets forth the general terms
          and conditions of your use of the {websiteURL} website ("Website" or
          "Service") and any of its related products and services (collectively,
          "Services"). This Agreement is legally binding between you ("User",
          "you" or "your") and Django Compose ("we", "us" or "our"). By
          accessing and using the Website and Services, you acknowledge that you
          have read, understood, and agree to be bound by the terms of this
          Agreement. If you are entering into this Agreement on behalf of a
          business or other legal entity, you represent that you have the
          authority to bind such entity to this Agreement, in which case the
          terms "User", "you" or "your" shall refer to such entity. If you do
          not have such authority, or if you do not agree with the terms of this
          Agreement, you must not accept this Agreement and may not access and
          use the Website and Services. You acknowledge that this Agreement is a
          contract between you and Django Compose, even though it is electronic
          and is not physically signed by you, and it governs your use of the
          Website and Services. These terms and conditions also include our
          Disclaimer, Acceptable use policy and our DMCA Policy.
          <h4 className="text-xl my-5">Accounts and membership</h4>
          You must be at least 13 years of age to use the Website and Services.
          By using the Website and Services and by agreeing to this Agreement
          you warrant and represent that you are at least 13 years of age. If
          you create an account on the Website, you are responsible for
          maintaining the security of your account and you are fully responsible
          for all activities that occur under the account and any other actions
          taken in connection with it. We may, but have no obligation to,
          monitor and review new accounts before you may sign in and start using
          the Services. Providing false contact information of any kind may
          result in the termination of your account. You must immediately notify
          us of any unauthorized uses of your account or any other breaches of
          security. We will not be liable for any acts or omissions by you,
          including any damages of any kind incurred as a result of such acts or
          omissions. We may suspend, disable, or delete your account (or any
          part thereof) if we determine that you have violated any provision of
          this Agreement or that your conduct or content would tend to damage
          our reputation and goodwill. If we delete your account for the
          foregoing reasons, you may not re-register for our Services. We may
          block your email address and Internet protocol address to prevent
          further registration.
          <h4 className="text-xl my-5">User content</h4>
          We do not own any data, information or material (collectively,
          "Content") that you submit on the Website in the course of using the
          Service. You shall have sole responsibility for the accuracy, quality,
          integrity, legality, reliability, appropriateness, and intellectual
          property ownership or right to use of all submitted Content. We may,
          but have no obligation to, monitor and review the Content on the
          Website submitted or created using our Services by you. You grant us
          permission to access, copy, distribute, store, transmit, reformat,
          display and perform the Content of your user account solely as
          required for the purpose of providing the Services to you. Without
          limiting any of those representations or warranties, we have the
          right, though not the obligation, to, in our own sole discretion,
          refuse or remove any Content that, in our reasonable opinion, violates
          any of our policies or is in any way harmful or objectionable. You
          also grant us the license to use, reproduce, adapt, modify, publish or
          distribute the Content created by you or stored in your user account
          for commercial, marketing or any similar purpose.
          <h4 className="text-xl my-5">Billing and payments</h4>
          You shall pay all fees or charges to your account in accordance with
          the fees, charges, and billing terms in effect at the time a fee or
          charge is due and payable. Where Services are offered on a free trial
          basis, payment may be required after the free trial period ends, and
          not when you enter your billing details (which may be required prior
          to the commencement of the free trial period). If auto-renewal is
          enabled for the Services you have subscribed for, you will be charged
          automatically in accordance with the term you selected. If, in our
          judgment, your purchase constitutes a high-risk transaction, we will
          require you to provide us with a copy of your valid government-issued
          photo identification, and possibly a copy of a recent bank statement
          for the credit or debit card used for the purchase. We reserve the
          right to change products and product pricing at any time. We also
          reserve the right to refuse any order you place with us. We may, in
          our sole discretion, limit or cancel quantities purchased per person,
          per household or per order. These restrictions may include orders
          placed by or under the same customer account, the same credit card,
          and/or orders that use the same billing and/or shipping address. In
          the event that we make a change to or cancel an order, we may attempt
          to notify you by contacting the e-mail and/or billing address/phone
          number provided at the time the order was made.
          <h4 className="text-xl my-5">Accuracy of information</h4>
          Occasionally there may be information on the Website that contains
          typographical errors, inaccuracies or omissions that may relate to
          product descriptions, availability, promotions and offers. We reserve
          the right to correct any errors, inaccuracies or omissions, and to
          change or update information or cancel orders if any information on
          the Website or Services is inaccurate at any time without prior notice
          (including after you have submitted your order). We undertake no
          obligation to update, amend or clarify information on the Website
          including, without limitation, pricing information, except as required
          by law. No specified update or refresh date applied on the Website
          should be taken to indicate that all information on the Website or
          Services has been modified or updated.
          <h4 className="text-xl my-5">Backups</h4>
          We perform regular backups of the Website and its Content, however,
          these backups are for our own administrative purposes only and are in
          no way guaranteed. You are responsible for maintaining your own
          backups of your data. We do not provide any sort of compensation for
          lost or incomplete data in the event that backups do not function
          properly. We will do our best to ensure complete and accurate backups,
          but assume no responsibility for this duty.
          <h4 className="text-xl my-5">Advertisements</h4>
          During your use of the Website and Services, you may enter into
          correspondence with or participate in promotions of advertisers or
          sponsors showing their goods or services through the Website and
          Services. Any such activity, and any terms, conditions, warranties or
          representations associated with such activity, is solely between you
          and the applicable third party. We shall have no liability, obligation
          or responsibility for any such correspondence, purchase or promotion
          between you and any such third party.
          <h4 className="text-xl my-5">Links to other resources</h4>
          Although the Website and Services may link to other resources (such as
          websites, mobile applications, etc.), we are not, directly or
          indirectly, implying any approval, association, sponsorship,
          endorsement, or affiliation with any linked resource, unless
          specifically stated herein. Some of the links on the Website may be
          "affiliate links". This means if you click on the link and purchase an
          item, Django Compose will receive an affiliate commission. We are not
          responsible for examining or evaluating, and we do not warrant the
          offerings of, any businesses or individuals or the content of their
          resources. We do not assume any responsibility or liability for the
          actions, products, services, and content of any other third parties.
          You should carefully review the legal statements and other conditions
          of use of any resource which you access through a link on the Website
          and Services. Your linking to any other off-site resources is at your
          own risk.
          <h4 className="text-xl my-5">Prohibited uses</h4>
          In addition to other terms as set forth in the Agreement, you are
          prohibited from using the Website and Services or Content: (a) for any
          unlawful purpose; (b) to solicit others to perform or participate in
          any unlawful acts; (c) to violate any international, federal,
          provincial or state regulations, rules, laws, or local ordinances; (d)
          to infringe upon or violate our intellectual property rights or the
          intellectual property rights of others; (e) to harass, abuse, insult,
          harm, defame, slander, disparage, intimidate, or discriminate based on
          gender, sexual orientation, religion, ethnicity, race, age, national
          origin, or disability; (f) to submit false or misleading information;
          (g) to upload or transmit viruses or any other type of malicious code
          that will or may be used in any way that will affect the functionality
          or operation of the Website and Services, third party products and
          services, or the Internet; (h) to spam, phish, pharm, pretext, spider,
          crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to
          interfere with or circumvent the security features of the Website and
          Services, third party products and services, or the Internet. We
          reserve the right to terminate your use of the Website and Services
          for violating any of the prohibited uses.
          <h4 className="text-xl my-5">Limitation of liability</h4>
          To the fullest extent permitted by applicable law, in no event will
          Django Compose, its affiliates, directors, officers, employees,
          agents, suppliers or licensors be liable to any person for any
          indirect, incidental, special, punitive, cover or consequential
          damages (including, without limitation, damages for lost profits,
          revenue, sales, goodwill, use of content, impact on business, business
          interruption, loss of anticipated savings, loss of business
          opportunity) however caused, under any theory of liability, including,
          without limitation, contract, tort, warranty, breach of statutory
          duty, negligence or otherwise, even if the liable party has been
          advised as to the possibility of such damages or could have foreseen
          such damages. To the maximum extent permitted by applicable law, the
          aggregate liability of Django Compose and its affiliates, officers,
          employees, agents, suppliers and licensors relating to the services
          will be limited to an amount greater of one dollar. The limitations
          and exclusions also apply if this remedy does not fully compensate you
          for any losses or fails of its essential purpose.
          <h4 className="text-xl my-5">Indemnification</h4>
          You agree to indemnify and hold Django Compose and its affiliates,
          directors, officers, employees, agents, suppliers and licensors
          harmless from and against any liabilities, losses, damages or costs,
          including reasonable attorneys' fees, incurred in connection with or
          arising from any third party allegations, claims, actions, disputes,
          or demands asserted against any of them as a result of or relating to
          your Content, your use of the Website and Services or any willful
          misconduct on your part.
          <h4 className="text-xl my-5">Severability</h4>
          All rights and restrictions contained in this Agreement may be
          exercised and shall be applicable and binding only to the extent that
          they do not violate any applicable laws and are intended to be limited
          to the extent necessary so that they will not render this Agreement
          illegal, invalid or unenforceable. If any provision or portion of any
          provision of this Agreement shall be held to be illegal, invalid or
          unenforceable by a court of competent jurisdiction, it is the
          intention of the parties that the remaining provisions or portions
          thereof shall constitute their agreement with respect to the subject
          matter hereof, and all such remaining provisions or portions thereof
          shall remain in full force and effect.
          <h4 className="text-xl my-5">Changes and amendments</h4>
          We reserve the right to modify this Agreement or its terms relating to
          the Website and Services at any time, effective upon posting of an
          updated version of this Agreement on the Website. When we do, we will
          send you an email to notify you. Continued use of the Website and
          Services after any such changes shall constitute your consent to such
          changes.
          <h4 className="text-xl my-5">Acceptance of these terms</h4>
          You acknowledge that you have read this Agreement and agree to all its
          terms and conditions. By accessing and using the Website and Services
          you agree to be bound by this Agreement. If you do not agree to abide
          by the terms of this Agreement, you are not authorized to access or
          use the Website and Services.
          <h4 className="text-xl my-5">Contacting us</h4>
          If you would like to contact us to understand more about this
          Agreement or wish to contact us concerning any matter relating to it,
          you may send an email to{" "}
          <a className="underline" href={`mailto:${contactEmailAddress}`}>
            {contactEmailAddress}
          </a>
          <p className="my-12">
            This document was last updated on July 17, 2024
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};
