import { Label, Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIPriceSchemaType } from "../../fields/field";
import { useAuthenticationStore, useProfileStore } from "../../model/store";
import { LocalStorageKey, notify } from "../../utils/contants";
import { FAQSection } from "../faq";
import { Footer } from "../footer";
import { NavBar } from "../navbar";
import { PricingCard } from "./pricing";

export const PricingPage = () => {
  const [priceList, fetchPriceList] = useProfileStore((state) => [
    state.prices,
    state.fetchPriceList,
  ]);
  const inProgress = useProfileStore((state) => state.in_progress);
  const paddleInstance = useProfileStore((state) => state.paddleInstance);
  const customInitializePaddle = useProfileStore(
    (state) => state.customInitializePaddle,
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (priceList == null) {
      fetchPriceList();
    }
    if (paddleInstance == undefined) {
      customInitializePaddle(navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceList, fetchPriceList]);

  // Use this to show price in local currency!
  // useEffect(() => {
  //     axios.get('https://api.ipify.org').then((response) => {
  //       fetchPriceList(response.data);
  //     }).catch((error) => {
  //       fetchPriceList();
  //     })
  // }, [fetchPriceList]);

  // const [paddle, setPaddle] = useState<Paddle>();
  const user = useAuthenticationStore((state) => state.user);

  // If selected plan is found in localStorage, use that to continue purchase flow
  const selectedPricePlanFromStorage = localStorage.getItem(
    LocalStorageKey.SELECTED_PRICE_ID,
  );
  localStorage.removeItem(LocalStorageKey.SELECTED_PRICE_ID);

  const [selectedPrice, setSelectedPrice] = useState<APIPriceSchemaType | null>(
    null,
  );
  const getAuthCode = useAuthenticationStore((state) => state.getAuthCode);

  useEffect(() => {
    if (user) {
      if (selectedPricePlanFromStorage) {
        paddleInstance?.Checkout.open({
          items: [
            {
              priceId: selectedPricePlanFromStorage,
              quantity: 1,
            },
          ],
          customer: {
            email: user!.email,
          },
          customData: {
            userId: user!.id,
          },
        });
      } else {
        if (selectedPrice) {
          paddleInstance?.Checkout.open({
            items: [
              {
                priceId: selectedPrice.id,
                quantity: 1,
              },
            ],
            customer: {
              email: user!.email,
            },
            customData: {
              userId: user!.id,
            },
          });
        }
      }
    } else {
      if (selectedPrice) {
        if (!selectedPrice.is_free) {
          notify("Please log in to purchase a plan.");
          localStorage.setItem(
            LocalStorageKey.SELECTED_PRICE_ID,
            selectedPrice.id,
          ); // Save the selected price plan the user clicked
        }
        getAuthCode(); // Redirect user to login
        notify("Authenticating with Github", false, undefined, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrice, getAuthCode]);

  return (
    <>
      <NavBar
        extraClass="fixed w-full z-20 top-0 start-0"
        activeLink="pricing"
      />

      <section className="mt-20 pt-12 pb-16 px-4 mx-auto max-w-screen-xl  lg:px-6">
        <div className="px-4 max-w-screen-lg pb-12 lg:px-6">
          <Label className="text-4xl font-semibold">
            Simple pricing for projects at any stage
          </Label>

          <div className="mt-2">
            <Label className="text-xl font-light">
              Whether you're working on side projects, aspiring to build a
              unicorn, or developing B2B SaaS apps, we offer flat, one-time
              pricing to meet your needs.
            </Label>
          </div>
        </div>

        <div className="px-4 mx-auto max-w-screen-xl pb-8 lg:px-6">
          {inProgress ? (
            <div className="w-full text-center">
              <Spinner size="xl" aria-label="loading" />
            </div>
          ) : (
            priceList && (
              <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                {priceList.length == 0 ? (
                  <div className="col-span-3 text-center border p-2">
                    <p className="text-2xl">No Pricing</p>
                  </div>
                ) : (
                  priceList.map((pricingPlan, index) => (
                    <PricingCard
                      price={pricingPlan}
                      setSelectedPrice={setSelectedPrice}
                      key={"PricingCard" + index}
                    />
                  ))
                )}
              </div>
            )
          )}
        </div>
        <FAQSection />
      </section>

      <Footer />
      {/* {inProgress ? <Loader text="Please wait!" /> : <></>} */}
    </>
  );
};
