import { Footer } from "../footer";
import { NavBar } from "../navbar";

import { contactEmailAddress, websiteURL } from "../../utils/contants";

export const LegalLicense = () => {
  return (
    <div>
      <NavBar
        extraClass="fixed w-full z-20 top-0 start-0"
        activeLink="licenses"
      />
      <section className="mt-20 pt-12 pb-16 px-4 mx-auto max-w-screen-xl  lg:px-6">
        <div className="px-4 max-w-screen-lg mx-auto pb-12 lg:px-6">
          <h4 className="text-3xl  my-5">License Agreement</h4>
          TL;DR; <br />
          <br />
          Personal License: Build unlimited projects as an individual. <br />
          <br />
          Team License: Build unlimited projects as a team. <br />
          <br />
          This License Agreement ("Agreement") is entered into between
          DjangoCompose, whose contact information is {contactEmailAddress}, and
          you, the user ("Licensee"), regarding the use of the Django
          Compose(the "Product") available at {websiteURL} (the "Website"). By
          downloading, accessing, or using the Product, Licensee agrees to be
          bound by the terms and conditions of this Agreement.
          <h3 className="text-xl my-5">1. Grant of License</h3>
          <h4 className="text-lg my-5">1.1 Personal License</h4>
          Subject to the terms and conditions of this Agreement, Django Compose
          grants Licensee a non-exclusive, non-transferable, and
          non-sublicensable Personal License to use the Django Compose
          downloaded codebase for the following purposes: <br />
          <br />
          Create unlimited projects. <br />
          <br />
          Build and develop applications or websites for personal use or
          commercial use.
          <h4 className="text-lg my-5">1.2 Team License</h4>
          Subject to the terms and conditions of this Agreement, Django Compose
          grants Licensee a non-exclusive, non-transferable, and
          non-sublicensable Team License to use the Django Compose downloaded
          codebase for the following purposes: <br />
          <br />
          Create unlimited projects. <br />
          <br />
          Build and develop applications or websites as part of a team. <br />
          <br />
          Share the code with other members of the team. <br />
          <h4 className="text-xl my-5">2. Restrictions</h4>
          Licensee shall not: <br />
          <br />
          Modify, adapt, reverse engineer, decompile, disassemble, or create
          derivative works based on the downloaded codebase. <br />
          <br />
          Remove, alter, or obscure any copyright, trademark, or other
          proprietary notices from the downloaded codebase. <br />
          <br />
          Use the downloaded codebase from Django Compose in any way that
          violates applicable laws, regulations, or third-party rights. <br />
          <br />
          Sub-license, rent, lease, or transfer the downloaded codebase or any
          rights granted under this Agreement.
          <h4 className="text-xl my-5">
            3. Ownership and Intellectual Property
          </h4>
          Django Compose retains all ownership and intellectual property rights
          in and to the codebase. This Agreement does not grant Licensee any
          ownership rights in the codebase.
          <h4 className="text-xl my-5">4. Warranty and Disclaimer</h4>
          THE DOWNLOADED CODEBASE FROM Django Compose IS PROVIDED "AS IS"
          WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
          BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT.
          <h4 className="text-xl my-5">5. Limitation of Liability</h4>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, DJANGCOCOMPOSE
          SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO THE
          USE OR INABILITY TO USE THE DOWNLOADED CODEBASE, EVEN IF Django
          ComposeHAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          <h4 className="text-xl my-5">6. Governing Law and Jurisdiction</h4>
          This Agreement will be governed by the laws of the jurisdiction where
          the Company is established, without regard to its conflict of law
          provisions.
          <h4 className="text-xl my-5">7. Entire Agreement</h4>
          This Agreement constitutes the entire agreement between Licensee and
          Django Composeconcerning the subject matter herein and supersedes all
          prior or contemporaneous agreements, representations, warranties, and
          understandings.
          <h4 className="text-xl my-5"> 8. Contacting us</h4>
          If you have any questions about these Terms, you may send an email to{" "}
          <a className="underline" href={`mailto:${contactEmailAddress}`}>
            {contactEmailAddress}
          </a>
          <p className="my-12">
            This document was last updated on July 16, 2024
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};
