import { Spinner } from "flowbite-react";

interface ILoader {
  text: string;
}

export const Loader: React.FC<ILoader> = (props: ILoader) => {
  return (
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-600 opacity-75 flex flex-col items-center justify-center">
        <div
          role="status"
          className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
        >
          <div className="flex flex-col items-center">
            <Spinner size="lg" aria-label="spinner" />
            <h5 className="mt-1 text-white">{props.text}</h5>
          </div>
        </div>
      </div>
    </>
  );
};
