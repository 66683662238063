import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  APIUserSchemaType,
  AllRelationFields,
  AppSchema,
  FieldToSchemaMap,
  ModelSchema,
  ModelType,
  RelationFieldToSchemaMap,
} from "../fields/field";
import {
  Color,
  RELATIONSHIP_FIELDS,
  SessionStorageKey,
  colors,
  comingSoonNamespace,
  newNamespace,
} from "./contants";

import { v4 as uuidv4 } from "uuid";

export const getOrSetCorrelationId = () => {
  let correlationId = sessionStorage.getItem(SessionStorageKey.CORRELATION_ID);

  if (!correlationId) {
    correlationId = uuidv4();
    sessionStorage.setItem(SessionStorageKey.CORRELATION_ID, correlationId);
  }
};

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function getAppColor(index: number): Color {
  index = Math.abs(index);
  index = index <= colors.length ? index : index % colors.length;
  return colors[index];
}

function getSingleFieldToSchemaMap(fieldType: string) {
  return [...RelationFieldToSchemaMap, ...FieldToSchemaMap].find(
    (value) => value.type == fieldType,
  );
}

export function isIdentifier(str: string | undefined): boolean {
  const identifierRegex = /^[a-zA-Z_$][0-9a-zA-Z_$]*$/;
  return identifierRegex.test(str ?? "");
}

export const hasSourceRelationField = (
  model: ModelType,
): AllRelationFields[] | null => {
  const allRelationFields: AllRelationFields[] = [];
  for (let index = 0; index < model.fields.length; index++) {
    const field = model.fields[index];
    if (Object.keys(RELATIONSHIP_FIELDS).includes(field.field.type)) {
      allRelationFields.push(field.field as AllRelationFields);
    }
  }
  return allRelationFields.length == 0 ? null : allRelationFields;
};

export const AUTH_APP_ID = "AUTH_APP_ID";
export const AUTH_APP_MODEL_ID = "AUTH_APP_MODEL_ID";
export const createAuthAppModel = () => {
  return AppSchema.parse({
    name: "authentication",
    appId: AUTH_APP_ID,
    models: [
      ModelSchema.parse({
        modelId: AUTH_APP_MODEL_ID,
        appId: AUTH_APP_ID,
        name: "User",
      }),
    ],
  });
};

export const isComingSoon = (namespace: string): boolean =>
  comingSoonNamespace.includes(namespace);

export const isNew = (namespace: string): boolean =>
  newNamespace.includes(namespace);

export const getFullName = (user: APIUserSchemaType | null): string => {
  if (user?.first_name && user?.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }
  return "-";
};

export const getFullNameInitials = (user: APIUserSchemaType | null) => {
  const splittedName = getFullName(user).split(" ");

  if (splittedName.length == 2) {
    return `${splittedName[0][0]} ${splittedName[1][0]}`.toUpperCase();
  }

  return null;
};

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export { capitalize, dayjs, getAppColor, getSingleFieldToSchemaMap };
