import { Buffer } from "buffer";
import { Label, Spinner } from "flowbite-react";
import { useEffect } from "react";
import { FaCircle, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { APIProjectSchemaType, ProjectSchema } from "../../fields/field";
import {
  useAuthenticationStore,
  useFlowStore,
  useModalStore,
  useProfileStore,
  useProjectStore,
} from "../../model/store";
import { notify } from "../../utils/contants";
import { dayjs } from "../../utils/utils";

export const ProjectList: React.FC = () => {
  const [projectList, fetchProjectList] = useProfileStore((state) => [
    state.projects,
    state.fetchProjectList,
  ]);
  const inProgress = useProfileStore((state) => state.in_progress);

  const user = useAuthenticationStore((state) => state.user);
  const getAPIUser = useAuthenticationStore((state) => state.getAPIUser);

  useEffect(() => {
    // try to get the user data once again with cache miss
    getAPIUser(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchProjectList();
  }, [fetchProjectList]);

  return (
    <div className="max-w-screen-md mx-auto">
      <div className="mb-3">
        <Label className="text-2xl">Projects</Label>
      </div>
      {inProgress ? (
        <div className="w-full text-center">
          <Spinner size="xl" aria-label="loading" />
        </div>
      ) : (
        <div>
          {projectList.length != 0 ? (
            projectList.map((project) => {
              return (
                <div
                  key={project.id}
                  className="hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  <hr className="h-px border-0 bg-gray-200 dark:bg-gray-700" />
                  <SingleProject project={project} />
                </div>
              );
            })
          ) : user?.has_active_subscription ? (
            user?.is_using_free_plan ? (
              <WelcomeMessageNoSubsription />
            ) : (
              <div className="text-center">
                <hr className="h-px border-0 bg-gray-200 dark:bg-gray-700" />
                <div className="my-4">
                  <Label className="text-lg">No Projects.</Label>
                </div>
              </div>
            )
          ) : (
            <WelcomeMessageNoSubsription />
          )}
        </div>
      )}
    </div>
  );
};

interface ISingleProject {
  project: APIProjectSchemaType;
}

export const SingleProject: React.FC<ISingleProject> = (
  props: ISingleProject,
) => {
  const { project } = props;

  const setProject = useProjectStore((state) => state.setProject);
  const setIsEditMode = useProjectStore((state) => state.setIsEditMode);
  const setNodes = useFlowStore((state) => state.setNodes);
  const nodes = useFlowStore((state) => state.nodes);
  const setOpenConfirmBox = useModalStore((state) => state.setOpenConfirmBox);

  const navigate = useNavigate();

  const _onConfirm = (base64CodeSpec: string) => {
    const _nodes = nodes;
    const codeSpec = Buffer.from(base64CodeSpec, "base64").toString();
    try {
      setNodes([]);
      setProject(ProjectSchema.parse(JSON.parse(codeSpec)));
      setIsEditMode(true);
      notify("Project successfully loaded.");
      navigate("/studio");
    } catch (Error) {
      setNodes(_nodes);
      notify("Error loading project.", true);
    }
  };

  return (
    <div
      className="cursor-pointer p-3"
      onClick={() => {
        setOpenConfirmBox({
          open: true,
          title: "Load Project",
          description: "This will overwrite any work in progress",
          onConfirm: () => _onConfirm(project.code_spec),
          onCancel: () => setOpenConfirmBox(null),
        });
      }}
    >
      <div className="flex items-center gap-4">
        <div>
          <FaCircle size={8} />
        </div>
        <div className="flex-grow">
          <div className="flex justify-between">
            <Label className="font-semi-bold text-xl">
              {project.name} (<span className="text-base">x</span>
              {project.version})
            </Label>
            <FaEye />
          </div>
          <div className="flex items-center flex-wrap gap-4 pt-2 justify-start">
            <Label className="text-sm dark:text-gray-400 text-gray-600">
              Django: {project.generator_settings.django_version}
            </Label>
            <p>|</p>
            <Label className="text-sm dark:text-gray-400 text-gray-600">
              Python: {project.generator_settings.python_version}
            </Label>
            <p>|</p>
            <Label className="text-sm dark:text-gray-400 text-gray-600">
              Updated: {dayjs(project.updated_at).fromNow()}
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};

const WelcomeMessageNoSubsription = () => {
  const user = useAuthenticationStore((state) => state.user);
  return (
    <div>
      <hr className="h-px border-0 bg-gray-200 dark:bg-gray-700 my-3 mb-4" />
      <div>
        <h3 className="text-lg font-bold">Welcome {user?.username} !</h3>
        <p className="my-6">
          We’re excited to have you here. Explore and enjoy a taste of our
          features and tools. For even more value, consider subscribing to one
          of our plans.
        </p>
        <p className="text-base my-3">Why Subscribe?</p>
        <ul className="list-disc ml-8 space-y-2">
          <li>Unlimited Access to premium features</li>
          <li>Priority Support from our dedicated team</li>
          <li>Exclusive Content and resources</li>
          <li>Special Offers just for members</li>
        </ul>
        <p className="mt-6">
          Ready to elevate your experience? Tap the below <b>"Upgrade Plan"</b>{" "}
          and unlock these benefits.
        </p>
      </div>
    </div>
  );
};
