import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Modal, TextInput } from "flowbite-react";
import { nanoid } from "nanoid";
import { FC, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { z } from "zod";
import { useShallow } from "zustand/react/shallow";
import { AppSchema } from "../fields/field";
import { Action, useModalStore, useProjectStore } from "../model/store";
import { AUTH_APP_ID } from "../utils/utils";

const AddNewAppForm: FC = (): JSX.Element => {
  const SemiAppSchema = AppSchema.omit({
    models: true,
    appId: true,
  }).required();

  type SemiAppType = z.infer<typeof SemiAppSchema>;
  const upsertApp = useProjectStore((state) => state.upsertApp);

  const projectName = useProjectStore(
    useShallow((state) => ({ name: state.project.name })),
  ).name;

  const apps = useProjectStore(
    useShallow((state) => ({
      name: state.project.apps.map((app) => app.name),
    })),
  ).name;

  const openUpsertAppModal = useModalStore((state) => state.openUpsertAppModal);
  const setOpenUpsertAppModal = useModalStore(
    (state) => state.setOpenUpsertAppModal,
  );
  const activeApp = useProjectStore((state) => state.activeApp);
  const setActiveApp = useProjectStore((state) => state.setActiveApp);

  const setOpenConfirmBox = useModalStore((state) => state.setOpenConfirmBox);

  const onSubmit: SubmitHandler<SemiAppType> = (data) => {
    if (activeApp === null) {
      upsertApp({
        app: AppSchema.parse({
          name: data.name,
          appId: nanoid(),
          models: [],
        }),
        action: Action.ADD,
      });
    } else {
      upsertApp({
        app: AppSchema.parse({
          ...activeApp,
          ...{ name: data.name },
        }),
        action: Action.EDIT,
      });
    }
    resetField("name");
    setOpenUpsertAppModal(false);
  };

  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<SemiAppType>({
    resolver: zodResolver(
      SemiAppSchema.superRefine((entries, ctx) => {
        if (apps.includes(entries.name)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              "Name already exist, no duplicate app allowed within project.",
            path: ["name"],
          });
        }
      }),
    ),
  });

  useEffect(() => {
    if (activeApp !== null) {
      setValue("name", activeApp.name);
    } else {
      resetField("name");
    }
  }, [activeApp, setValue, resetField]);

  return (
    <>
      <Button
        size="sm"
        outline
        className={twMerge(["w-full mt-2", projectName ? "show" : "hidden"])}
        onClick={() => {
          setActiveApp(null);
          setOpenUpsertAppModal(true);
        }}
        disabled={projectName ? false : true}
      >
        Add New App
      </Button>

      <Modal
        show={openUpsertAppModal}
        size="md"
        popup
        onClose={() => setOpenUpsertAppModal(false)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6 text-gray-900 dark:text-white">
            <h4 className="text-xl font-medium">
              {activeApp ? "Edit" : "New"} App
            </h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                id="text"
                placeholder="App Name"
                {...register("name")}
                required={true}
              />

              <div className="text-red-500 bg:text-red-500 text-sm my-1">
                {errors.name?.message && (
                  <p>{errors.name!.message.toString()}</p>
                )}
              </div>

              <Button className="w-full mt-5" type="submit">
                {activeApp ? "Update App" : "Create App"}
              </Button>
              {activeApp != null && activeApp.appId != AUTH_APP_ID && (
                <Button
                  className={twMerge([
                    "w-full mt-3",
                    activeApp ? "show" : "hidden",
                  ])}
                  color="failure"
                  onClick={() => {
                    setOpenConfirmBox({
                      open: true,
                      title: "Delete App",
                      onConfirm: () => {
                        upsertApp({
                          app: AppSchema.parse({
                            ...activeApp,
                          }),
                          action: Action.DELETE,
                        });
                        setOpenUpsertAppModal(false);
                      },
                      onCancel: () => {},
                    });
                  }}
                >
                  Delete
                </Button>
              )}
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { AddNewAppForm };
