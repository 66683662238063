import { IconType } from "react-icons";
import { HiUserCircle } from "react-icons/hi";
import { z } from "zod";
import {
  MODEL_FIELD_DOCS_BASE_URL,
  RELATIONSHIP_FIELDS,
  SUPPORTED_FIELDS,
} from "../utils/contants";
import { isIdentifier } from "../utils/utils";

const NameIdentifier = z
  .string()
  .min(1, { message: "You must enter a name" })
  .refine(
    (val) => isIdentifier(val),
    (val) => ({
      message: `'${val}' is not a valid name, please make sure it is a valid identifier.`,
    }),
  );

const GenericOptionSchema = z.object({
  default: z.coerce.string().optional(),
  db_column: z.string().optional(),
  db_comment: z.string().optional(),
  help_text: z.string().optional(),
  verbose_name: z.string().optional(),
  db_index: z.boolean().default(false).optional(),
  editable: z.boolean().default(true).optional(),
  blank: z.boolean().default(false).optional(),
  null: z.boolean().default(false).optional(),
  primary_key: z.boolean().default(false).optional(),
  unique: z.boolean().default(false).optional(),
});

const AutoFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.AutoField.toString())
      .default(SUPPORTED_FIELDS.AutoField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type AutoField = z.infer<typeof AutoFieldSchema>;

const BigAutoFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.BigAutoField.toString())
      .default(SUPPORTED_FIELDS.BigAutoField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type BigAutoField = z.infer<typeof BigAutoFieldSchema>;

const BinaryFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.BinaryField.toString())
      .default(SUPPORTED_FIELDS.BinaryField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type BinaryField = z.infer<typeof BinaryFieldSchema>;

const BooleanFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.BooleanField.toString())
      .default(SUPPORTED_FIELDS.BooleanField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type BooleanField = z.infer<typeof BooleanFieldSchema>;

const CharFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.CharField.toString())
      .default(SUPPORTED_FIELDS.CharField.toString()),
    // .readonly(),
    max_length: z.number().optional().default(200),
  })
  .merge(GenericOptionSchema);
export type CharField = z.infer<typeof CharFieldSchema>;

const BigIntegerFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.BigIntegerField.toString())
      .default(SUPPORTED_FIELDS.BigIntegerField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type BigIntegerField = z.infer<typeof BigIntegerFieldSchema>;

const DateFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.DateField.toString())
      .default(SUPPORTED_FIELDS.DateField.toString()),
    // .readonly(),
    auto_now: z.boolean().default(false).optional(),
    auto_now_add: z.boolean().default(false).optional(),
  })
  .merge(GenericOptionSchema);
export type DateField = z.infer<typeof DateFieldSchema>;

const DateTimeFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.DateTimeField.toString())
      .default(SUPPORTED_FIELDS.DateTimeField.toString()),
    // .readonly(),
    auto_now: z.boolean().default(false).optional(),
    auto_now_add: z.boolean().default(false).optional(),
  })
  .merge(GenericOptionSchema);
export type DateTimeField = z.infer<typeof DateTimeFieldSchema>;

const DecimalFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.DecimalField.toString())
      .default(SUPPORTED_FIELDS.DecimalField.toString()),
    // .readonly(),
    max_digits: z.number().optional(),
    decimal_places: z.number().optional(),
  })
  .merge(GenericOptionSchema)
  .superRefine((entries, ctx) => {
    if (entries.max_digits && entries.decimal_places) {
      if (!(entries.max_digits >= entries.decimal_places)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["max_digits"],
          message:
            "max_digit number must be greater than or equal to decimal_places",
        });
      }
      return z.NEVER;
    }
  });
export type DecimalField = z.infer<typeof DecimalFieldSchema>;

const DurationFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.DurationField.toString())
      .default(SUPPORTED_FIELDS.DurationField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type DurationField = z.infer<typeof DurationFieldSchema>;

const EmailFieldSchema = CharFieldSchema.extend({
  type: z
    .literal(SUPPORTED_FIELDS.EmailField.toString())
    .default(SUPPORTED_FIELDS.EmailField.toString()),
  // .readonly(),
  max_length: z.number().default(254).optional(),
}).merge(GenericOptionSchema);
export type EmailField = z.infer<typeof EmailFieldSchema>;

const FileFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.FileField.toString())
      .default(SUPPORTED_FIELDS.FileField.toString()),
    // .readonly(),
    upload_to: z.string().default("").optional(),
    max_length: z.number().default(100).optional(),
  })
  .merge(GenericOptionSchema)
  .superRefine((entries, ctx) => {
    if (entries.primary_key != undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["primary_key"],
        message: "The primary_key argument is not supported",
      });
    }
    return z.NEVER;
  });
export type FileField = z.infer<typeof FileFieldSchema>;

const FilePathFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.FilePathField.toString())
      .default(SUPPORTED_FIELDS.FilePathField.toString()),
    // .readonly(),
    path: z.string().default(""),
    match: z.string().optional(),
    recursive: z.boolean().default(false).optional(),
    allow_files: z.boolean().default(true).optional(),
    allow_folders: z.boolean().default(false).optional(),
    max_length: z.number().default(100).optional(),
  })
  .merge(GenericOptionSchema);
export type FilePathField = z.infer<typeof FilePathFieldSchema>;

const FloatFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.FloatField.toString())
      .default(SUPPORTED_FIELDS.FloatField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type FloatField = z.infer<typeof FloatFieldSchema>;

const GenericIPAddressFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.GenericIPAddressField.toString())
      .default(SUPPORTED_FIELDS.GenericIPAddressField.toString()),
    // .readonly(),
    protocol: z.enum(["both", "IPv4", "IPv6"]).default("both").optional(),
    unpack_ipv4: z.boolean().default(false).optional(),
  })
  .merge(GenericOptionSchema)
  .superRefine((entries, ctx) => {
    if (entries.protocol && entries.unpack_ipv4) {
      if (entries.protocol != "both" && entries.unpack_ipv4 == true) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["unpack_ipv4"],
          message:
            "unpack_ipv4 can only be used when protocol is set to 'both'",
        });
      }
    }
    return z.NEVER;
  });
export type GenericIPAddressField = z.infer<typeof GenericIPAddressFieldSchema>;

const ImageFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.ImageField.toString())
      .default(SUPPORTED_FIELDS.ImageField.toString()),
    // .readonly(),
    upload_to: z.string().default("").optional(),
    max_length: z.number().default(100).optional(),
    height_field: z.string().optional(),
    width_field: z.string().optional(),
  })
  .merge(GenericOptionSchema);
export type ImageField = z.infer<typeof ImageFieldSchema>;

const IntegerFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.IntegerField.toString())
      .default(SUPPORTED_FIELDS.IntegerField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type IntegerField = z.infer<typeof IntegerFieldSchema>;

const JSONFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.JSONField.toString())
      .default(SUPPORTED_FIELDS.JSONField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type JSONField = z.infer<typeof JSONFieldSchema>;

const PositiveBigIntegerFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.PositiveBigIntegerField.toString())
      .default(SUPPORTED_FIELDS.PositiveBigIntegerField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type PositiveBigIntegerField = z.infer<
  typeof PositiveBigIntegerFieldSchema
>;

const PositiveIntegerFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.PositiveIntegerField.toString())
      .default(SUPPORTED_FIELDS.PositiveIntegerField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type PositiveIntegerField = z.infer<typeof PositiveIntegerFieldSchema>;

const PositiveSmallIntegerFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.PositiveSmallIntegerField.toString())
      .default(SUPPORTED_FIELDS.PositiveSmallIntegerField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type PositiveSmallIntegerField = z.infer<
  typeof PositiveSmallIntegerFieldSchema
>;

const SlugFieldSchema = CharFieldSchema.extend({
  type: z
    .literal(SUPPORTED_FIELDS.SlugField.toString())
    .default(SUPPORTED_FIELDS.SlugField.toString()),
  // .readonly(),
  // max_length: z.number().default(50).optional(),
  allow_unicode: z.boolean().default(false).optional(),
}).merge(GenericOptionSchema);
export type SlugField = z.infer<typeof SlugFieldSchema>;

const SmallAutoFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.SmallAutoField.toString())
      .default(SUPPORTED_FIELDS.SmallAutoField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type SmallAutoField = z.infer<typeof SmallAutoFieldSchema>;

const SmallIntegerFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.SmallIntegerField.toString())
      .default(SUPPORTED_FIELDS.SmallIntegerField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type SmallIntegerField = z.infer<typeof SmallIntegerFieldSchema>;

const TextFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.TextField.toString())
      .default(SUPPORTED_FIELDS.TextField.toString()),
    // .readonly(),
    db_collation: z.string().optional(),
  })
  .merge(GenericOptionSchema);
export type TextField = z.infer<typeof TextFieldSchema>;

const TimeFieldSchema = DateFieldSchema.extend({
  type: z
    .literal(SUPPORTED_FIELDS.TimeField.toString())
    .default(SUPPORTED_FIELDS.TimeField.toString()),
  // .readonly(),
  db_collation: z.string().optional(),
}).merge(GenericOptionSchema);
export type TimeField = z.infer<typeof TimeFieldSchema>;

const URLFieldSchema = CharFieldSchema.extend({
  type: z
    .literal(SUPPORTED_FIELDS.URLField.toString())
    .default(SUPPORTED_FIELDS.URLField.toString()),
  // .readonly(),
  // max_length: z.number().default(200).optional(),
}).merge(GenericOptionSchema);
export type URLField = z.infer<typeof URLFieldSchema>;

const UUIDFieldSchema = z
  .object({
    type: z
      .literal(SUPPORTED_FIELDS.UUIDField.toString())
      .default(SUPPORTED_FIELDS.UUIDField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type UUIDField = z.infer<typeof UUIDFieldSchema>;

const ConnectionSchema = z.object({
  modelId: z.string().min(1, { message: "You must enter a value" }), // Used only internally to indentify model
  appId: z.string().min(1, { message: "You must enter a value" }), // Used only internally to indentify model,
  reference: z.string(),
});

const ForeignKeySchema = z
  .object({
    type: z
      .literal(RELATIONSHIP_FIELDS.ForeignKey.toString())
      .default(RELATIONSHIP_FIELDS.ForeignKey.toString()),
    // .readonly(),
    from: ConnectionSchema.required(),
    to: ConnectionSchema.required(),
    on_delete: z
      .enum([
        "CASCADE",
        "PROTECT",
        "RESTRICT",
        "SET_NULL",
        "SET_DEFAULT",
        "DO_NOTHING",
      ])
      .default("CASCADE"),
    related_name: z.string().optional(),
    related_query_name: z.string().optional(),
    to_field: z.string().optional(),
    swappable: z.boolean().default(true),
    db_constraint: z.boolean().default(true),
  })
  .merge(GenericOptionSchema);
export type ForeignKeyType = z.infer<typeof ForeignKeySchema>;

const ManyToManyFieldSchema = z
  .object({
    type: z
      .literal(RELATIONSHIP_FIELDS.ManyToManyField.toString())
      .default(RELATIONSHIP_FIELDS.ManyToManyField.toString()),
    // .readonly(),
    from: ConnectionSchema,
    to: ConnectionSchema,
    related_name: z.string().optional(),
    related_query_name: z.string().optional(),
    db_table: z.string().optional(),
    db_constraint: z.boolean().default(true),
    swappable: z.boolean().default(true),
  })
  .merge(GenericOptionSchema);
export type ManyToManyFieldType = z.infer<typeof ManyToManyFieldSchema>;

const OneToOneFieldSchema = ForeignKeySchema.omit({ type: true })
  .extend({
    type: z
      .literal(RELATIONSHIP_FIELDS.OneToOneField.toString())
      .default(RELATIONSHIP_FIELDS.OneToOneField.toString()),
    // .readonly(),
  })
  .merge(GenericOptionSchema);
export type OneToOneFieldType = z.infer<typeof OneToOneFieldSchema>;

interface SingleFieldToSchemaMap {
  type: string;
  column_type: string;
  field: { schema: z.ZodTypeAny; icon: IconType; doc_url: string };
}

// const FieldToSchemaMap  = [
//   {
//     type: SUPPORTED_FIELDS.AutoField.toString(),
//     column_type: "int",
//     field: { schema: AutoFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.BigAutoField.toString(),
//     column_type: "int",
//     field: { schema: BigAutoFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.BigIntegerField.toString(),
//     column_type: "int",
//     field: { schema: BigIntegerFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.BinaryField.toString(),
//     column_type: "bytes",
//     field: { schema: BinaryFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.BooleanField.toString(),
//     column_type: "bool",
//     field: { schema: BooleanFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.CharField.toString(),
//     column_type: "str",
//     field: { schema: CharFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.DateField.toString(),
//     column_type: "date",
//     field: { schema: DateFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.DateTimeField.toString(),
//     column_type: "datetime",
//     field: { schema: DateTimeFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.DecimalField.toString(),
//     column_type: "decimal",
//     field: { schema: DecimalFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.DurationField.toString(),
//     column_type: "duration",
//     field: { schema: DurationFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.EmailField.toString(),
//     column_type: "email",
//     field: { schema: EmailFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.FileField.toString(),
//     column_type: "file",
//     field: { schema: FileFieldSchema, icon: HiUserCircle },
//   },
//   {
//     type: SUPPORTED_FIELDS.FilePathField.toString(),
//     column_type: "file",
//     field: { schema: FilePathFieldSchema, icon: HiUserCircle },
//   },
// ];

// const FieldToSchemaMap = Object.keys(SUPPORTED_FIELDS).map((value) => {
//   return {
//     type: value,
//     column_type: value.split("Field")[0].toLowerCase(),
//     field: {
//       schema: eval(
//         `${value}Schema instanceof z.ZodEffects ? ${value}Schema.sourceType() : ${value}Schema`,
//       ),
//       icon: HiUserCircle,
//       doc_url: `${MODEL_FIELD_DOCS_BASE_URL}${value.toLocaleLowerCase()}`,
//     },
//   };
// });

const RelationFieldToSchemaMap = [
  {
    type: RELATIONSHIP_FIELDS.ForeignKey.toString(),
    column_type: "o2m",
    field: {
      schema: ForeignKeySchema,
      icon: HiUserCircle,
      doc_url: `${MODEL_FIELD_DOCS_BASE_URL}${RELATIONSHIP_FIELDS.ForeignKey.toString().toLocaleLowerCase()}`,
    },
  },
  {
    type: RELATIONSHIP_FIELDS.ManyToManyField.toString(),
    column_type: "m2m",
    field: {
      schema: ManyToManyFieldSchema,
      icon: HiUserCircle,
      doc_url: `${MODEL_FIELD_DOCS_BASE_URL}${RELATIONSHIP_FIELDS.ManyToManyField.toString().toLocaleLowerCase()}`,
    },
  },
  {
    type: RELATIONSHIP_FIELDS.OneToOneField.toString(),
    column_type: "o2o",
    field: {
      schema: OneToOneFieldSchema,
      icon: HiUserCircle,
      doc_url: `${MODEL_FIELD_DOCS_BASE_URL}${RELATIONSHIP_FIELDS.OneToOneField.toString().toLocaleLowerCase()}`,
    },
  },
];

const allSingleField = [
  AutoFieldSchema,
  BigAutoFieldSchema,
  BigIntegerFieldSchema,
  BinaryFieldSchema,
  BooleanFieldSchema,
  CharFieldSchema,
  DateFieldSchema,
  DateTimeFieldSchema,
  DecimalFieldSchema,
  DurationFieldSchema,
  EmailFieldSchema,
  FileFieldSchema,
  FilePathFieldSchema,
  FloatFieldSchema,
  GenericIPAddressFieldSchema,
  ImageFieldSchema,
  IntegerFieldSchema,
  JSONFieldSchema,
  PositiveBigIntegerFieldSchema,
  PositiveIntegerFieldSchema,
  PositiveSmallIntegerFieldSchema,
  SlugFieldSchema,
  SmallAutoFieldSchema,
  SmallIntegerFieldSchema,
  TextFieldSchema,
  TimeFieldSchema,
  URLFieldSchema,
  UUIDFieldSchema,
];

const FieldToSchemaMap = allSingleField.map((value) => {
  const _value = value instanceof z.ZodEffects ? value.sourceType() : value;
  const _fieldType = _value.shape.type._def.defaultValue();
  return {
    type: _fieldType,
    column_type: _fieldType.split("Field")[0].toLowerCase(),
    field: {
      schema: _value,
      icon: HiUserCircle,
      doc_url: `${MODEL_FIELD_DOCS_BASE_URL}${_fieldType.toLocaleLowerCase()}`,
    },
  };
});

const allRelationField = [
  ForeignKeySchema,
  ManyToManyFieldSchema,
  OneToOneFieldSchema,
];
type AllRelationFields =
  | ForeignKeyType
  | ManyToManyFieldType
  | OneToOneFieldType;

// const AllFieldSchema = z.union([
const AllFieldSchema = z.discriminatedUnion("type", [
  AutoFieldSchema,
  BigAutoFieldSchema,
  BigIntegerFieldSchema,
  BinaryFieldSchema,
  BooleanFieldSchema,
  CharFieldSchema,
  DateFieldSchema,
  DateTimeFieldSchema,
  DecimalFieldSchema.sourceType(),
  DurationFieldSchema,
  EmailFieldSchema,
  FileFieldSchema.sourceType(),
  FilePathFieldSchema,
  FloatFieldSchema,
  GenericIPAddressFieldSchema.sourceType(),
  ImageFieldSchema,
  IntegerFieldSchema,
  JSONFieldSchema,
  PositiveBigIntegerFieldSchema,
  PositiveIntegerFieldSchema,
  PositiveSmallIntegerFieldSchema,
  SlugFieldSchema,
  SmallAutoFieldSchema,
  SmallIntegerFieldSchema,
  TextFieldSchema,
  TimeFieldSchema,
  URLFieldSchema,
  UUIDFieldSchema,
  ...allRelationField,
]);
type AllFields = z.infer<typeof AllFieldSchema>;

const FieldSchema = z.object({
  fieldId: z.string().min(1, { message: "You must enter a name" }), // only used internally to identify field
  modelId: z.string().min(1, { message: "You must enter a value" }), // Used only internally to indentify model
  appId: z.string().min(1, { message: "You must enter a value" }), // Used only internally to indentify model,

  name: NameIdentifier,
  field: AllFieldSchema,
});
type FieldType = z.infer<typeof FieldSchema>;

export const ModelConfigSchema = z.object({
  include_admin_page: z.boolean().default(true).optional(),
});
export type ModelConfig = z.infer<typeof ModelConfigSchema>;

const ModelSchema = z.object({
  modelId: z.string().min(1, { message: "You must enter a value" }), // Used only internally to indentify model
  appId: z.string().min(1, { message: "You must enter a value" }), // Used only internally to indentify model,

  name: NameIdentifier,
  fields: z
    .array(FieldSchema)
    .default([])
    .refine(
      (entries) => {
        const fieldIds = new Set(entries.map((e) => e.fieldId));
        return fieldIds.size === entries.length;
      },
      {
        message: "fieldIds must be unique",
      },
    )
    .refine(
      (entries) => {
        const fieldWithPrimaryKey = entries.filter(
          (e) => e.field.primary_key == true,
        );
        return fieldWithPrimaryKey.length <= 1;
      },
      {
        message: "Model can only contain one primary key field",
      },
    ),
  config: ModelConfigSchema.default(ModelConfigSchema.parse({})).optional(),
});
type ModelType = z.infer<typeof ModelSchema>;

const AppSchema = z.object({
  appId: z.string().min(1, { message: "You must enter a value" }), // Used only internally to indentify app.

  name: NameIdentifier,
  models: z
    .array(ModelSchema)
    .default([])
    .refine(
      (entries) => {
        const modelIds = new Set(entries.map((e) => e.modelId));
        return modelIds.size === entries.length;
      },
      {
        message: "modelIds must be unique",
      },
    )
    .refine(
      (entries) => {
        const modelNames = new Set(entries.map((e) => e.name));
        return modelNames.size === entries.length;
      },
      {
        message: "Model name must be unique",
      },
    ),
});
type AppType = z.infer<typeof AppSchema>;

export const ProjectConfigSchema = z
  .object({
    enable_web: z.boolean().default(true),
    enable_api: z.boolean().default(false),
    use_email_as_username: z.boolean().default(false),
    include_social_auth: z.boolean().default(false),
    include_django_filter: z.boolean().default(false),
    include_drf_spectacular: z.boolean().default(false),
    include_dj_database_url: z.boolean().default(false),
    include_docker: z.boolean().default(false),
    include_celery: z.boolean().default(false),
    include_poetry: z.boolean().default(false),
    include_sentry: z.boolean().default(false),
    include_ruff: z.boolean().default(false),
    include_pytest: z.boolean().default(false),
    include_email_setup: z.boolean().default(false),
  })
  .superRefine((entries, ctx) => {
    if (entries.enable_api == false && entries.enable_web == false) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["enable_web"],
        message: "Either `enable_web` or `enable_api` must be enabled",
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["enable_api"],
        message: "Either `enable_web` or `enable_api` must be enabled",
      });
    }
    if (
      entries.include_drf_spectacular == true &&
      entries.enable_api == false
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["include_drf_spectacular"],
        message:
          "`include_drf_spectacular` is only allowed when `enable_api` is enabled",
      });
    }
  });

export type ProjectConfig = z.infer<typeof ProjectConfigSchema>;

export const ProjectSchema = z.object({
  name: z.string().optional(),
  apps: z
    .array(AppSchema)
    .default([])
    .refine(
      (entries) => {
        const appIds = new Set(entries.map((e) => e.appId));
        return appIds.size === entries.length;
      },
      {
        message: "appIds must be unique",
      },
    ),
  config: ProjectConfigSchema.default(ProjectConfigSchema.parse({})).optional(),
});

type ProjectType = z.infer<typeof ProjectSchema>;

// -------------------------------

export const APIUserSchema = z.object({
  id: z.coerce.string(),
  email: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  username: z.string(),
  last_login: z.string().datetime({ offset: true }),
  date_joined: z.string().datetime({ offset: true }),
  has_active_subscription: z.boolean(),
  is_using_free_plan: z.boolean(),
  active_product_id: z.string().optional(),
  plan_permission: z
    .object({
      access_feature_list: z.array(z.string()),
    })
    .partial(),
});
export type APIUserSchemaType = z.infer<typeof APIUserSchema>;

export const APIProjectSchema = z.object({
  code_spec: z.string(),
  created_at: z.string().datetime({ offset: true }),
  download_url: z.string(),
  id: z.string(),
  name: z.string(),
  version: z.coerce.string(),
  updated_at: z.string().datetime({ offset: true }),
  generator_settings: z.object({
    django_version: z.string(),
    python_version: z.string(),
  }),
});

export type APIProjectSchemaType = z.infer<typeof APIProjectSchema>;

export const APIPriceSchema = z.object({
  id: z.string(),
  product_name: z.string(),
  product_id: z.string(),
  product_description: z.string(),
  price_amount: z.string(),
  billing_cycle: z.string().optional(),
  is_popular: z.boolean(),
  is_free: z.boolean(),
  features: z.array(
    z.object({
      name: z.string(),
      active: z.boolean(),
    }),
  ),
});

export type APIPriceSchemaType = z.infer<typeof APIPriceSchema>;

export const APIAppConfigSchema = z.object({
  pro_feature_list: z.array(z.string()),
});

export type APIAppConfigSchemaType = z.infer<typeof APIAppConfigSchema>;

export {
  AllFieldSchema,
  AppSchema,
  AutoFieldSchema,
  ConnectionSchema,
  FieldSchema,
  FieldToSchemaMap,
  GenericOptionSchema,
  ModelSchema,
  RelationFieldToSchemaMap,
};

export type {
  AllFields,
  AllRelationFields,
  AppType,
  FieldType,
  ModelType,
  ProjectType,
  SingleFieldToSchemaMap,
};
