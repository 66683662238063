import { Button, useThemeMode } from "flowbite-react";
import { useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
// import pricingPlans from "../../assets/pricing.json";
import demoGraphicsDark from "../../assets/capture-dark.png";
import demoGraphicsLight from "../../assets/capture-light.png";
import { useAuthenticationStore, useProfileStore } from "../../model/store";
import {
  LocalStorageKey,
  SessionStorageKey,
  notify,
} from "../../utils/contants";
import { FAQSection } from "../faq";
import { Footer } from "../footer";
import { Loader } from "../loader";
import { NavBar } from "../navbar";
import { WhatDoIGet } from "./what-do-i-get";
import { FeatureList } from "./feature-list";
import { HeroSection } from "./hero";
import { FaGithub } from "react-icons/fa";
import { HowItWorks } from "./how-it-works";
import { getOrSetCorrelationId } from "../../utils/utils";

export default function LoginPage() {
  const getAPIAccessToken = useAuthenticationStore(
    (state) => state.getAPIAccessToken,
  );
  const loginInProgress = useAuthenticationStore(
    (state) => state.loginInProgress,
  );
  const navigate = useNavigate();

  const fetchPriceList = useProfileStore((state) => state.fetchPriceList);
  const fetchAppConfig = useProfileStore((state) => state.fetchAppConfig);
  const customInitializePaddle = useProfileStore(
    (state) => state.customInitializePaddle,
  );

  useEffect(() => {
    fetchPriceList();
    fetchAppConfig();
    getOrSetCorrelationId();
    customInitializePaddle(navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { code, state, error } = useLoaderData() as any;

  useEffect(() => {
    if (code) {
      getAPIAccessToken({
        code: code,
        stateString: state,
      }).then((result) => {
        if (result) {
          notify("Login successful");
          const selectedPlan = localStorage.getItem(
            LocalStorageKey.SELECTED_PRICE_ID,
          );

          if (selectedPlan) {
            // If selected plan is found in localStorage, redirect to pricing page to continue purchase flow
            navigate("/pricing");
          } else {
            navigate("/profile");
          }
        } else {
          notify("Error logging in, try again", true);
        }
      });
    }
    if (error) notify(error, true);

    // if user somehow gets to the main page. reset correlation-id
    sessionStorage.removeItem(SessionStorageKey.CORRELATION_ID);
    getOrSetCorrelationId();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, error]);

  const themeMode = useThemeMode();
  const getAuthCode = useAuthenticationStore((state) => state.getAuthCode);

  return (
    <>
      <div style={{ minHeight: "80vh" }}>
        <NavBar
          extraClass="fixed w-full z-20 top-0 start-0"
          activeLink="home"
          content={
            <Button
              gradientDuoTone="pinkToOrange"
              onClick={() => {
                getAuthCode();
                notify("Authenticating with Github", false, undefined, true);
              }}
              className="justify-center items-center hidden md:inline-flex"
            >
              <FaGithub size={18} className="mr-2" /> Get Started
            </Button>
          }
        />
        <HeroSection />
        <section className="">
          <div className="px-4 mx-auto max-w-screen-xl sm:py-12 lg:px-6">
            <img
              className="h-auto max-w-full"
              src={
                themeMode.mode == "dark" ? demoGraphicsDark : demoGraphicsLight
              }
              alt="image description"
            />
          </div>
        </section>

        <FeatureList />

        <HowItWorks />

        <WhatDoIGet />

        <FAQSection />
      </div>
      <Footer />
      {loginInProgress ? <Loader text="Please wait!" /> : <></>}
    </>
  );
}
