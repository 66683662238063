import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Label, Modal, TextInput } from "flowbite-react";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import {
  ConnectionSchema,
  FieldSchema,
  RelationFieldToSchemaMap,
  SingleFieldToSchemaMap,
} from "../../fields/field";
import { Action, useModalStore, useProjectStore } from "../../model/store";
import { errorTextClass } from "../../utils/contants";
import { FieldListSelect } from "./singlemodel";

const RelationFieldSelectModal: React.FC = () =>
  // props: IFieldSelectModal
  {
    const [actionSuccessful, setActionSuccessful] = useState(false);
    const [selectedField, setSelectedField] =
      useState<SingleFieldToSchemaMap | null>(null);
    const upsertModelField = useProjectStore((state) => state.upsertModelField);
    const allowFieldName = useProjectStore((state) => state.allowFieldName);
    const getModelConnectionReference = useProjectStore(
      (state) => state.getModelConnectionReference,
    );
    const activeModelConnection = useProjectStore(
      (state) => state.activeModelConnection,
    );

    const activeModelField = useProjectStore(
      (state) => state.activeModelField,
    )!;

    const setActiveModelField = useProjectStore(
      (state) => state.setActiveModelField,
    );

    const [
      openUpsertRelationModelFieldModal,
      setOpenUpsertRelationModelFieldModal,
    ] = useModalStore((state) => [
      state.openUpsertRelationModelFieldModal,
      state.setOpenUpsertRelationModelFieldModal,
    ]);

    const FieldSchemaForm = FieldSchema.pick({ name: true, field: true });
    type FieldSchemaFormType = z.infer<typeof FieldSchemaForm>;

    const onSubmit: SubmitHandler<FieldSchemaFormType> = (data) => {
      if (selectedField !== null && activeModelConnection != null) {
        if (activeModelField === null) {
          const newField = {
            fieldId: nanoid(),
            modelId: activeModelConnection.source.modelId,
            appId: activeModelConnection.source.appId,
            field: data.field,
            name: data.name,
          };

          upsertModelField({
            field: newField,
            action: Action.ADD,
          });
          setActionSuccessful(true);
        } else {
          const updatedModelField = FieldSchema.parse({
            ...activeModelField,
            ...{ name: data.name, field: data.field },
          });
          upsertModelField({
            field: updatedModelField,
            action: Action.EDIT,
          });
          setActionSuccessful(true);
          setActiveModelField(updatedModelField);
        }

        setSelectedField(null);
        setOpenUpsertRelationModelFieldModal(false);
      }
    };

    const {
      register,
      setValue,
      resetField,
      reset,
      handleSubmit,
      clearErrors,
      formState: { errors },
    } = useForm<FieldSchemaFormType>({
      resolver: zodResolver(
        FieldSchemaForm.superRefine((entries, ctx) => {
          if (
            activeModelConnection &&
            !allowFieldName({
              fieldName: entries.name,
              model: activeModelConnection.source,
            })
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Name Already Exist, No duplicates allowed.",
              path: ["name"],
            });
          }
        }),
      ),
    });

    useEffect(() => {
      if (selectedField == null && activeModelConnection != null) {
        setValue("name", activeModelConnection?.target.name.toLowerCase());
      }
    }, [setValue, activeModelConnection, selectedField]);

    useEffect(() => {
      if (selectedField != null && activeModelConnection != null) {
        const [sourceReference, targetReference] = getModelConnectionReference(
          activeModelConnection,
        );

        setValue(
          "field",
          selectedField.field.schema.parse({
            from: ConnectionSchema.parse({
              modelId: activeModelConnection.source.modelId,
              appId: activeModelConnection.source.appId,
              reference: sourceReference != null ? sourceReference : "",
            }),
            to: ConnectionSchema.parse({
              modelId: activeModelConnection.target.modelId,
              appId: activeModelConnection.target.appId,
              reference: targetReference != null ? targetReference : "",
            }),
          }),
        );
        clearErrors("field");
      }
    }, [
      selectedField,
      activeModelConnection,
      setValue,
      clearErrors,
      register,
      getModelConnectionReference,
    ]);

    useEffect(() => {
      reset({
        name: "",
      });
    }, [resetField, reset, actionSuccessful]);

    useEffect(() => {
      if (activeModelField != null) {
        const activeModelFieldSelector = RelationFieldToSchemaMap.find(
          (field) => field.type === activeModelField.field.type,
        );

        if (activeModelFieldSelector) {
          setSelectedField(activeModelFieldSelector);
          setValue("name", activeModelField.name);
        }
      }
    }, [activeModelField, setValue]);

    return (
      <>
        <Modal
          dismissible
          show={openUpsertRelationModelFieldModal}
          onClose={() => {
            setOpenUpsertRelationModelFieldModal(false);
            setValue("name", "");
            // setActiveModelField(null)
          }}
        >
          <Modal.Header>Select Field</Modal.Header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Model Field Name" />
                </div>
                <TextInput
                  id="text"
                  placeholder="Enter Model Field Name"
                  {...register("name")}
                  required={true}
                />
                <div className={errorTextClass}>
                  {errors.name?.message && (
                    <p>{errors.name!.message.toString()}</p>
                  )}
                </div>
              </div>
              <div>
                <div className="my-2 block">
                  <Label htmlFor="source" value="Source Model" />
                </div>
                <TextInput
                  id="text"
                  value={activeModelConnection?.source.name}
                  readOnly={true}
                />
              </div>
              <div>
                <div className="my-2 block">
                  <Label htmlFor="source" value="Target Model" />
                </div>
                <TextInput
                  id="text"
                  value={activeModelConnection?.target.name}
                  readOnly={true}
                />
              </div>

              <div className="my-2">
                <div className="mb-2 block">
                  <Label htmlFor="type" value="Select Field Type" />
                </div>
                <div className={errorTextClass}>
                  {errors.field?.message && (
                    <p>
                      {errors.field!.message.toString() == "Required"
                        ? "You must select a Field Type"
                        : errors.field!.message.toString()}
                    </p>
                  )}
                </div>
                <FieldListSelect
                  setSelectedField={setSelectedField}
                  activeModelField={activeModelField}
                  fieldListMap={RelationFieldToSchemaMap}
                  // selectedField={selectedField}
                />
              </div>
              {/* <div>
                <a
                  href="#"
                  className="inline-flex items-center text-xs font-normal text-gray-500 hover:underline dark:text-gray-400"
                >
                  Why do I need to connect with my wallet?
                </a>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <div className="w-full flex gap-3 justify-end">
                <Button className="w-32" type="submit">
                  {activeModelField === null ? "Add Field" : "Edit Field"}
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  };

export { RelationFieldSelectModal };
