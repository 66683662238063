import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { NeedHelpAlert } from "./need_help";
import { Footer } from "./footer";
import { NavBar } from "./navbar";
import { useAuthenticationStore } from "../model/store";
import { useEffect } from "react";

export default function PaymentSuccessPage() {
  const getAPIUser = useAuthenticationStore((state) => state.getAPIUser);

  useEffect(() => {
    // try to get the user data once again with cache miss
    getAPIUser(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <div style={{ minHeight: "80vh" }}>
        <NavBar
          extraClass="fixed w-full z-20 top-0 start-0"
          activeLink="profile"
        />
        <section className="mt-20 pt-12 pb-16 px-4 mx-auto max-w-screen-xl  lg:px-6">
          <div className="text-center py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <h1 className="mb-4 text-4xl font-bold tracking-tight leading-none text-gray-900 lg:mb-6 md:text-5xl xl:text-6xl dark:text-white">
              🎉 &nbsp; Payment successful &nbsp; 🎉
            </h1>
            <p className="font-light italic text-gray-500 text-base dark:text-gray-400">
              It could take some seconds until you can access all of the paid
              features, nothing to worry about, just few processing.
            </p>
            <Button
              gradientDuoTone="pinkToOrange"
              onClick={() => {
                navigate("/profile", {
                  replace: true,
                });
              }}
              className="my-8 justify-center items-center hidden md:inline-flex px-8"
            >
              My Profile
            </Button>
          </div>
          <div className="mt-36">
            <NeedHelpAlert />
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
