import { zodResolver } from "@hookform/resolvers/zod";
import { Drawer } from "flowbite";
import { Button } from "flowbite-react";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { z } from "zod";
import { ModelSchema } from "../fields/field";
import {
  Action,
  IConfigDrawer,
  useAuthenticationStore,
  useModalStore,
  useProjectStore,
} from "../model/store";
import { drawerGenericOptions, notify } from "../utils/contants";
import { capitalize, isComingSoon } from "../utils/utils";
import { decideFieldInputType } from "./utils";

export const ConfigDrawer: React.FC = () => {
  const openConfigDrawer = useModalStore((state) => state.openConfigDrawer);

  return (
    <>
      <div
        id="config-drawer-navigation"
        className="fixed top-0 right-0 z-40 w-80 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white dark:bg-gray-800"
        tabIndex={-1}
        aria-labelledby="drawer-navigation-label"
      >
        <div className="flex items-center justify-between text-base font-semibold text-gray-500 dark:text-gray-400">
          <div>
            <div id="drawer-label" className="flex text-base items-center mt-2">
              <IoMdInformationCircleOutline className="mr-1" />
              <span>
                {capitalize(
                  openConfigDrawer != null ? openConfigDrawer.type : "",
                )}{" "}
                Config
              </span>
            </div>
          </div>
        </div>
        <hr className="my-3" />
        {openConfigDrawer != null ? (
          <InnerConfigDrawer drawerConfig={openConfigDrawer} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
interface IF {
  drawerConfig: IConfigDrawer;
}
const InnerConfigDrawer: React.FC<IF> = (props: IF) => {
  const openConfigDrawer = props.drawerConfig;

  const upsertModel = useProjectStore((state) => state.upsertModel);
  const upsertProject = useProjectStore((state) => state.upsertProject);
  const setActiveModel = useProjectStore((state) => state.setActiveModel);

  const setOpenConfigDrawer = useModalStore(
    (state) => state.setOpenConfigDrawer,
  );

  const selectedConfigSchema =
    openConfigDrawer.configSchema instanceof z.ZodEffects
      ? openConfigDrawer.configSchema._def.schema
      : openConfigDrawer.configSchema;

  type selectedConfigType = z.infer<typeof selectedConfigSchema>;
  const activeModel = useProjectStore((state) => state.activeModel);

  const onSubmit: SubmitHandler<selectedConfigType> = (data) => {
    if (openConfigDrawer !== null) {
      if (openConfigDrawer.type === "MODEL") {
        upsertModel({
          model: ModelSchema.parse({
            ...activeModel,
            ...{ config: data },
          }),
          action: Action.EDIT,
        });
        notify();
      } else if (openConfigDrawer.type === "PROJECT") {
        upsertProject({
          project: { config: data },
          action: Action.EDIT,
        });
        notify();
      }
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<selectedConfigType>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(
      openConfigDrawer.type === "PROJECT"
        ? openConfigDrawer.configSchema.superRefine((entries, ctx) => {
            const featureList = user?.plan_permission.access_feature_list;
            const allowedFeatureList =
              featureList == null || featureList == undefined
                ? []
                : featureList;
            const schemaDefault = openConfigDrawer.configSchema.parse({});

            const configCanBeChecked = function (element: string) {
              if (entries[element] != schemaDefault[element]) {
                // if what is supplied as value does not match default, then we want to validate such entry
                if (isComingSoon(element)) return false; // if the entry property is marked as coming soon, false, it cannot be changed (has to be default)
                return allowedFeatureList.includes(element); // if the entry property is part of the user feature list then user can change it (return true), else false (cannot be changed)
              } else {
                return true; // if what is supplied as value match default, then we do not want to validate such entry
              }
            };
            if (!Object.keys(entries).every(configCanBeChecked)) {
              notify(
                "Pro features are not supported by your current plan, kindly Upgrade",
                true,
              );
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Pro features are not supported by your plan, kindly Upgrade`,
              });
            }
          })
        : openConfigDrawer.configSchema,
    ),

    defaultValues: selectedConfigSchema.parse({
      ...openConfigDrawer.config,
    }),
  });

  useEffect(() => {
    if (openConfigDrawer !== null) {
      const options = {
        ...drawerGenericOptions,
        onHide: () => {
          setActiveModel(null);
          setOpenConfigDrawer(null);
        },
        onShow: () => {
          //
        },
        onToggle: () => {
          //
        },
      };
      const drawer = new Drawer(
        document.getElementById("config-drawer-navigation"),
        options,
      );
      drawer.show();
    }
  }, [openConfigDrawer, setOpenConfigDrawer, setActiveModel]);

  const user = useAuthenticationStore((state) => state.user);

  const properties = selectedConfigSchema
    .keyof()
    .options.map((option: string) => {
      const _def = selectedConfigSchema.shape[option]._def;
      const typeName = _def.typeName;
      return (
        <div className="my-6" key={option + "key"}>
          {decideFieldInputType({
            typeName: typeName,
            fieldName: option,
            registerHook: register,
            _def: _def,
            errors: errors,
            featureList: user?.plan_permission.access_feature_list,
          })}
          <hr className="h-px border-0 mt-2 bg-gray-200 dark:bg-gray-600" />
        </div>
      );
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="my-3">{properties}</div>
      <Button type="submit" size="sm" className="w-full mt-8">
        Save changes
      </Button>
    </form>
  );
};
