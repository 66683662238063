import { Button } from "flowbite-react";
import { twMerge } from "tailwind-merge";
import { APIPriceSchemaType, APIUserSchemaType } from "../../fields/field";
import { useAuthenticationStore } from "../../model/store";
import { HiCheck, HiX } from "react-icons/hi";

interface IPricingCard extends React.ComponentProps<"div"> {
  price: APIPriceSchemaType;
  setSelectedPrice: React.Dispatch<
    React.SetStateAction<APIPriceSchemaType | null>
  >;
}

const showIsPopular = ({
  user,
  pricePlan,
}: {
  user: APIUserSchemaType | null;
  pricePlan: APIPriceSchemaType;
}) => {
  if (user?.has_active_subscription) return false;
  return pricePlan.is_popular;
};

export const PricingCard: React.FC<IPricingCard> = (props: IPricingCard) => {
  const { price: pricePlan } = props;
  const user = useAuthenticationStore((state) => state.user);

  return (
    <>
      <div
        className={twMerge([
          "max-w-sm flex rounded-lg border text-gray-900 dark:text-white bg-white shadow-md  dark:bg-gray-800",
          showIsPopular({ user, pricePlan }) ||
          user?.active_product_id == pricePlan.product_id
            ? "shadow-lg border-2 border-cyan-600 dark:border-cyan-600"
            : "border-gray-200 dark:border-gray-700",
        ])}
      >
        <div className="flex gap-4 p-6 flex-col h-full w-full">
          <div>
            <div className="flex justify-between">
              <h3 className="uppercase text-xl font-semibold">
                {pricePlan.product_name}
              </h3>
              {(user?.active_product_id == pricePlan.product_id ||
                showIsPopular({ user, pricePlan })) && (
                <div className="rounded-full flex bg-cyan-600 w-24 h-7">
                  <span className="text-xs m-auto font-semibold text-white">
                    {user?.active_product_id == pricePlan.product_id
                      ? "Current Plan"
                      : "Most popular"}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="flex justify-center items-baseline my-4">
              <span className="mr-2 text-5xl font-bold">
                {pricePlan.price_amount}
              </span>
              {pricePlan.billing_cycle && (
                <span className="text-gray-500 dark:text-gray-400 lowercase">
                  /{pricePlan.billing_cycle}
                </span>
              )}
            </div>
          </div>
          <div className="mb-3">
            <p className="sm:text-base">{pricePlan.product_description}</p>
          </div>
          <div className="flex-grow">
            <ul role="list" className="space-y-4 text-left">
              {pricePlan.features.map((feature, index) => (
                <li
                  className="flex items-center  font-light space-x-3"
                  key={`${pricePlan.product_name}-${index}`}
                >
                  {feature.active ? (
                    <HiCheck
                      size={20}
                      className="text-green-500 dark:text-green-400"
                    />
                  ) : (
                    <HiX size={20} className="text-red-500 dark:text-red-500" />
                  )}
                  <span className="text-base">{feature.name}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full mt-3">
            <Button
              size="lg"
              className="w-full"
              gradientDuoTone="pinkToOrange"
              onClick={() => props.setSelectedPrice(pricePlan)}
              disabled={user?.active_product_id == pricePlan.product_id}
            >
              {user?.has_active_subscription
                ? user?.active_product_id == pricePlan.product_id
                  ? "Current Plan"
                  : "Get Plan"
                : "Get Started"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
