import { HiCheckCircle } from "react-icons/hi";
const offerList: string[] = [
  "ER Diagram Schema Assistence",
  "User Management",
  "Acccount authentication",
  "Docker Development Environment",
  "Different databases support",
  "Optional Celery support",
  "Optional Redis support",
  "Different CI/CD Setup",
  "Testing support",
  "Email Integration",
  "Admin Setup",
  "Social Authentication Setup",
  "Optional Sentry integration",
  "Error Monitoring with Sentry",
  "Code coverage with Codecov",
  "Code Formatting with Ruff",
  "Production Deployment Support",
  "Studio template to build on",
  "Complete Documentation",
  "Example: Background Tasks",
  "Example: Login with Github",
  "Example: Email Sending",
  "Many more ...",
];

export const WhatDoIGet = () => {
  return (
    <section className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
      <div className="px-4 mx-auto max-w-screen-xl sm:py-12 lg:px-6">
        <h2 className="text-center text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          What Do You Get?
        </h2>
        <div className="mt-8 md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-4 md:space-y-0">
          {offerList.map((offer, index) => (
            <div
              className="mb-3 flex gap-3 align-middle items-center"
              key={`whatDoIGet-${index}`}
            >
              <HiCheckCircle size={18} className="text-green-500" />
              <p>{offer}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
