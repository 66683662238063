import { Modal } from "flowbite-react";
import { ProjectConfigSchema, ProjectSchema } from "../fields/field";
import { useFlowStore, useModalStore, useProjectStore } from "../model/store";
import { PREDEFINED_TEMPLATES, Template, notify } from "../utils/contants";

export const TemplateModal: React.FC = () => {
  const setProject = useProjectStore((state) => state.setProject);
  const setNodes = useFlowStore((state) => state.setNodes);
  const nodes = useFlowStore((state) => state.nodes);
  const setOpenConfirmBox = useModalStore((state) => state.setOpenConfirmBox);
  const setOpenTemplateModal = useModalStore(
    (state) => state.setOpenTemplateModal,
  );
  const openTemplateModal = useModalStore((state) => state.openTemplateModal);

  const _onConfirm = (template: Template) => {
    const _nodes = nodes;
    try {
      setNodes([]);
      const parsedTemplateProject = ProjectSchema.parse(
        template.templateProject,
      );
      if (parsedTemplateProject.config == undefined) {
        parsedTemplateProject.config = ProjectConfigSchema.parse({});
      }
      setProject(parsedTemplateProject);
      setOpenTemplateModal(false);
      notify("Template successfully loaded.");
    } catch (Error) {
      setNodes(_nodes);
      notify("Error loading template.", true);
    }
  };

  return (
    <>
      <Modal
        size="md"
        show={openTemplateModal}
        onClose={() => setOpenTemplateModal(false)}
      >
        <Modal.Header>Templates</Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
              Use one of our available templates or create a new project.
            </p>
            <ul className="my-4 space-y-3">
              {PREDEFINED_TEMPLATES.map((template) => (
                <li
                  onClick={() => {
                    setOpenConfirmBox({
                      open: true,
                      title: "Template",
                      onConfirm: () => _onConfirm(template),
                      onCancel: () => setOpenConfirmBox(null),
                    });
                  }}
                  key={template.name}
                >
                  <a
                    href="#"
                    className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-200 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                  >
                    <span className="flex-1 ms-3 whitespace-nowrap">
                      {template.name}
                    </span>
                    <span className="inline-flex items-center justify-center px-2 py-0.5 ms-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">
                      Popular
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
