import { Footer } from "../footer";
import { NavBar } from "../navbar";

import { contactEmailAddress, websiteURL } from "../../utils/contants";

export const PrivacyPolicy = () => {
  return (
    <div>
      <NavBar
        extraClass="fixed w-full z-20 top-0 start-0"
        activeLink="provicy-policy"
      />
      <section className="mt-20 pt-12 pb-16 px-4 mx-auto max-w-screen-xl  lg:px-6">
        <div className="px-4 max-w-screen-lg mx-auto pb-12 lg:px-6">
          <h4 className="text-3xl  my-5">Privacy policy</h4>
          This privacy policy ("Policy") describes how the personally
          identifiable information ("Personal Information") you may provide on
          the{" "}
          <a className="underline" href={`http://${websiteURL}`}>
            {websiteURL}
          </a>{" "}
          website ("Website" or "Service") and any of its related products and
          services (collectively, "Services") is collected, protected and used.
          It also describes the choices available to you regarding our use of
          your Personal Information and how you can access and update this
          information. This Policy is a legally binding agreement between you
          ("User", "you" or "your") and Django Compose ("we", "us" or "our"). By
          accessing and using the Website and Services, you acknowledge that you
          have read, understood, and agree to be bound by the terms of this
          Agreement. This Policy does not apply to the practices of companies
          that we do not own or control, or to individuals that we do not employ
          or manage.
          <h4 className="text-xl my-5"> Automatic collection of information</h4>
          When you open the Website, our servers automatically record
          information that your browser sends. This data may include information
          such as your device's IP address, browser type and version, operating
          system type and version, language preferences or the webpage you were
          visiting before you came to the Website and Services, pages of the
          Website and Services that you visit, the time spent on those pages,
          information you search for on the Website, access times and dates, and
          other statistics. Information collected automatically is used only to
          identify potential cases of abuse and establish statistical
          information regarding the usage and traffic of the Website and
          Services. This statistical information is not otherwise aggregated in
          such a way that would identify any particular user of the system.
          <h4 className="text-xl my-5"> Collection of personal information</h4>
          You can access and use the Website and Services without telling us who
          you are or revealing any information by which someone could identify
          you as a specific, identifiable individual. If, however, you wish to
          use some of the features on the Website, you may be asked to provide
          certain Personal Information (for example, your name and e-mail
          address). We receive and store any information you knowingly provide
          to us when you create an account, publish content, make a purchase, or
          fill any online forms on the Website. When required, this information
          may include the following:
          <ol className="list-disc ml-6 my-3">
            <li>Personal details such as name, country of residence, etc.</li>

            <li>Contact information such as email address, address, etc.</li>
            <li>
              Account details such as user name, unique user ID, password, etc.
            </li>
            <li>
              Payment information such as credit card details, bank details,
              etc.
            </li>
            <li>
              Any other materials you willingly submit to us such as articles,
              images, feedback, etc.
            </li>
            <li>
              You can choose not to provide us with your Personal Information,
              but then you may not be able to take advantage of some of the
              features on the Website. Users who are uncertain about what
              information is mandatory are welcome to contact us.
            </li>
          </ol>
          <h4 className="text-xl my-5">
            {" "}
            Use and processing of collected information
          </h4>
          In order to make the Website and Services available to you, or to meet
          a legal obligation, we need to collect and use certain Personal
          Information. If you do not provide the information that we request, we
          may not be able to provide you with the requested products or
          services. Some of the information we collect is directly from you via
          the Website and Services. However, we may also collect Personal
          Information about you from other sources such as social media, emails,
          etc. Any of the information we collect from you may be used for the
          following purposes:
          <ol className="list-disc ml-6 my-3">
            <li>Create and manage user accounts</li>
            <li>Fulfill and manage orders</li>
            <li>Deliver products or services</li>
            <li>Improve products and services</li>
            <li>Send administrative information</li>
            <li>Send marketing and promotional communications</li>
            <li>Respond to inquiries and offer support</li>
            <li>Request user feedback</li>
            <li>Improve user experience</li>
            <li>Deliver targeted advertising</li>
            <li>Administer prize draws and competitions</li>
            <li>Enforce terms and conditions and policies</li>
            <li>Protect from abuse and malicious users</li>
            <li>Respond to legal requests and prevent harm</li>
            <li>Run and operate the Website and Services</li>
          </ol>
          <p>
            Processing your Personal Information depends on how you interact
            with the Website and Services, where you are located in the world
            and if one of the following applies: (i) you have given your consent
            for one or more specific purposes; this, however, does not apply,
            whenever the processing of Personal Information is subject to
            California Consumer Privacy Act or European data protection law;
            (ii) provision of information is necessary for the performance of an
            agreement with you and/or for any pre-contractual obligations
            thereof; (iii) processing is necessary for compliance with a legal
            obligation to which you are subject; (iv) processing is related to a
            task that is carried out in the public interest or in the exercise
            of official authority vested in us; (v) processing is necessary for
            the purposes of the legitimate interests pursued by us or by a third
            party.
          </p>
          Note that under some legislations we may be allowed to process
          information until you object to such processing (by opting out),
          without having to rely on consent or any other of the following legal
          bases below. In any case, we will be happy to clarify the specific
          legal basis that applies to the processing, and in particular whether
          the provision of Personal Information is a statutory or contractual
          requirement, or a requirement necessary to enter into a contract.
          <h4 className="text-xl my-5"> Billing and payments</h4>
          We use third party payment processors to assist us in processing your
          payment information securely. Such third party processors' use of your
          Personal Information is governed by their respective privacy policies
          which may or may not contain privacy protections as protective as this
          Policy. We suggest that you review their respective privacy policies.
          <h4 className="text-xl my-5"> Managing information</h4>
          You are able to delete certain Personal Information we have about you.
          The Personal Information you can delete may change as the Website and
          Services change. When you delete Personal Information, however, we may
          maintain a copy of the unrevised Personal Information in our records
          for the duration necessary to comply with our obligations to our
          affiliates and partners, and for the purposes described below.
          <h4 className="text-xl my-5"> Disclosure of information</h4>
          Depending on the requested Services or as necessary to complete any
          transaction or provide any service you have requested, we may share
          your information with your consent with our trusted third parties that
          work with us, any other affiliates and subsidiaries we rely upon to
          assist in the operation of the Website and Services available to you.
          We do not share Personal Information with unaffiliated third parties.
          These service providers are not authorized to use or disclose your
          information except as necessary to perform services on our behalf or
          comply with legal requirements. We may share your Personal Information
          for these purposes only with third parties whose privacy policies are
          consistent with ours or who agree to abide by our policies with
          respect to Personal Information. These third parties are given
          Personal Information they need only in order to perform their
          designated functions, and we do not authorize them to use or disclose
          Personal Information for their own marketing or other purposes. We
          will disclose any Personal Information we collect, use or receive if
          required or permitted by law, such as to comply with a subpoena, or
          similar legal process, and when we believe in good faith that
          disclosure is necessary to protect our rights, protect your safety or
          the safety of others, investigate fraud, or respond to a government
          request. In the event we go through a business transition, such as a
          merger or acquisition by another company, or sale of all or a portion
          of its assets, your user account, and Personal Information will likely
          be among the assets transferred.
          <h4 className="text-xl my-5"> Retention of information</h4>
          We will retain and use your Personal Information for the period
          necessary to comply with our legal obligations, resolve disputes, and
          enforce our agreements unless a longer retention period is required or
          permitted by law. We may use any aggregated data derived from or
          incorporating your Personal Information after you update or delete it,
          but not in a manner that would identify you personally. Once the
          retention period expires, Personal Information shall be deleted.
          Therefore, the right to access, the right to erasure, the right to
          rectification and the right to data portability cannot be enforced
          after the expiration of the retention period.
          <h4 className="text-xl my-5"> Transfer of information</h4>
          Depending on your location, data transfers may involve transferring
          and storing your information in a country other than your own. You are
          entitled to learn about the legal basis of information transfers to a
          country outside the European Union or to any international
          organization governed by public international law or set up by two or
          more countries, such as the UN, and about the security measures taken
          by us to safeguard your information. If any such transfer takes place,
          you can find out more by checking the relevant sections of this Policy
          or inquire with us using the information provided in the contact
          section.
          <h4 className="text-xl my-5"> The rights of users</h4>
          You may exercise certain rights regarding your information processed
          by us. In particular, you have the right to do the following: (i) you
          have the right to withdraw consent where you have previously given
          your consent to the processing of your information; (ii) you have the
          right to object to the processing of your information if the
          processing is carried out on a legal basis other than consent; (iii)
          you have the right to learn if information is being processed by us,
          obtain disclosure regarding certain aspects of the processing and
          obtain a copy of the information undergoing processing; (iv) you have
          the right to verify the accuracy of your information and ask for it to
          be updated or corrected; (v) you have the right, under certain
          circumstances, to restrict the processing of your information, in
          which case, we will not process your information for any purpose other
          than storing it; (vi) you have the right, under certain circumstances,
          to obtain the erasure of your Personal Information from us; (vii) you
          have the right to receive your information in a structured, commonly
          used and machine readable format and, if technically feasible, to have
          it transmitted to another controller without any hindrance. This
          provision is applicable provided that your information is processed by
          automated means and that the processing is based on your consent, on a
          contract which you are part of or on pre-contractual obligations
          thereof.
          <h4 className="text-xl my-5"> The right to object to processing</h4>
          Where Personal Information is processed for the public interest, in
          the exercise of an official authority vested in us or for the purposes
          of the legitimate interests pursued by us, you may object to such
          processing by providing a ground related to your particular situation
          to justify the objection. You must know that, however, should your
          Personal Information be processed for direct marketing purposes, you
          can object to that processing at any time without providing any
          justification. To learn, whether we are processing Personal
          Information for direct marketing purposes, you may refer to the
          relevant sections of this document.
          <h4 className="text-xl my-5"> Data protection rights under GDPR</h4>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights and we aim to take reasonable steps to
          allow you to correct, amend, delete, or limit the use of your Personal
          Information. If you wish to be informed what Personal Information we
          hold about you and if you want it to be removed from our systems,
          please contact us.
          <h4 className="text-xl my-5"> Cookies</h4>
          The Website and Services use "cookies" to help personalize your online
          experience. A cookie is a text file that is placed on your hard disk
          by a web page server. Cookies cannot be used to run programs or
          deliver viruses to your computer. Cookies are uniquely assigned to
          you, and can only be read by a web server in the domain that issued
          the cookie to you. We may use cookies to collect, store, and track
          information for statistical purposes to operate the Website and
          Services. You have the ability to accept or decline cookies. Most web
          browsers automatically accept cookies, but you can usually modify your
          browser setting to decline cookies if you prefer. If you choose to
          decline cookies, you may not be able to fully experience the features
          of the Website and Services. To learn more about cookies and how to
          manage them, visit{" "}
          <a className="underline" href="http://internetcookies.org">
            internetcookies.org
          </a>
          <h4 className="text-xl my-5"> Advertisements</h4>
          We may display online advertisements and we may share aggregated and
          non-identifying information about our customers that we or our
          advertisers collect through your use of the Website and Services. We
          do not share personally identifiable information about individual
          customers with advertisers. In some instances, we may use this
          aggregated and non-identifying information to deliver tailored
          advertisements to the intended audience. We may also permit certain
          third party companies to help us tailor advertising that we think may
          be of interest to users and to collect and use other data about user
          activities on the Website. These companies may deliver ads that might
          place cookies and otherwise track user behavior. Affiliates We may
          disclose information about you to our affiliates for the purpose of
          being able to offer you related or additional products and services.
          Any information relating to you that we provide to our affiliates will
          be treated by those affiliates in accordance with the terms of this
          Policy. Email marketing We offer electronic newsletters to which you
          may voluntarily subscribe at any time. We are committed to keeping
          your e-mail address confidential and will not disclose your email
          address to any third parties except as allowed in the information use
          and processing section or for the purposes of utilizing a third party
          provider to send such emails. We will maintain the information sent
          via e-mail in accordance with applicable laws and regulations. In
          compliance with the CAN-SPAM Act, all e-mails sent from us will
          clearly state who the e-mail is from and provide clear information on
          how to contact the sender. You may choose to stop receiving our
          newsletter or marketing emails by following the unsubscribe
          instructions included in these emails or by contacting us. However,
          you will continue to receive essential transactional emails.
          <h4 className="text-xl my-5"> Links to other resources</h4>
          The Website and Services contain links to other resources that are not
          owned or controlled by us. Please be aware that we are not responsible
          for the privacy practices of such other resources or third parties. We
          encourage you to be aware when you leave the Website and Services and
          to read the privacy statements of each and every resource that may
          collect Personal Information.
          <h4 className="text-xl my-5"> Information security</h4>
          We secure information you provide on computer servers in a controlled,
          secure environment, protected from unauthorized access, use, or
          disclosure. We maintain reasonable administrative, technical, and
          physical safeguards in an effort to protect against unauthorized
          access, use, modification, and disclosure of Personal Information in
          its control and custody. However, no data transmission over the
          Internet or wireless network can be guaranteed. Therefore, while we
          strive to protect your Personal Information, you acknowledge that (i)
          there are security and privacy limitations of the Internet which are
          beyond our control; (ii) the security, integrity, and privacy of any
          and all information and data exchanged between you and the Website and
          Services cannot be guaranteed; and (iii) any such information and data
          may be viewed or tampered with in transit by a third party, despite
          best efforts.
          <h4 className="text-xl my-5"> Data breach</h4>
          In the event we become aware that the security of the Website and
          Services has been compromised or users Personal Information has been
          disclosed to unrelated third parties as a result of external activity,
          including, but not limited to, security attacks or fraud, we reserve
          the right to take reasonably appropriate measures, including, but not
          limited to, investigation and reporting, as well as notification to
          and cooperation with law enforcement authorities. In the event of a
          data breach, we will make reasonable efforts to notify affected
          individuals if we believe that there is a reasonable risk of harm to
          the user as a result of the breach or if notice is otherwise required
          by law. When we do, we will send you an email.
          <h4 className="text-xl my-5"> Changes and amendments</h4>
          We reserve the right to modify this Policy or its terms relating to
          the Website and Services from time to time in our discretion and will
          notify you of any material changes to the way in which we treat
          Personal Information. When we do, we will send you an email to notify
          you. We may also provide notice to you in other ways in our
          discretion, such as through contact information you have provided. Any
          updated version of this Policy will be effective immediately upon the
          posting of the revised Policy unless otherwise specified. Your
          continued use of the Website and Services after the effective date of
          the revised Policy (or such other act specified at that time) will
          constitute your consent to those changes. However, we will not,
          without your consent, use your Personal Information in a manner
          materially different than what was stated at the time your Personal
          Information was collected.
          <h4 className="text-xl my-5"> Acceptance of this policy</h4>
          You acknowledge that you have read this Policy and agree to all its
          terms and conditions. By accessing and using the Website and Services
          you agree to be bound by this Policy. If you do not agree to abide by
          the terms of this Policy, you are not authorized to access or use the
          Website and Services.
          <h4 className="text-xl my-5"> Contacting us</h4>
          If you would like to contact us to understand more about this Policy
          or wish to contact us concerning any matter relating to individual
          rights and your Personal Information, you may send an email to{" "}
          <a className="underline" href={`mailto:${contactEmailAddress}`}>
            {contactEmailAddress}
          </a>
          <p className="my-5">
            This document was last updated on July 16, 2024
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};
