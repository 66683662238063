import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { ConfigDrawer } from "./components/configdrawer";
import { ConfirmBox } from "./components/confirmbox";
import Flow from "./components/flow";
import { Loader } from "./components/loader";
import { LoggedInNavBarContent, NavBar } from "./components/navbar";
import { PropertyDrawer } from "./components/propertydrawer";
import { RelationFieldSelectModal } from "./components/react-flow-type/relationfieldselectmodal";
import { FieldSelectModal } from "./components/react-flow-type/singlemodel";
import { TemplateModal } from "./components/selectpredefinedtemplate";
import { SideBar } from "./components/sidebar";
import { useCodeGenerationStore, useProjectStore } from "./model/store";
import { setupResponseInterceptor } from "./utils/api";
import { containerBackground } from "./utils/contants";

const App: React.FC = () => {
  const hasHydrated = useHydration();

  const codeGenerationInProgress = !useCodeGenerationStore(
    (state) => state.in_progress,
  );

  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      setupResponseInterceptor(navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavBar content={<LoggedInNavBarContent />} />
      <div style={{ height: "100vh", overflowX: "scroll" }} className="flex">
        <div className="flex-none">
          <SideBar />
        </div>
        <div className={"flex-1 " + containerBackground}>
          <div
            style={{
              height: "90vh",
              minWidth: "100%",
              overflowX: "scroll",
            }}
          >
            <Flow />
          </div>
        </div>
      </div>
      <PropertyDrawer />
      <ConfigDrawer />
      <FieldSelectModal />
      <RelationFieldSelectModal />
      <ConfirmBox />
      <TemplateModal />

      {hasHydrated ? <></> : <Loader text="Loading" />}
      {codeGenerationInProgress ? <></> : <Loader text="Generating Code" />}
    </>
  );
};

const useHydration: () => boolean = () => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    // Note: This is just in case you want to take into account manual rehydration.
    // You can remove the following line if you don't need it.
    const unsubHydrate = useProjectStore.persist.onHydrate(() =>
      setHydrated(false),
    );

    const unsubFinishHydration = useProjectStore.persist.onFinishHydration(() =>
      setHydrated(true),
    );

    setHydrated(useProjectStore.persist.hasHydrated());

    return () => {
      unsubHydrate();
      unsubFinishHydration();
    };
  }, []);

  return hydrated;
};

export default App;
