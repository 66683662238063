import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Modal, TextInput } from "flowbite-react";
import { FC, useEffect, useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { z } from "zod";
import { useShallow } from "zustand/react/shallow";
import { ProjectConfigSchema, ProjectSchema } from "../fields/field";
import {
  Action,
  useAuthenticationStore,
  useModalStore,
  useProjectStore,
} from "../model/store";
import { errorTextClass, notify } from "../utils/contants";
import { createAuthAppModel, isComingSoon } from "../utils/utils";
import { decideFieldInputType } from "./utils";

const ProjectNameForm: FC = (): JSX.Element => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const openUpsertProjectModal = useModalStore(
    (state) => state.openUpsertProjectModal,
  );
  const setOpenUpsertProjectModal = useModalStore(
    (state) => state.setOpenUpsertProjectModal,
  );

  const projectNameSchema = ProjectSchema.pick({ name: true }).required();
  type projectNameType = z.infer<typeof projectNameSchema>;

  const upsertProject = useProjectStore((state) => state.upsertProject);
  const { name: projectName, config: projectConfig } = useProjectStore(
    useShallow((state) => ({
      name: state.project.name,
      config: state.project.config,
    })),
  );

  const user = useAuthenticationStore((state) => state.user);

  const onSubmit: SubmitHandler<projectNameType> = (data) => {
    upsertProject({
      project: {
        name: data.name.toLowerCase(),
        apps: [createAuthAppModel()],
        // config: projectConfig ?? ProjectConfigSchema.parse({}),
      },
      action: Action.ADD,
    });

    if (projectName) {
      notify("Project successfully updated");
    } else {
      notify("Project successfully created");
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    formState: { errors },
  } = useForm<projectNameType>({
    resolver: zodResolver(projectNameSchema),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedConfigSchema: any = ProjectConfigSchema._def.schema;
  type selectedConfigType = z.infer<typeof selectedConfigSchema>;

  const {
    register: configRegister,
    handleSubmit: configHandleSubmit,
    formState: { errors: configErrors },
  } = useForm<selectedConfigType>({
    reValidateMode: "onSubmit",
    resolver: zodResolver(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ProjectConfigSchema.superRefine((entries: any, ctx) => {
        // LOGIC HERE SHOULD MATCH `configdrawer.tsx (for openConfigDrawer.type === "PROJECT")`
        const featureList = user?.plan_permission.access_feature_list;
        const allowedFeatureList =
          featureList == null || featureList == undefined ? [] : featureList;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const schemaDefault: any = ProjectConfigSchema.parse({});

        const configCanBeChecked = function (element: string): boolean {
          if (entries[element] != schemaDefault[element]) {
            // if what is supplied as value does not match default, then we want to validate such entry
            if (isComingSoon(element)) return false; // if the entry property is marked as coming soon, false, it cannot be changed (has to be default)
            return allowedFeatureList.includes(element); // if the entry property is part of the user feature list then user can change it (return true), else false (cannot be changed)
          } else {
            return true; // if what is supplied as value match default, then we do not want to validate such entry
          }
        };
        if (!Object.keys(entries).every(configCanBeChecked)) {
          notify(
            "Pro features are not supported by your current plan, kindly Upgrade",
            true,
          );
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Pro features are not supported by your plan, kindly Upgrade`,
          });
        }
      }),
    ),
    defaultValues: selectedConfigSchema.parse({
      ...projectConfig,
    }),
  });

  const onConfigSubmit: SubmitHandler<selectedConfigType> = (data, event) => {
    handleSubmit(onSubmit)(event);
    upsertProject({
      project: { config: data },
      action: Action.EDIT,
    });
    setOpenUpsertProjectModal(false);
  };

  useEffect(() => {
    if (projectName !== undefined) {
      setValue("name", projectName);
    } else {
      resetField("name");
    }
  }, [projectName, setValue, resetField]);

  const properties = selectedConfigSchema
    .keyof()
    .options.map((option: string) => {
      const _def = selectedConfigSchema.shape[option]._def;
      const typeName = _def.typeName;
      return (
        <div key={option + "key"}>
          {decideFieldInputType({
            typeName: typeName,
            fieldName: option,
            registerHook: configRegister,
            _def: _def,
            errors: configErrors,
            featureList: user?.plan_permission.access_feature_list,
          })}
          <hr className="h-px border-0 mt-2 bg-gray-200 dark:bg-gray-600" />
        </div>
      );
    });

  return (
    <div>
      <Button
        size="lg"
        onClick={() => setOpenUpsertProjectModal(true)}
        className={twMerge(["w-full", projectName ? "hidden" : "show"])}
      >
        New Project
      </Button>
      <h5 className="text-base text-gray-900 dark:text-white truncate">
        {projectName}
      </h5>
      <Modal
        show={openUpsertProjectModal}
        size="xl"
        popup
        onClose={() => setOpenUpsertProjectModal(false)}
        initialFocus={emailInputRef}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h4 className="text-xl font-medium text-gray-900 dark:text-white">
              Project
            </h4>
            <form
              onSubmit={(event) => {
                configHandleSubmit(onConfigSubmit)(event);
              }}
            >
              <TextInput
                id="text"
                placeholder="Project Name"
                {...register("name")}
                required={true}
              />

              <div className={errorTextClass}>
                {errors.name?.message && (
                  <p>{errors.name.message.toString()}</p>
                )}
              </div>

              <div className="grid grid-cols-2 gap-5 my-6 ">{properties}</div>

              <Button className="w-full my-3" type="submit">
                {projectName ? "Edit Project" : "Create Project"}
              </Button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export { ProjectNameForm };
