import { Avatar, Button, CustomFlowbiteTheme, Label } from "flowbite-react";
import { ComponentProps, useEffect, useState } from "react";
import { IconType } from "react-icons";
import { FaClock, FaEnvelope, FaUserCircle } from "react-icons/fa";
import { HiCheckCircle, HiXCircle } from "react-icons/hi";
import { IoCodeWorkingSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useAuthenticationStore, useProfileStore } from "../../model/store";
import { setupResponseInterceptor } from "../../utils/api";
import { dayjs, getFullName } from "../../utils/utils";
import { ConfirmBox } from "../confirmbox";
import { Footer } from "../footer";
import { NavBar } from "../navbar";
import { NeedHelpAlert } from "../need_help";
import { ProjectList } from "./project-list";
export default function ProfilePage() {
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);
  const fetchAppConfig = useProfileStore((state) => state.fetchAppConfig);

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
      setupResponseInterceptor(navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchAppConfig();
  }, []);

  // const appConfig = useProfileStore((state) => state.appConfig);
  // console.log(appConfig);

  return (
    <>
      <div style={{ minHeight: "80vh" }}>
        <NavBar
          extraClass="fixed w-full z-20 top-0 start-0"
          activeLink="profile"
        />
        <section className="mt-20 pt-12 pb-16 px-4 mx-auto max-w-screen-xl  lg:px-6">
          <div className="flex md:justify-end mb-6 lg:mr-20">
            <Link to={`/studio`}>
              <Button size="sm">
                <IoCodeWorkingSharp className="mr-2 h-5 w-5" />
                Code Studio
              </Button>
            </Link>
          </div>
          <div className="flex md:flex-nowrap flex-wrap gap-8">
            <Profile />
            <div className="grow">
              <ProjectList />
            </div>
          </div>
          <div className="mt-36">
            <NeedHelpAlert />
          </div>
        </section>
      </div>
      <Footer />
      {/* {inProgress ? <Loader text="Please wait!" /> : <></>} */}
      <ConfirmBox />
    </>
  );
}

function Profile() {
  const user = useAuthenticationStore((state) => state.user);

  const customAvatarTheme: CustomFlowbiteTheme["avatar"] = {
    root: {
      size: {
        xl: "h-60 w-60",
      },
      initials: {
        text: "font-medium text-3xl text-gray-600 dark:text-gray-300",
      },
    },
  };
  return (
    <div className="flex items-start flex-col justify-start gap-4">
      <Avatar
        theme={customAvatarTheme}
        // placeholderInitials="RR"
        size="xl"
        className="mb-4"
        // rounded
      />

      <div className="flex flex-col gap-1">
        <Label className="text-base font-light truncate">Subscription</Label>
        <div className="flex gap-2 align-middle items-center">
          {user?.has_active_subscription ? (
            <HiCheckCircle size={14} color="green" />
          ) : (
            <HiXCircle color="red" size={14} />
          )}
          <Label
            style={{ color: user?.has_active_subscription ? "green" : "red" }}
            className="text-base font-bold"
          >
            {user?.has_active_subscription ? "Active" : "Inactive"}
          </Label>
        </div>
      </div>

      <SingleProfileLine
        icon={FaUserCircle}
        label="Name"
        text={getFullName(user)}
      />

      <SingleProfileLine
        icon={FaEnvelope}
        label="Email"
        text={`${user?.email}`}
      />
      <SingleProfileLine
        icon={FaClock}
        label="Last Login"
        text={dayjs(user?.last_login).format("lll")}
      />

      <PaddleCheckout />
    </div>
  );
}

export function PaddleCheckout() {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate("/pricing");
      }}
      gradientDuoTone="greenToBlue"
      className="mt-4 w-full"
    >
      Upgrade Plan
    </Button>
  );
}

interface ISingleProfileLine extends ComponentProps<"p"> {
  icon: IconType;
  text: string;
  label: string;
}
export const SingleProfileLine: React.FC<ISingleProfileLine> = (
  props: ISingleProfileLine,
) => {
  return (
    <div className="flex flex-col gap-1">
      <Label className="text-base font-light truncate">{props.label}</Label>
      <div className="flex gap-2 align-middle items-center">
        <props.icon size={12} />
        <Label className="text-base font-light truncate">{props.text}</Label>
      </div>
    </div>
  );
};
