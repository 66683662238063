import ReactFlow, { Background, Controls } from "reactflow";
import "reactflow/dist/style.css";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { RFState, useFlowStore } from "../model/store";
import { SingleModelNode } from "./react-flow-type/singlemodel";

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  onConnectStart: state.onConnectStart,
});

function Flow() {
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onConnectStart,
  } = useFlowStore(useShallow(selector));

  const nodeTypes = useMemo(() => ({ SingleModelNode: SingleModelNode }), []);

  return (
    <div style={{ height: "100%" }}>
      <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        panOnScroll={true}
        preventScrolling={true}
        // fitView
      >
        <Background className="dark:bg-gray-900" />
        <Controls className="dark:stroke-gray-300 dark:fill-gray-300" />
      </ReactFlow>
    </div>
  );
}

export default Flow;
