import { ProjectType } from "./fields/field";

export const BlogTemplate: ProjectType = {
  name: "Blog",
  apps: [
    {
      appId: "AUTH_APP_ID",
      name: "authentication",
      models: [
        {
          modelId: "AUTH_APP_MODEL_ID",
          appId: "AUTH_APP_ID",
          name: "User",
          fields: [],
        },
      ],
    },
    {
      appId: "RSqX_VfHBZlwl62X2Y5NK",
      name: "Blog",
      models: [
        {
          modelId: "j_aDv2DVvhhABS43t1q_Q",
          appId: "RSqX_VfHBZlwl62X2Y5NK",
          name: "Post",
          fields: [
            {
              fieldId: "HsAMxaXJWgJLqMlNQtC6E",
              modelId: "j_aDv2DVvhhABS43t1q_Q",
              appId: "RSqX_VfHBZlwl62X2Y5NK",
              name: "title",
              field: {
                type: "CharField",
                max_length: 200,
              },
            },
            {
              fieldId: "vhhAxaXJWgJLqMHBZlw6E",
              modelId: "j_aDv2DVvhhABS43t1q_Q",
              appId: "RSqX_VfHBZlwl62X2Y5NK",
              name: "description",
              field: {
                type: "CharField",
                max_length: 200,
              },
            },
            {
              fieldId: "zRxpLAmzjtjeKscQMXDRo",
              modelId: "j_aDv2DVvhhABS43t1q_Q",
              appId: "RSqX_VfHBZlwl62X2Y5NK",
              name: "content",
              field: {
                type: "TextField",
              },
            },
            {
              fieldId: "TXAZfMiwVeJUqKDGxCtQW",
              modelId: "j_aDv2DVvhhABS43t1q_Q",
              appId: "RSqX_VfHBZlwl62X2Y5NK",
              name: "posted_at",
              field: {
                type: "DateTimeField",
                auto_now: false,
                auto_now_add: false,
              },
            },
            {
              fieldId: "ByWKMp3_AGMp3OwDPoB8d",
              modelId: "j_aDv2DVvhhABS43t1q_Q",
              appId: "RSqX_VfHBZlwl62X2Y5NK",
              name: "post_by",
              field: {
                type: "ForeignKey",
                from: {
                  modelId: "j_aDv2DVvhhABS43t1q_Q",
                  appId: "RSqX_VfHBZlwl62X2Y5NK",
                  reference: "blog.Post",
                },
                to: {
                  modelId: "AUTH_APP_MODEL_ID",
                  appId: "AUTH_APP_ID",
                  reference: "authentication.User",
                },
                on_delete: "CASCADE",
                swappable: true,
                db_constraint: true,
              },
            },
          ],
        },
        {
          modelId: "_jJlGGQ5T27uZEPLENnEj",
          appId: "RSqX_VfHBZlwl62X2Y5NK",
          name: "Comment",
          fields: [
            {
              fieldId: "rHjgfvlMwlcscTMMuVM-B",
              modelId: "_jJlGGQ5T27uZEPLENnEj",
              appId: "RSqX_VfHBZlwl62X2Y5NK",
              name: "post",
              field: {
                type: "ForeignKey",
                from: {
                  modelId: "_jJlGGQ5T27uZEPLENnEj",
                  appId: "RSqX_VfHBZlwl62X2Y5NK",
                  reference: "blog.Comment",
                },
                to: {
                  modelId: "j_aDv2DVvhhABS43t1q_Q",
                  appId: "RSqX_VfHBZlwl62X2Y5NK",
                  reference: "blog.Post",
                },
                on_delete: "CASCADE",
                swappable: true,
                db_constraint: true,
              },
            },
            {
              fieldId: "77X-oCI5Tjg553nGXQQ_m",
              modelId: "_jJlGGQ5T27uZEPLENnEj",
              appId: "RSqX_VfHBZlwl62X2Y5NK",
              name: "comment_by",
              field: {
                from: {
                  modelId: "_jJlGGQ5T27uZEPLENnEj",
                  appId: "RSqX_VfHBZlwl62X2Y5NK",
                  reference: "blog.Comment",
                },
                to: {
                  modelId: "AUTH_APP_MODEL_ID",
                  appId: "AUTH_APP_ID",
                  reference: "authentication.User",
                },
                on_delete: "CASCADE",
                swappable: true,
                db_constraint: true,
                type: "OneToOneField",
              },
            },
            {
              fieldId: "FFvM_qUAc4_pNLHVuzPfi",
              modelId: "_jJlGGQ5T27uZEPLENnEj",
              appId: "RSqX_VfHBZlwl62X2Y5NK",
              name: "comment_at",
              field: {
                type: "DateTimeField",
              },
            },
          ],
        },
      ],
    },
  ],
};

export const PollTemplate: ProjectType = {
  name: "mysite",
  apps: [
    {
      appId: "AUTH_APP_ID",
      name: "authentication",
      models: [
        {
          modelId: "AUTH_APP_MODEL_ID",
          appId: "AUTH_APP_ID",
          name: "User",
          fields: [],
        },
      ],
    },
    {
      appId: "n9EXYxUVI-rYE45677UeG",
      name: "polls",
      models: [
        {
          modelId: "_EaEddCc9CZO72q98R89H",
          appId: "n9EXYxUVI-rYE45677UeG",
          name: "Question",
          fields: [
            {
              fieldId: "npfUpbX5caf8fvVFEZDCE",
              modelId: "_EaEddCc9CZO72q98R89H",
              appId: "n9EXYxUVI-rYE45677UeG",
              name: "question_text",
              field: {
                type: "CharField",
                max_length: 200,
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: false,
                unique: false,
              },
            },
            {
              fieldId: "QnTvDW3nfFFibof7lHsp9",
              modelId: "_EaEddCc9CZO72q98R89H",
              appId: "n9EXYxUVI-rYE45677UeG",
              name: "pub_date",
              field: {
                type: "DateTimeField",
              },
            },
          ],
        },
        {
          modelId: "3z-MIrr0Gdi4N0uVBD03g",
          appId: "n9EXYxUVI-rYE45677UeG",
          name: "Choice",
          fields: [
            {
              fieldId: "qpAoHrOhvn8BDCQH8GEUZ",
              modelId: "3z-MIrr0Gdi4N0uVBD03g",
              appId: "n9EXYxUVI-rYE45677UeG",
              name: "question",
              field: {
                type: "ForeignKey",
                from: {
                  modelId: "3z-MIrr0Gdi4N0uVBD03g",
                  appId: "n9EXYxUVI-rYE45677UeG",
                  reference: "poll.Choice",
                },
                to: {
                  modelId: "_EaEddCc9CZO72q98R89H",
                  appId: "n9EXYxUVI-rYE45677UeG",
                  reference: "poll.Question",
                },
                on_delete: "CASCADE",
                swappable: true,
                db_constraint: true,
              },
            },
            {
              fieldId: "qpP6aOTT5dd05nWEVO2Up",
              modelId: "3z-MIrr0Gdi4N0uVBD03g",
              appId: "n9EXYxUVI-rYE45677UeG",
              name: "choice_text",
              field: {
                type: "CharField",
                max_length: 200,
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: false,
                unique: false,
              },
            },
            {
              fieldId: "q4NzR3s-mpvRN4gzwiN3k",
              modelId: "3z-MIrr0Gdi4N0uVBD03g",
              appId: "n9EXYxUVI-rYE45677UeG",
              name: "votes",
              field: {
                type: "IntegerField",
              },
            },
          ],
        },
      ],
    },
  ],
};

export const LibraryTemplate: ProjectType = {
  name: "locallibrary",
  apps: [
    {
      appId: "AVKIIkef6KWgBNPlxKHHX",
      name: "catalog",
      models: [
        {
          modelId: "XRiHsFdnZSHwZtIqPNEMK",
          appId: "AVKIIkef6KWgBNPlxKHHX",
          name: "Genre",
          fields: [
            {
              fieldId: "P2yOFG4DllNMxgkmxa4HK",
              modelId: "XRiHsFdnZSHwZtIqPNEMK",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "name",
              field: {
                type: "CharField",
                max_length: 200,
                default: "",
                db_column: "",
                db_comment: "",
                help_text:
                  "Enter a book genre (e.g. Science Fiction, French Poetry etc.)",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: false,
                unique: true,
              },
            },
          ],
        },
        {
          modelId: "h-_-Kx7fJpdjzbrgEkBFW",
          appId: "AVKIIkef6KWgBNPlxKHHX",
          name: "Language",
          fields: [
            {
              fieldId: "i2_WJxd4hDKqOCDLWYjWY",
              modelId: "h-_-Kx7fJpdjzbrgEkBFW",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "name",
              field: {
                type: "CharField",
                max_length: 200,
                default: "",
                db_column: "",
                db_comment: "",
                help_text:
                  "Enter the books natural language (e.g. English, French, Japanese etc.)",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: false,
                unique: true,
              },
            },
          ],
        },
        {
          modelId: "Je-kRwb0pc5Pun3YnDdZU",
          appId: "AVKIIkef6KWgBNPlxKHHX",
          name: "Book",
          fields: [
            {
              fieldId: "hhansYq_tsHmcnj4AB7HB",
              modelId: "Je-kRwb0pc5Pun3YnDdZU",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "title",
              field: {
                type: "CharField",
                max_length: 200,
                default: "",
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: false,
                unique: false,
              },
            },
            {
              fieldId: "tcJ7Iqf9L-vj0Q2ehN5ep",
              modelId: "Je-kRwb0pc5Pun3YnDdZU",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "author",
              field: {
                type: "ForeignKey",
                from: {
                  modelId: "Je-kRwb0pc5Pun3YnDdZU",
                  appId: "AVKIIkef6KWgBNPlxKHHX",
                  reference: "catalog.Book",
                },
                to: {
                  modelId: "oI7t-I6t9waYddYKzS12I",
                  appId: "AVKIIkef6KWgBNPlxKHHX",
                  reference: "catalog.Author",
                },
                on_delete: "RESTRICT",
                related_name: "",
                related_query_name: "",
                to_field: "",
                swappable: true,
                db_constraint: true,
                default: "",
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: false,
                unique: false,
              },
            },
            {
              fieldId: "ejAFEvWwcyxCsb8xz-jWB",
              modelId: "Je-kRwb0pc5Pun3YnDdZU",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "summary",
              field: {
                type: "TextField",
                max_length: 1000,
                db_collation: "",
                default: "",
                db_column: "",
                db_comment: "",
                help_text: "Enter a brief description of the book",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: false,
                unique: false,
              },
            },
            {
              fieldId: "lT4Rut3mLal_Vv_5mvlyh",
              modelId: "Je-kRwb0pc5Pun3YnDdZU",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "isbn",
              field: {
                type: "CharField",
                max_length: 13,
                default: "",
                db_column: "",
                db_comment: "",
                help_text:
                  '13 Character <a href="https://www.isbn-international.org/content/what-isbn">ISBN number</a>',
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: false,
                unique: true,
              },
            },
            {
              fieldId: "arrMtiTHv4ziL-EzNPcfN",
              modelId: "Je-kRwb0pc5Pun3YnDdZU",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "genre",
              field: {
                type: "ManyToManyField",
                from: {
                  modelId: "Je-kRwb0pc5Pun3YnDdZU",
                  appId: "AVKIIkef6KWgBNPlxKHHX",
                  reference: "catalog.Book",
                },
                to: {
                  modelId: "XRiHsFdnZSHwZtIqPNEMK",
                  appId: "AVKIIkef6KWgBNPlxKHHX",
                  reference: "catalog.Genre",
                },
                db_constraint: true,
                swappable: true,
              },
            },
            {
              fieldId: "EXVimNxYbHQBXSpm9SQBk",
              modelId: "Je-kRwb0pc5Pun3YnDdZU",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "language",
              field: {
                type: "ForeignKey",
                from: {
                  modelId: "Je-kRwb0pc5Pun3YnDdZU",
                  appId: "AVKIIkef6KWgBNPlxKHHX",
                  reference: "catalog.Book",
                },
                to: {
                  modelId: "h-_-Kx7fJpdjzbrgEkBFW",
                  appId: "AVKIIkef6KWgBNPlxKHHX",
                  reference: "catalog.Language",
                },
                on_delete: "SET_NULL",
                related_name: "",
                related_query_name: "",
                to_field: "",
                swappable: true,
                db_constraint: true,
                default: "",
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: true,
                primary_key: false,
                unique: false,
              },
            },
          ],
        },
        {
          modelId: "oI7t-I6t9waYddYKzS12I",
          appId: "AVKIIkef6KWgBNPlxKHHX",
          name: "Author",
          fields: [
            {
              fieldId: "SbGKfG1JbyqL4yRzKGVm2",
              modelId: "oI7t-I6t9waYddYKzS12I",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "first_name",
              field: {
                type: "CharField",
                max_length: 200,
                default: "",
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: false,
                unique: false,
              },
            },
            {
              fieldId: "M3-4qL9qZ1oq7xtt6_-LA",
              modelId: "oI7t-I6t9waYddYKzS12I",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "last_name",
              field: {
                type: "CharField",
                max_length: 200,
                default: "",
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: false,
                unique: false,
              },
            },
            {
              fieldId: "ynV6CCt8RKinovJO4rFwb",
              modelId: "oI7t-I6t9waYddYKzS12I",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "date_of_birth",
              field: {
                type: "DateField",
                auto_now: false,
                auto_now_add: false,
                default: "",
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: true,
                null: true,
                primary_key: false,
                unique: false,
              },
            },
            {
              fieldId: "JxTbXFkDRkKdAz5BEDkch",
              modelId: "oI7t-I6t9waYddYKzS12I",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "date_of_death",
              field: {
                type: "DateField",
                auto_now: false,
                auto_now_add: false,
                default: "",
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: true,
                null: true,
                primary_key: false,
                unique: false,
              },
            },
          ],
        },
        {
          modelId: "xPARUkT-ZELL7bdhN9HIR",
          appId: "AVKIIkef6KWgBNPlxKHHX",
          name: "BookInstance",
          fields: [
            {
              fieldId: "7z0UEvGwEgGSNO47mQ82l",
              modelId: "xPARUkT-ZELL7bdhN9HIR",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "id",
              field: {
                type: "UUIDField",
                default: "",
                db_column: "",
                db_comment: "",
                help_text:
                  "Unique ID for this particular book across whole library",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: true,
                unique: false,
              },
            },
            {
              fieldId: "ow2mFSrsr9GkyxtthBmNc",
              modelId: "xPARUkT-ZELL7bdhN9HIR",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "book",
              field: {
                type: "ForeignKey",
                from: {
                  modelId: "xPARUkT-ZELL7bdhN9HIR",
                  appId: "AVKIIkef6KWgBNPlxKHHX",
                  reference: "catalog.BookInstance",
                },
                to: {
                  modelId: "Je-kRwb0pc5Pun3YnDdZU",
                  appId: "AVKIIkef6KWgBNPlxKHHX",
                  reference: "catalog.Book",
                },
                on_delete: "RESTRICT",
                related_name: "",
                related_query_name: "",
                to_field: "",
                swappable: true,
                db_constraint: true,
                default: "",
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: true,
                primary_key: false,
                unique: false,
              },
            },
            {
              fieldId: "5oXg3FQqZ2OHvhto9qHrp",
              modelId: "xPARUkT-ZELL7bdhN9HIR",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "imprint",
              field: {
                type: "CharField",
                max_length: 200,
                default: "",
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: false,
                null: false,
                primary_key: false,
                unique: false,
              },
            },
            {
              fieldId: "u4x7nus974GPXNV-QVej5",
              modelId: "xPARUkT-ZELL7bdhN9HIR",
              appId: "AVKIIkef6KWgBNPlxKHHX",
              name: "due_back",
              field: {
                type: "DateField",
                auto_now: false,
                auto_now_add: false,
                default: "",
                db_column: "",
                db_comment: "",
                help_text: "",
                verbose_name: "",
                db_index: false,
                editable: true,
                blank: true,
                null: true,
                primary_key: false,
                unique: false,
              },
            },
          ],
        },
      ],
    },
  ],
};
