import {
  Button,
  CustomFlowbiteTheme,
  DarkThemeToggle,
  Navbar,
  Tooltip,
} from "flowbite-react";
import { FC, ReactNode } from "react";
import { BiExport, BiImport } from "react-icons/bi";
import { BsSend } from "react-icons/bs";
import { HiTemplate } from "react-icons/hi";
import { MdOutlineDelete } from "react-icons/md";
import { ClassNameValue, twMerge } from "tailwind-merge";

import { Avatar, Dropdown } from "flowbite-react";
import { IoCodeDownloadOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { ProjectSchema } from "../fields/field";
import {
  useAuthenticationStore,
  useCodeGenerationStore,
  useFlowStore,
  useModalStore,
  useProjectStore,
} from "../model/store";
import {
  ALLOW_CODE_DOWNLOAD,
  documentationBaseUrl,
  notify,
} from "../utils/contants";
interface INavBar {
  content?: ReactNode;
  extraClass?: ClassNameValue;
  activeLink?: string;
}

const customTheme: CustomFlowbiteTheme["navbar"] = {
  root: {
    base: "bg-gray-100 px-2 py-2.5 dark:border-gray-700 dark:bg-gray-800 sm:px-4",
    inner: {
      base: "mx-auto flex flex-wrap items-center gap-8 py-3",
    },
  },
};
const NavBar: FC<INavBar> = (props: INavBar): JSX.Element => {
  const navigate = useNavigate();

  const user = useAuthenticationStore((state) => state.user);

  return (
    <Navbar fluid theme={customTheme} className={twMerge(props.extraClass)}>
      <Navbar.Brand href="#">
        <IoCodeDownloadOutline className="mr-1 h-8 w-8 text-primary-400" />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Django Compose
        </span>
      </Navbar.Brand>
      <Navbar.Collapse>
        <Navbar.Link
          href="#"
          onClick={(event) => {
            event.preventDefault();
            navigate(user ? "/profile" : "/");
          }}
          active={props.activeLink == "home"}
        >
          Home
        </Navbar.Link>
        {user && (
          <Navbar.Link
            href="#"
            onClick={(event) => {
              event.preventDefault();
              navigate("/profile");
            }}
            active={props.activeLink == "profile"}
          >
            Profile
          </Navbar.Link>
        )}
        <Navbar.Link
          href="#"
          onClick={(event) => {
            event.preventDefault();
            navigate("/pricing");
          }}
          active={props.activeLink == "pricing"}
        >
          Pricing
        </Navbar.Link>
      </Navbar.Collapse>
      <div className="flex-grow"></div>
      <DarkThemeToggle />
      {props.content}
      {user && <ProfileAvatar />}
    </Navbar>
  );
};

export const LoggedInNavBarContent = () => {
  const setProject = useProjectStore((state) => state.setProject);
  const setIsEditMode = useProjectStore((state) => state.setIsEditMode);
  const project = useProjectStore((state) => state.project);
  const hasEmptyModel = useProjectStore((state) => state.hasEmptyModel);
  const hasEmptyApp = useProjectStore((state) => state.hasEmptyApp);
  const setOpenConfirmBox = useModalStore((state) => state.setOpenConfirmBox);
  const setNodes = useFlowStore((state) => state.setNodes);
  const setOpenTemplateModal = useModalStore(
    (state) => state.setOpenTemplateModal,
  );
  const nodes = useFlowStore((state) => state.nodes);

  const _onInputConfirm = (file: File) => {
    const _nodes = nodes;
    file?.text().then((text) => {
      try {
        setNodes([]);
        setProject(ProjectSchema.parse(JSON.parse(text)));
        setOpenTemplateModal(false);
        notify("Import successfully loaded.");
      } catch (Error) {
        setNodes(_nodes);

        notify("Error importing template.", true);
      }
    });
  };

  const generateCode = useCodeGenerationStore((state) => state.generateCode);

  const submitData = () => {
    generateCode()
      .then((download_link) => {
        if (download_link.includes("Error")) {
          notify(download_link, true);
        } else {
          notify("Code generated successfully");
          if (ALLOW_CODE_DOWNLOAD) {
            const element = document.createElement("a");
            element.setAttribute("href", download_link);
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          }
          notify("Code Downloaded");
        }
      })
      .catch((error) => {
        notify(error, true);
      });
  };
  return (
    <div className="flex items-center gap-2">
      <div className="inline-flex">
        <Tooltip content="Clear">
          <Button
            color="failure"
            onClick={() => {
              setOpenConfirmBox({
                open: true,
                title: "Clear project",
                description: "This will delete current work!",
                onConfirm: () => {
                  useProjectStore.persist.clearStorage();
                  setProject(ProjectSchema.parse({}));
                  setIsEditMode(false);
                  setNodes([]);
                },
                onCancel: () => {},
              });
            }}
            className="rounded-r-none focus:ring-2"
          >
            <MdOutlineDelete />
          </Button>
        </Tooltip>
        <Tooltip content="Export">
          <Button
            onClick={() => {
              const element = document.createElement("a");
              element.setAttribute(
                "href",
                "data:text/plain;charset=utf-8," +
                  encodeURIComponent(
                    JSON.stringify(ProjectSchema.parse(project), undefined, 2),
                  ),
              );
              element.setAttribute("download", "download.djangocompose.json");

              element.style.display = "none";
              document.body.appendChild(element);

              element.click();

              document.body.removeChild(element);
              notify("Export successfully");
            }}
            className="rounded-none border-l-0 pl-0 focus:ring-2"
          >
            <BiExport />
          </Button>
        </Tooltip>
        <Tooltip content="Import">
          <Button
            onClick={() => {
              setOpenConfirmBox({
                open: true,
                title: "Import template",
                description: "The imported project will override current work!",
                onConfirm: () => document.getElementById("file")?.click(),
                onCancel: () => {},
              });
            }}
            className="rounded-none border-l-0 pl-0 focus:ring-2"
          >
            <BiImport />
            <input
              id="file"
              type="file"
              style={{ display: "none" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files) {
                  const file = e.target.files[0];
                  _onInputConfirm(file);
                }
              }}
            />
          </Button>
        </Tooltip>
        <Tooltip content="Template">
          <Button
            onClick={() => {
              setOpenTemplateModal(true);
            }}
            className="rounded-none border-l-0 pl-0 focus:ring-2"
          >
            <HiTemplate />
          </Button>
        </Tooltip>
        <Button
          theme={{
            inner: {
              base: "flex items-center transition-all duration-200",
            },
          }}
          size="sm"
          color="success"
          onClick={() => {
            if (project.apps.length == 0) {
              notify(
                "Project must have atleast one app to generate code.",
                true,
              );
              return;
            } else if (hasEmptyApp) {
              notify(
                "All apps must have atleast one model to generate code.",
                true,
              );
              return;
            } else if (hasEmptyModel) {
              notify(
                "All models must have atleast one field to generate code.",
                true,
              );
              return;
            } else {
              setOpenConfirmBox({
                open: true,
                title: "Generate Code",
                onConfirm: () => {
                  submitData();
                },
                onCancel: () => {},
              });
            }
          }}
          className="rounded-l-none border-l-0 pl-0 focus:ring-2 py-0"
        >
          <BsSend className="mr-2" />
          Generate
        </Button>
      </div>
      {/* <Link to={`/`}>
        <Button color="failure" size="sm">
          <HiOutlineLogout />
        </Button>
      </Link> */}
    </div>
  );
};

export function ProfileAvatar() {
  const user = useAuthenticationStore((state) => state.user);
  const navigate = useNavigate();

  const customAvatarTheme: CustomFlowbiteTheme["avatar"] = {
    root: {
      bordered: "ring-2",
      img: {
        off: "relative overflow-hidden bg-gray-200 dark:bg-gray-600",
      },
    },
  };

  const initiateLogout = useAuthenticationStore(
    (state) => state.initiateLogout,
  );

  return (
    <Dropdown
      label={
        <Avatar
          theme={customAvatarTheme}
          statusPosition="bottom-right"
          bordered
          alt="User profile"
          color="success"
          status="online"
          rounded
        />
      }
      arrowIcon={false}
      inline
    >
      <Dropdown.Header>
        <span className="block text-sm">{`${user?.first_name} ${user?.last_name}`}</span>
        <span className="block truncate text-sm font-medium">
          {user?.email}
        </span>
      </Dropdown.Header>
      <Dropdown.Item
        onClick={() => {
          navigate("/profile");
        }}
      >
        Profile
      </Dropdown.Item>
      <Dropdown.Item
        as="a"
        onClick={() => {
          navigate("/pricing");
        }}
      >
        Upgrade Plan
      </Dropdown.Item>
      <Dropdown.Item as="a" href={documentationBaseUrl} target="_blank">
        Documentation
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        onClick={() => {
          initiateLogout().then((result) => {
            if (result) {
              notify("Logout successful");
              navigate("/", { replace: true });
            }
          });
        }}
      >
        Sign out
      </Dropdown.Item>
    </Dropdown>
  );
}

export { NavBar };
