import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useModalStore } from "../model/store";

export const ConfirmBox: React.FC = () => {
  const setOpenConfirmBox = useModalStore((state) => state.setOpenConfirmBox);
  const openConfirmBox = useModalStore((state) => state.openConfirmBox);

  return (
    <>
      {openConfirmBox && (
        <Modal
          show={openConfirmBox.open}
          size="md"
          onClose={() => {
            openConfirmBox.onCancel();
            setOpenConfirmBox(null);
          }}
          popup
        >
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <HiOutlineExclamationCircle className="mx-auto mb-3 h-14 w-14 text-gray-500 dark:text-gray-300" />
              <div className="mb-5 font-normal">
                <h3 className="text-lg text-gray-500 dark:text-gray-300">
                  {openConfirmBox.title
                    ? openConfirmBox.title
                    : "Are you sure?"}
                </h3>
                <i className="text-sm text-gray-500 dark:text-gray-400">
                  {openConfirmBox.description ? openConfirmBox.description : ""}
                </i>
              </div>
              <div className="flex justify-center gap-4">
                <Button
                  size="sm"
                  color="success"
                  onClick={() => {
                    openConfirmBox.onConfirm();
                    setOpenConfirmBox(null);
                  }}
                >
                  {"Yes, I'm sure"}
                </Button>
                <Button
                  size="sm"
                  color="gray"
                  onClick={() => {
                    openConfirmBox.onCancel();
                    setOpenConfirmBox(null);
                  }}
                >
                  No, cancel
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
