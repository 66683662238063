import { Accordion } from "flowbite-react";
import { contactEmailAddress } from "../utils/contants";

interface IFAQ {
  question: string;
  answer: string | React.ReactNode;
}

const EmailSupport = () => (
  <a
    className="underline text-primary-600 dark:text-primary-500 hover:no-underline"
    href={`mailto:${contactEmailAddress}`}
  >
    email
  </a>
);

const FAQList: IFAQ[] = [
  {
    question: "What is Django Compose?",
    answer: (
      <p>
        Django Compose is an easy to use tool for creating your next Django
        project. <br />
        <br />
        Django compose takes care of the <s>basic</s>(and boring) Django setup
        stuffs for you while bootstraping a full fledged working project for
        you. <br />
        <br />
        Django compose does the chores, and lets you focus on your logic,
        allowing you to <b>Build smart, not from scratch</b> <br />
        <br />
        With few clicks, you select technologies and features you want for your
        project, and Django Compose generates a customized codebase for your
        project. Rather than starting with Django's default skeleton project,
        you begin with a pre-built foundation tailored to your needs—without any
        unnecessary components. <br />
      </p>
    ),
  },
  {
    question: "How do I start?",
    answer: (
      <p>
        Simply, Log in, access the code studio, describe your project and with
        few clicks, you have your working codebase for download. <br />
        <br />
        For more how-to, we recommend following our step-by-step{" "}
        <a
          className="underline text-primary-600 dark:text-primary-500 hover:no-underline"
          target="_blank"
        >
          guide
        </a>
        .
      </p>
    ),
  },
  {
    question: "How does support work?",
    answer: (
      <p>
        We are aware of the importance of well qualified support, that is why we
        decided that support will only be provided by the authors that actually
        worked on this project. <br />
        <br />
        You can contact us via <EmailSupport /> and our support will be prompt
        to attend to you.
      </p>
    ),
  },
  {
    question: 'What does "lifetime access" exactly mean?',
    answer:
      "Pay once and you will have access to all amazing features and quick access to the future updates, free of charge.",
  },
  {
    question: "What is your refund policy?",
    answer:
      "If you are unhappy with your purchase, just contact us within 7 days and we'll issue a full refund.",
  },
];

export const FAQSection = () => {
  return (
    <section className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
      <div className="py-4 px-4 mx-auto max-w-screen-xl sm:py-12 lg:px-6">
        <div className="">
          <div className="text-center mb-8">
            <h2 className="text-3xl mb-4 tracking-tight font-extrabold text-gray-900 dark:text-white">
              Frequently Asked Questions
            </h2>
            <p>
              Have another question? Contact us by <EmailSupport />.
            </p>
          </div>
          <div className="mx-auto lg:max-w-4xl">
            <Accordion flush>
              {FAQList.map((faq, index) => (
                <Accordion.Panel key={`faq-${index}`}>
                  <Accordion.Title>{faq.question}</Accordion.Title>
                  <Accordion.Content>{faq.answer}</Accordion.Content>
                </Accordion.Panel>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

// export const FAQSection = () => {
//   return (
//     <section className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
//       <div className="py-4 px-4 mx-auto max-w-screen-xl sm:py-12 lg:px-6">
//         <h2 className="mb-8 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
//           Frequently asked questions
//         </h2>
//         <div className="grid pt-4 text-left border-t border-gray-200 md:gap-8 dark:border-gray-700 md:grid-cols-2">
//           {FAQList.map((faq, index) => (
//             <div key={`faq-${index}`}>
//               <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
//                 <svg
//                   className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"
//                   fill="currentColor"
//                   viewBox="0 0 20 20"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     fillRule="evenodd"
//                     d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
//                     clipRule="evenodd"
//                   ></path>
//                 </svg>
//                 {faq.question}
//               </h3>
//               {faq.answer}
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };
