import { Button } from "flowbite-react";
import { FaGithub } from "react-icons/fa";
import { useAuthenticationStore } from "../../model/store";
import { documentationBaseUrl, notify } from "../../utils/contants";
import { HiOutlineDocumentText } from "react-icons/hi";

export const HeroSection: React.FC = () => {
  const getAuthCode = useAuthenticationStore((state) => state.getAuthCode);
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="mt-20 pt-12 pb-16 px-4 mx-auto max-w-screen-xl text-center  lg:px-12">
          <a
            href="#features"
            className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
            role="alert"
          >
            <span className="text-xs bg-primary-400 rounded-full text-white px-4 py-1.5 mr-3">
              New
            </span>
            <span className="text-sm font-medium">
              Django Compose! what's it about
            </span>
            <svg
              className="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
          <div style={{ display: "grid", placeItems: "center" }}>
            <h1 className="mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-4xl lg:text-5xl dark:text-white text-center pb-2 typing-demo">
              {/* Fast Start Django Project 🚀 */}
              Build smart, <s>from scratch</s> 🚀
            </h1>
          </div>
          <p className="mb-8 text-lg font-normal text-gray-900 dark:text-white lg:text-xl sm:px-16 xl:px-48">
            Gain <i>10x</i> better start, more productivity, and faster time to
            market for your next <b>Django</b> <br /> side projects, unicorn, or
            B2B SaaS apps. <br />
            <b className="italic">
              Deploy in <s>Months</s> Days
            </b>
          </p>
          <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <Button
              gradientDuoTone="pinkToOrange"
              onClick={() => {
                getAuthCode();
                notify("Authenticating with Github", false, undefined, true);
              }}
              className="inline-flex justify-center items-center px-5"
            >
              <FaGithub size={18} className="mr-2" /> Get Started
            </Button>
            <a
              href={documentationBaseUrl}
              target="_blank"
              className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            >
              <HiOutlineDocumentText size={18} className="mr-2" />
              View Docs
            </a>
          </div>
          <div id="features"></div>
        </div>
      </section>
    </>
  );
};
