import { Paddle, initializePaddle } from "@paddle/paddle-js";
import { Buffer } from "buffer";
import deepmerge from "deepmerge";
import Cookies from "js-cookie";
import {
  type MouseEvent as ReactMouseEvent,
  type TouchEvent as ReactTouchEvent,
} from "react";
import { NavigateFunction } from "react-router-dom";
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnConnectStart,
  OnConnectStartParams,
  OnEdgesChange,
  OnNodesChange,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from "reactflow";
import { z } from "zod";
import { create } from "zustand";
import {
  createJSONStorage,
  devtools,
  persist,
  subscribeWithSelector,
} from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import {
  APIAppConfigSchema,
  APIAppConfigSchemaType,
  APIPriceSchema,
  APIPriceSchemaType,
  APIProjectSchema,
  APIProjectSchemaType,
  APIUserSchema,
  APIUserSchemaType,
  AppSchema,
  AppType,
  FieldSchema,
  FieldType,
  ModelConfig,
  ModelSchema,
  ModelType,
  ProjectConfig,
  ProjectSchema,
  ProjectType,
} from "../fields/field";
import apiClient from "../utils/api";
import {
  Color,
  JWT_AUTH_COOKIE,
  PADDLE_CLIENT_TOKEN,
  apiBaseUrl,
  notify,
} from "../utils/contants";
import {
  AUTH_APP_ID,
  createAuthAppModel,
  getAppColor,
  hasSourceRelationField,
} from "../utils/utils";
import { nanoid } from "nanoid";

export interface INodeData {
  value: ModelType;
  color: Color;
}

export type RFState = {
  nodes: Node<INodeData>[];
  edges: Edge[];
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  onConnectStart: OnConnectStart;
  setNodes: (nodes: Node[]) => void;
  setEdges: (edges: Edge[]) => void;
  connectionNodeId: string | null;
};

interface IUpsertModelFieldToSpecificNode {
  modelId: string;
  modelField: FieldType;
}
interface IModelNode {
  nodeId: string;
  model: ModelType;
}

export type RFStateAction = {
  addModelNode: (args: IModelNode) => void;
  updateModelNode: (args: IModelNode) => void;
  deleteModelNode: (args: IModelNode) => void;
  addNewModelFieldToSpecificNode: (
    args: IUpsertModelFieldToSpecificNode,
  ) => void;
  updateModelFieldOfSpecificNode: (
    args: IUpsertModelFieldToSpecificNode,
  ) => void;
};

// this, is our useStore hook that we can use in our components to get parts of the store and call actions
const useFlowStore = create<RFState & RFStateAction>()(
  immer((set, get) => ({
    nodes: [],
    edges: [],
    connectionNodeId: null,
    onConnectStart: (
      _: ReactMouseEvent | ReactTouchEvent,
      params: OnConnectStartParams,
    ) => {
      useProjectStore
        .getState()
        .setActiveModel(
          get().nodes.find((node) => node.id === params.nodeId)!.data.value,
        );
      set({
        connectionNodeId: params.nodeId,
      });
    },
    onNodesChange: (changes: NodeChange[]) => {
      const nextChanges = changes.reduce((acc, change) => {
        if (change.type === "remove") {
          // prevent node deletion from the flowstore
          return acc;
        }
        return [...acc, change];
      }, [] as NodeChange[]);

      set({
        nodes: applyNodeChanges(nextChanges, get().nodes),
      });
    },
    onEdgesChange: (changes: EdgeChange[]) => {
      const nextChanges = changes.reduce((acc, change) => {
        if (change.type === "remove") {
          // prevent edge deletion from the flowstore
          return acc;
        }
        return [...acc, change];
      }, [] as EdgeChange[]);
      set({
        edges: applyEdgeChanges(nextChanges, get().edges),
      });
    },
    onConnect: (connection: Connection) => {
      useModalStore.getState().setOpenUpsertRelationModelFieldModal(true);
      useProjectStore.getState().setActiveModelConnection({
        source: get().nodes.find((node) => node.id === connection.source)!.data
          .value,
        target: get().nodes.find((node) => node.id === connection.target)!.data
          .value,
      });
    },
    setNodes: (nodes: Node[]) => {
      set({ nodes });
    },
    setEdges: (edges: Edge[]) => {
      set({ edges });
    },
    addModelNode: (args: IModelNode) => {
      const _hasRelationField = hasSourceRelationField(args.model);

      const xAxisAddtion =
        get().nodes.length > 0
          ? get().nodes[get().nodes.length - 1].position.x > 1000
            ? 30
            : get().nodes[get().nodes.length - 1].position.x + 250
          : 30;
      const yAxisAddtion =
        get().nodes.length > 0
          ? get().nodes[get().nodes.length - 1].position.x > 1000
            ? get().nodes[get().nodes.length - 1].position.y + 250
            : get().nodes[get().nodes.length - 1].position.y
          : 30;

      const newNodeData = {
        id: args.nodeId,
        type: "SingleModelNode",
        data: {
          value: args.model,
          color: getAppColor(
            useProjectStore
              .getState()
              .project.apps.findIndex((app) => app.appId == args.model.appId),
          ),
        },
        position: {
          x: xAxisAddtion,

          y: yAxisAddtion,
        },
      };
      set((state) => {
        const existingNodeIndex = state.nodes.findIndex(
          (node) => node.id === args.nodeId,
        );

        if (existingNodeIndex !== -1) {
          const existingNode = state.nodes[existingNodeIndex];
          state.nodes[existingNodeIndex] = {
            ...newNodeData,
            ...{ position: existingNode.position }, // so as to retain previous node position
          };
        } else {
          state.nodes.push({ ...newNodeData });
        }

        if (_hasRelationField != null) {
          let newEdges: Edge[] = [];
          for (let i = 0; i < _hasRelationField.length; i++) {
            const _relationField = _hasRelationField[i];
            newEdges = [
              ...newEdges,
              ...addEdge(
                {
                  source: _relationField.from.modelId,
                  target: _relationField.to.modelId,
                } as Connection,
                state.edges,
              ),
            ];
          }

          const uniqueIds = new Set();
          const uniqueNewEdges = newEdges.filter((obj) => {
            if (!uniqueIds.has(obj.id)) {
              uniqueIds.add(obj.id);
              return true;
            }
            return false;
          });
          state.edges = uniqueNewEdges;
        }
      });
    },
    addNewModelFieldToSpecificNode: (args: IUpsertModelFieldToSpecificNode) => {
      set((state) => {
        const index = state.nodes.findIndex(
          (node) => node.data.value.modelId === args.modelId,
        );
        if (index !== -1)
          state.nodes[index].data.value.fields.push(args.modelField);
      });
    },
    updateModelNode: (args: IModelNode) => {
      set((state) => {
        const index = state.nodes.findIndex(
          (node) => node.data.value.modelId === args.model.modelId,
        );
        if (index !== -1) state.nodes[index].data.value = args.model;
      });
    },
    deleteModelNode: (args: IModelNode) => {
      set((state) => {
        const index = state.nodes.findIndex(
          (node) => node.data.value.modelId === args.model.modelId,
        );
        if (index !== -1) state.nodes.splice(index, 1);
      });
    },
    updateModelFieldOfSpecificNode: (args: IUpsertModelFieldToSpecificNode) => {
      set((state) => {
        const nodeIndex = state.nodes.findIndex(
          (node) => node.data.value.modelId === args.modelId,
        );

        if (nodeIndex !== -1) {
          const fieldIndex = state.nodes[nodeIndex].data.value.fields.findIndex(
            (field) => field.fieldId === args.modelField.fieldId,
          );

          if (fieldIndex !== -1) {
            const model = state.nodes[nodeIndex].data.value;
            model.fields[fieldIndex] = args.modelField;

            state.nodes[nodeIndex].data.value = model;
          }
        }
      });
    },
  })),
);

interface IUpsertModel {
  model: ModelType;
  action: Action;
}

interface IUpsertModelField {
  field: FieldType;
  action: Action;
}

interface AllowFieldNameProp {
  fieldName: string;
  model: ModelType;
}

interface IAllowModelName {
  modelName: string;
  app: AppType;
}

interface IAllowModelRelationship {
  model: ModelType;
  app: AppType;
}

interface ModelConnection {
  source: ModelType;
  target: ModelType;
}

export enum Action {
  ADD,
  EDIT,
  DELETE,
}

interface IUpsertApp {
  app: AppType;
  action: Action;
}

interface IUpsertProject {
  project: object;
  action: Action;
}

export type ProjectAction = {
  upsertProject: (args: IUpsertProject) => void;
  upsertApp: (args: IUpsertApp) => void;
  upsertModel: (args: IUpsertModel) => void;
  upsertModelField: (args: IUpsertModelField) => void;
  allowFieldName: (args: AllowFieldNameProp) => boolean;
  allowModelName: (args: IAllowModelName) => boolean;
  allowModelRelationship: (args: IAllowModelRelationship) => boolean;
  getModelConnectionReference: (
    modelConnection: ModelConnection,
  ) => [string | null, string | null];
};

export type ProjectState = {
  project: ProjectType;
  activeApp: AppType | null;
  activeModel: ModelType | null;
  activeModelField: FieldType | null;
  activeModelConnection: ModelConnection | null;
  hasUnSavedChanges: boolean;
  isEditMode: boolean;
  hasEmptyModel: boolean;
  hasEmptyApp: boolean;
  setHasUnSavedChanges: (hasUnSavedChanges: boolean) => void;
  setIsEditMode: (isEditMode: boolean) => void;
  setHasEmptyModel: (hasEmptyModel: boolean) => void;
  setHasEmptyApp: (hasEmptyApp: boolean) => void;
  setProject: (project: ProjectType) => void;
  setActiveApp: (app: AppType | null) => void;
  setActiveModel: (model: ModelType | null) => void;
  setActiveModelField: (field: FieldType | null) => void;
  setActiveModelConnection: (connection: ModelConnection | null) => void;
} & ProjectAction;

const useProjectStore = create<ProjectState>()(
  subscribeWithSelector(
    devtools(
      immer(
        persist(
          (set, get) => ({
            project: ProjectSchema.parse({}),
            activeApp: null,
            activeModel: null,
            activeModelField: null,
            activeModelConnection: null,
            hasUnSavedChanges: false,
            isEditMode: false,
            hasEmptyModel: true,
            hasEmptyApp: true,
            setProject: (project: ProjectType) => {
              const newAuthModel = createAuthAppModel();
              // We check that if we don't already have AUTH_MODEL in the apps
              if (
                project.apps.length != 0 &&
                project.apps.findIndex(
                  (_app) => _app.appId == newAuthModel.appId,
                ) === -1
              ) {
                project.apps.unshift(newAuthModel);
              }
              set((state) => {
                state.project = project;
              });
            },
            setActiveApp: (app: AppType | null) => {
              set((state) => {
                if (app) {
                  if (
                    state.project.apps.findIndex(
                      (_app) => _app.appId == app.appId,
                    ) !== -1
                  ) {
                    state.activeApp = app;
                  }
                } else {
                  state.activeApp = null;
                }
              });
            },
            setActiveModel: (model: ModelType | null) => {
              set((state) => {
                if (model) {
                  const appIndex = state.project.apps.findIndex(
                    (app) => app.appId === model.appId,
                  );

                  if (appIndex !== -1) {
                    const modelIndex = state.project.apps[
                      appIndex
                    ].models.findIndex(
                      (_model) => _model.modelId === model.modelId,
                    );

                    if (modelIndex !== -1) {
                      state.activeModel = model;
                    }
                  }
                } else {
                  state.activeModel = null;
                }
              });
            },
            setActiveModelConnection: (connection: ModelConnection | null) => {
              function checkModel(model: ModelType) {
                if (model) {
                  const appIndex = get().project.apps.findIndex(
                    (app) => app.appId === model.appId,
                  );

                  if (appIndex !== -1) {
                    const modelIndex = get().project.apps[
                      appIndex
                    ].models.findIndex(
                      (_model) => _model.modelId === model.modelId,
                    );

                    if (modelIndex !== -1) {
                      return model;
                    }
                  }
                }
                return null;
              }
              set((state) => {
                if (connection) {
                  const sourceModel = checkModel(connection.source);
                  const targetModel = checkModel(connection.target);
                  if (sourceModel && targetModel) {
                    state.activeModelConnection = {
                      source: sourceModel,
                      target: targetModel,
                    };
                  } else {
                    state.activeModelConnection = null;
                  }
                } else {
                  state.activeModelConnection = null;
                }
              });
            },
            setActiveModelField: (field: FieldType | null) => {
              set((state) => {
                if (field) {
                  const appIndex = state.project.apps.findIndex(
                    (app) => app.appId === field.appId,
                  );
                  if (appIndex !== -1) {
                    const modelIndex = state.project.apps[
                      appIndex
                    ].models.findIndex(
                      (model) => model.modelId === field.modelId,
                    );
                    if (modelIndex !== -1) {
                      const fieldIndex = state.project.apps[appIndex].models[
                        modelIndex
                      ].fields.findIndex(
                        (_field) => _field.fieldId === field.fieldId,
                      );
                      if (fieldIndex !== -1) {
                        state.activeModelField =
                          state.project.apps[appIndex].models[
                            modelIndex
                          ].fields[fieldIndex];
                      }
                    }
                  }
                } else {
                  state.activeModelField = null;
                }
              });
            },
            upsertProject: (args: IUpsertProject) => {
              set((state) => {
                state.project = ProjectSchema.parse({
                  ...state.project,
                  ...args.project,
                });
              });
            },
            upsertApp: (args: IUpsertApp) => {
              set((state) => {
                if (args.action == Action.ADD) {
                  state.project.apps.push(
                    AppSchema.parse({
                      ...args.app,
                    }),
                  );
                  notify("App successfully added");
                } else if (args.action == Action.EDIT) {
                  const appIndex = state.project.apps.findIndex(
                    (app) => app.appId == args.app.appId,
                  );
                  if (appIndex !== -1) {
                    state.project.apps[appIndex] = AppSchema.parse({
                      ...args.app,
                    });
                    notify("App successfully updated");
                  }
                } else if (args.action == Action.DELETE) {
                  const appIndex = state.project.apps.findIndex(
                    (app) => app.appId == args.app.appId,
                  );
                  if (appIndex !== -1) {
                    const _hasModels =
                      state.project.apps[appIndex].models.length > 0;

                    if (_hasModels) {
                      notify(
                        "App with models cannot be deleted. \n Remove models then delete.",
                        true,
                      );
                    } else {
                      state.project.apps.splice(appIndex, 1);
                      notify("App successfully deleted");
                    }
                  }
                }
              });
            },
            upsertModel: (args: IUpsertModel) => {
              set((state) => {
                if (args.action == Action.ADD) {
                  const appIndex = state.project.apps.findIndex(
                    (app) => app.appId === args.model.appId,
                  );
                  if (appIndex !== -1) {
                    //
                    state.project.apps[appIndex].models.push(
                      ModelSchema.parse({
                        ...args.model,
                      }),
                    );
                    // useFlowStore.getState().addModelNode({ nodeId: args.model.modelId, model: args.model });
                  }
                } else if (args.action == Action.EDIT) {
                  const appIndex = state.project.apps.findIndex(
                    (app) => app.appId === args.model.appId,
                  );
                  if (appIndex !== -1) {
                    const modelIndex = state.project.apps[
                      appIndex
                    ].models.findIndex(
                      (model) => model.modelId === args.model.modelId,
                    );
                    if (modelIndex !== -1) {
                      //
                      state.project.apps[appIndex].models[modelIndex] =
                        ModelSchema.parse({
                          ...args.model,
                        });

                      // useFlowStore.getState().updateModelNode({ nodeId: args.model.modelId, model: args.model });
                    }
                  }
                } else if (args.action == Action.DELETE) {
                  const appIndex = state.project.apps.findIndex(
                    (app) => app.appId === args.model.appId,
                  );
                  if (appIndex !== -1) {
                    const modelIndex = state.project.apps[
                      appIndex
                    ].models.findIndex(
                      (model) => model.modelId === args.model.modelId,
                    );
                    if (modelIndex !== -1) {
                      const _hasRelationConnection = useFlowStore
                        .getState()
                        .edges.find(
                          (edge) =>
                            edge.source == args.model.modelId ||
                            edge.target == args.model.modelId,
                        );

                      if (_hasRelationConnection) {
                        notify(
                          "Model with relation connection cannot be deleted. \n Remove relation fields then delete.",
                          true,
                        );
                      } else {
                        state.project.apps[appIndex].models.splice(
                          modelIndex,
                          1,
                        );
                        useFlowStore.getState().deleteModelNode({
                          nodeId: args.model.modelId,
                          model: args.model,
                        });
                        notify("Model deleted");
                      }

                      // useFlowStore.getState().updateModelNode({ nodeId: args.model.modelId, model: args.model });
                    }
                  }
                }
              });
            },
            upsertModelField: (args: IUpsertModelField) => {
              set((state) => {
                if (args.action == Action.ADD) {
                  const appIndex = state.project.apps.findIndex(
                    (app) => app.appId === args.field.appId,
                  );
                  if (appIndex !== -1) {
                    const modelIndex = state.project.apps[
                      appIndex
                    ].models.findIndex(
                      (model) => model.modelId === args.field.modelId,
                    );

                    if (modelIndex !== -1) {
                      const _tempFieldList = [
                        ...state.project.apps[appIndex].models[modelIndex]
                          .fields,
                        args.field,
                      ];
                      const fieldWithPrimaryKey = _tempFieldList.filter(
                        (e) => e.field.primary_key == true,
                      );
                      if (fieldWithPrimaryKey.length >= 2) {
                        notify(
                          "Model can only contain one primary key field",
                          true,
                        );
                        return;
                      }

                      state.project.apps[appIndex].models[
                        modelIndex
                      ].fields.push(FieldSchema.parse({ ...args.field }));
                      notify("Field successfully added", false);
                      // useFlowStore.getState().addNewModelFieldToSpecificNode({ modelId: args.field.modelId, modelField: args.field });
                    }
                  }
                } else if (args.action == Action.EDIT) {
                  const appIndex = state.project.apps.findIndex(
                    (app) => app.appId === args.field.appId,
                  );
                  if (appIndex !== -1) {
                    const modelIndex = state.project.apps[
                      appIndex
                    ].models.findIndex(
                      (model) => model.modelId === args.field.modelId,
                    );

                    if (modelIndex !== -1) {
                      const fieldIndex = state.project.apps[appIndex].models[
                        modelIndex
                      ].fields.findIndex(
                        (field) => field.fieldId === args.field.fieldId,
                      );
                      if (fieldIndex !== -1) {
                        const _tempFieldList = [
                          ...state.project.apps[appIndex].models[modelIndex]
                            .fields,
                        ];
                        const fieldWithPrimaryKey = _tempFieldList.filter(
                          (e) =>
                            e.field.primary_key == true &&
                            e.fieldId != args.field.fieldId,
                        );
                        if (
                          fieldWithPrimaryKey.length >= 1 &&
                          args.field.field.primary_key
                        ) {
                          notify(
                            "Model can only contain one primary key field",
                            true,
                          );
                          return;
                        }

                        state.project.apps[appIndex].models[modelIndex].fields[
                          fieldIndex
                        ] = FieldSchema.parse({ ...args.field });

                        // useFlowStore.getState().updateModelFieldOfSpecificNode({ modelId: args.field.modelId, modelField: args.field })
                        state.activeModelField = args.field;

                        notify("Property successfully updated.");
                      }
                    }
                  }
                } else if (args.action == Action.DELETE) {
                  const appIndex = state.project.apps.findIndex(
                    (app) => app.appId === args.field.appId,
                  );
                  if (appIndex !== -1) {
                    const modelIndex = state.project.apps[
                      appIndex
                    ].models.findIndex(
                      (model) => model.modelId === args.field.modelId,
                    );

                    if (modelIndex !== -1) {
                      const fieldIndex = state.project.apps[appIndex].models[
                        modelIndex
                      ].fields.findIndex(
                        (field) => field.fieldId === args.field.fieldId,
                      );
                      if (fieldIndex !== -1) {
                        useFlowStore.getState().setEdges([]);
                        state.project.apps[appIndex].models[
                          modelIndex
                        ].fields.splice(fieldIndex, 1);

                        // useFlowStore.getState().updateModelFieldOfSpecificNode({ modelId: args.field.modelId, modelField: args.field })
                        state.activeModelField = null;
                      }
                    }
                  }
                }
              });
            },
            allowFieldName: (args: AllowFieldNameProp) => {
              const state = get();
              const appIndex = state.project.apps.findIndex(
                (app) => app.appId === args.model.appId,
              );
              if (appIndex !== -1) {
                const modelIndex = state.project.apps[
                  appIndex
                ].models.findIndex(
                  (model) => model.modelId === args.model.modelId,
                );
                const foundField = state.project.apps[appIndex].models[
                  modelIndex
                ].fields.findIndex((field) => field.name === args.fieldName);

                return foundField === -1 ? true : false;
              }
              return false;
            },
            allowModelRelationship: (args: IAllowModelRelationship) => {
              const state = get();
              const appIndex = state.project.apps.findIndex(
                (app) => app.appId === args.model.appId,
              );
              if (appIndex !== -1) {
                const modelIndex = state.project.apps[
                  appIndex
                ].models.findIndex(
                  (model) => model.modelId === args.model.modelId,
                );

                return modelIndex === -1 ? true : false;
              }
              return false;
            },
            allowModelName: (args: IAllowModelName) => {
              const state = get();
              const appIndex = state.project.apps.findIndex(
                (app) => app.appId === args.app.appId,
              );
              if (appIndex !== -1) {
                const foundModel = state.project.apps[
                  appIndex
                ].models.findIndex((model) => model.name === args.modelName);

                return foundModel === -1 ? true : false;
              }
              return false;
            },
            getModelConnectionReference: (modelConnection: ModelConnection) => {
              const state = get();
              const sourceApp = state.project.apps.find(
                (app) => app.appId === modelConnection.source.appId,
              );
              const targetApp = state.project.apps.find(
                (app) => app.appId === modelConnection.target.appId,
              );
              if (sourceApp != null && targetApp != null) {
                return [
                  `${sourceApp.name}.${modelConnection.source.name}`,
                  `${targetApp.name}.${modelConnection.target.name}`,
                ];
              }
              return [null, null];
            },
            setHasUnSavedChanges: (_hasUnSavedChanges: boolean) => {
              set((state) => {
                state.hasUnSavedChanges = _hasUnSavedChanges;
              });
            },
            setIsEditMode: (_isEditMode: boolean) => {
              set((state) => {
                state.isEditMode = _isEditMode;
              });
            },
            setHasEmptyModel: (_hasEmptyModel: boolean) => {
              set((state) => {
                state.hasEmptyModel = _hasEmptyModel;
              });
            },
            setHasEmptyApp: (_hasEmptyApp: boolean) => {
              set((state) => {
                state.hasEmptyApp = _hasEmptyApp;
              });
            },
          }),
          {
            name: "django-compose-storage", // name of item in the storage (must be unique).
            storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used.
            partialize: (state) => ({
              project: state.project,
              isEditMode: state.isEditMode,
            }),
            merge: (persistedState, currentState) =>
              deepmerge(currentState, persistedState ?? {}),
            // skipHydration: true, // the initial call for hydration isn't called.
            onRehydrateStorage: () => (state) => {
              // state?.setHasHydrated(true)

              state?.setHasUnSavedChanges(false);

              // return (state, error) => { //eslint-disable-line  @typescript-eslint/no-explicit-any
              //   if (error) {
              //
              //   } else {
              //
              //     state.setHasUnSavedChanges(false);
              //   }
              // }
            },
          },
        ),
      ),
    ),
  ),
);

useProjectStore.subscribe(
  (state) => state.project,
  (project) => {
    let _hasEmptyModel = false;
    let _hasEmptyApp = false;
    project.apps.forEach((app) => {
      if (app.models.length == 0) {
        _hasEmptyApp = true;
      }
      app.models.forEach((model) => {
        if (model.fields.length == 0 && app.appId != AUTH_APP_ID) {
          _hasEmptyModel = true;
        }
        useFlowStore
          .getState()
          .addModelNode({ nodeId: model.modelId, model: model });
      });
    });

    useProjectStore.getState().setHasUnSavedChanges(true);
    useProjectStore.getState().setHasEmptyApp(_hasEmptyApp);
    useProjectStore.getState().setHasEmptyModel(_hasEmptyModel);
  },
  {
    fireImmediately: true,
  },
);

interface IComfirmBox {
  title?: string;
  description?: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}
export interface IConfigDrawer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configSchema: z.ZodObject<any> | z.ZodEffects<any>;
  // configSchema: z.ZodTypeAny;
  type: "PROJECT" | "MODEL";
  config: ProjectConfig | ModelConfig;
  open: boolean;
}

export type ModalState = {
  openTemplateModal: boolean;
  setOpenTemplateModal: (open: boolean) => void;
  openUpsertAppModal: boolean;
  setOpenUpsertAppModal: (open: boolean) => void;
  openUpsertProjectModal: boolean;
  setOpenUpsertProjectModal: (open: boolean) => void;
  openUpsertModelModal: boolean;
  setOpenUpsertModelModal: (open: boolean) => void;
  openUpsertModelFieldModal: boolean;
  setOpenUpsertModelFieldModal: (open: boolean) => void;
  openConfigDrawer: IConfigDrawer | null;
  setOpenConfigDrawer: (option: IConfigDrawer | null) => void;
  openUpsertRelationModelFieldModal: boolean;
  setOpenUpsertRelationModelFieldModal: (open: boolean) => void;
  openConfirmBox: IComfirmBox | null;
  setOpenConfirmBox: (option: IComfirmBox | null) => void;
};

const useModalStore = create<ModalState>()(
  devtools(
    immer((set) => ({
      openTemplateModal: false,
      openUpsertAppModal: false,
      openUpsertProjectModal: false,
      openUpsertModelModal: false,
      openUpsertModelFieldModal: false,
      openUpsertRelationModelFieldModal: false,
      openConfigDrawer: null,
      openConfirmBox: null,
      setOpenUpsertAppModal: (open: boolean) => {
        set((state) => {
          state.openUpsertAppModal = open;
        });
      },
      setOpenTemplateModal: (open: boolean) => {
        set((state) => {
          state.openTemplateModal = open;
        });
      },
      setOpenUpsertProjectModal: (open: boolean) => {
        set((state) => {
          state.openUpsertProjectModal = open;
        });
      },
      setOpenUpsertModelModal: (open: boolean) => {
        set((state) => {
          state.openUpsertModelModal = open;
        });
      },
      setOpenUpsertModelFieldModal: (open: boolean) => {
        set((state) => {
          state.openUpsertModelFieldModal = open;
        });
      },
      setOpenConfigDrawer: (option: IConfigDrawer | null) => {
        set((state) => {
          state.openConfigDrawer = option;
        });
      },
      setOpenUpsertRelationModelFieldModal: (open: boolean) => {
        set((state) => {
          state.openUpsertRelationModelFieldModal = open;
        });
      },
      setOpenConfirmBox: (option: IComfirmBox | null) => {
        set((state) => {
          state.openConfirmBox = option;
        });
      },
    })),
  ),
);

export type AuthenticationState = {
  loginInProgress: boolean;
  logoutInProgress: boolean;
  user: APIUserSchemaType | null;
};

export interface IGetAPIAccessToken {
  code: string;
  stateString: string;
}

export type AuthenticationStateAction = {
  getAPIAccessToken: (args: IGetAPIAccessToken) => Promise<boolean>;
  getAuthCode: () => void;
  // setUser: (_user: unknown) => void;
  getAPIUser: (reload?: boolean) => Promise<boolean>;
  initiateLogout: () => Promise<boolean>;
};

const useAuthenticationStore = create<
  AuthenticationState & AuthenticationStateAction
>()(
  // persist(
  (set) => ({
    loginInProgress: false,
    logoutInProgress: false,
    user: null,
    setUser: (_user: unknown) => {
      if (_user) {
        set({
          user: APIUserSchema.parse(_user),
        });
      }
    },
    getAPIUser: (reload?: boolean) => {
      return (
        apiClient
          .get(
            `${apiBaseUrl}/api/auth/user/${reload ? `?reload=${nanoid()}` : ""}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          )
          .then(function (response) {
            if (response.status == 200) {
              set({
                user: APIUserSchema.parse(response.data),
              });
              return true;
            }
            return false;
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .catch(function () {
            // console.log(error, "errror");
            set({
              user: null,
            });
            Cookies.remove(JWT_AUTH_COOKIE);
            return false;
          })
      );
    },
    getAuthCode: () => {
      window.location.href = `${apiBaseUrl}/api/auth/github/authorize/`;
    },
    getAPIAccessToken: (args: IGetAPIAccessToken) => {
      set({
        loginInProgress: true,
      });
      Cookies.remove(JWT_AUTH_COOKIE);
      return (
        apiClient
          .post(
            `${apiBaseUrl}/api/auth/github/login/`,
            JSON.stringify({
              state: args.stateString,
              code: args.code,
            }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          )
          .then(function (response) {
            const response_data = response.data;

            if (response_data.error) {
              notify(response_data.error.error_description, true);
              set({
                loginInProgress: false,
              });
              return false;
            } else {
              const data = response_data;

              set({
                loginInProgress: false,
                user: APIUserSchema.parse(data.user),
              });
              Cookies.set(JWT_AUTH_COOKIE, data.access);
              return true;
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .catch(function () {
            // console.log(error, "errror");
            set({
              loginInProgress: false,
            });
            return false;
          })
      );
    },
    initiateLogout: () => {
      set({
        logoutInProgress: true,
      });
      return (
        apiClient
          .post(`${apiBaseUrl}/api/auth/logout/`, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(function (response) {
            if (response.status == 200) {
              set({
                logoutInProgress: false,
                user: null,
              });
              Cookies.remove(JWT_AUTH_COOKIE);
              return true;
            }
            return false;
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .catch(function () {
            // console.log(error, "errror");
            set({
              logoutInProgress: false,
            });
            return false;
          })
      );
    },
  }),
  // {
  // name: "django_compose_auth", // name of item in the storage (must be unique).
  // storage: createJSONStorage(() => localStorage), // (optional) by default the 'localStorage' is used.
  // partialize: (state) => ({
  //   access_token: state.access_token,
  // }),
  // merge: (persistedState, currentState) =>
  //   deepmerge(currentState, persistedState ?? {}),
  // },
  // ),
);

export type CodeGenerationState = {
  in_progress: boolean;
  daily_count: number;
};

export type CodeGenerationStateAction = {
  generateCode: () => Promise<string>;
};

const useCodeGenerationStore = create<
  CodeGenerationState & CodeGenerationStateAction
>()((set, get) => ({
  in_progress: false,
  daily_count: 5,
  generateCode: () => {
    set({
      in_progress: true,
    });
    return apiClient
      .post(
        `${apiBaseUrl}/api/generator/projects/`,
        {
          name: useProjectStore.getState().project.name,
          code_spec: Buffer.from(
            JSON.stringify(
              ProjectSchema.parse(useProjectStore.getState().project),
              undefined,
              2,
            ),
          ).toString("base64"),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(function (response) {
        set({
          in_progress: false,
          daily_count: get().daily_count - 1,
        });
        return response.data.download_url;
      })
      .catch(function (error) {
        set({
          in_progress: false,
        });

        if (Array.isArray(error.response.data)) {
          error.response.data.map((err: string) => notify(err, true));
        }

        return `Error generating code`;
      });
  },
}));

export type ProfileState = {
  projects: APIProjectSchemaType[];
  prices: APIPriceSchemaType[] | null;
  appConfig: APIAppConfigSchemaType | null;
  paddleInstance: Paddle | undefined;
  in_progress: boolean;
};

export type ProfileStateAction = {
  fetchProjectList: () => Promise<string>;
  customInitializePaddle: (navigate: NavigateFunction) => Promise<void>;
  fetchPriceList: (customerIpAddress?: string) => Promise<string>;
  fetchAppConfig: () => Promise<boolean>;
};

const useProfileStore = create<ProfileState & ProfileStateAction>()((set) => ({
  projects: [],
  prices: null,
  appConfig: null,
  in_progress: false,
  paddleInstance: undefined,
  fetchProjectList: () => {
    set({
      in_progress: true,
    });
    return (
      apiClient
        .get(`${apiBaseUrl}/api/generator/projects/`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          set({
            in_progress: false,
            projects: response.data.map((project: unknown) =>
              APIProjectSchema.parse(project),
            ),
          });
          return response.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(function () {
          set({
            in_progress: false,
          });
          notify("Error loading profile", true);
        })
    );
  },
  fetchPriceList: (customerIpAddress?: string) => {
    set({
      in_progress: true,
    });
    return (
      apiClient
        .get(`${apiBaseUrl}/api/paddle/prices/`, {
          params: customerIpAddress
            ? {
                customer_ip_address: customerIpAddress,
              }
            : {},
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          set({
            in_progress: false,
            prices: response.data.map((price: unknown) =>
              APIPriceSchema.parse(price),
            ),
          });
          return response.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(function () {
          set({
            in_progress: false,
          });
          // notify("Error loading price", true);
        })
    );
  },
  fetchAppConfig: () => {
    set({
      in_progress: true,
    });
    return (
      apiClient
        .get(`${apiBaseUrl}/api/app/config/`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          set({
            in_progress: false,
            appConfig: APIAppConfigSchema.parse(response.data),
          });
          return true;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(function () {
          set({
            in_progress: false,
          });
          return false;
          // notify("Error loading price", true);
        })
    );
  },
  customInitializePaddle: async (navigate) => {
    const user = useAuthenticationStore.getState().user;

    const _paddleInstance = await initializePaddle({
      environment:
        import.meta.env.MODE == "production" ? "production" : "sandbox",
      token: PADDLE_CLIENT_TOKEN,
      pwCustomer:
        user == null
          ? {}
          : {
              email: user.email,
            },
      eventCallback(event) {
        if (event.name == "checkout.completed") {
          // console.log(event);
          navigate("/thankyou");
        }
      },
    });
    if (_paddleInstance) {
      // console.log("paddle instance initialized");

      set({
        paddleInstance: _paddleInstance,
      });
    }
  },
}));

export {
  useAuthenticationStore,
  useCodeGenerationStore,
  useFlowStore,
  useModalStore,
  useProfileStore,
  useProjectStore,
};
