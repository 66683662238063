import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Label,
  ListGroup,
  Modal,
  TextInput,
  Tooltip,
  useThemeMode,
} from "flowbite-react";
import { nanoid } from "nanoid";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { HiPlus } from "react-icons/hi";
import { MdOutlineDelete, MdSettingsSuggest, MdVpnKey } from "react-icons/md";
import { TbLetterN } from "react-icons/tb";
import { Handle, NodeProps, Position } from "reactflow";
import { z } from "zod";
import {
  FieldSchema,
  FieldToSchemaMap,
  FieldType,
  ModelConfigSchema,
  ModelSchema,
  ModelType,
  RelationFieldToSchemaMap,
  SingleFieldToSchemaMap,
} from "../../fields/field";
import {
  Action,
  INodeData,
  useModalStore,
  useProjectStore,
} from "../../model/store";
import {
  RELATIONSHIP_FIELDS,
  baseDarkColor,
  errorTextClass,
} from "../../utils/contants";
import {
  AUTH_APP_MODEL_ID,
  getSingleFieldToSchemaMap,
} from "../../utils/utils";

function SingleModelNode({ data }: NodeProps<INodeData>) {
  const setActiveModel = useProjectStore((state) => state.setActiveModel);
  const setActiveModelField = useProjectStore(
    (state) => state.setActiveModelField,
  );
  const themeMode = useThemeMode();
  const setOpenConfirmBox = useModalStore((state) => state.setOpenConfirmBox);

  const setOpenUpsertModelFieldModal = useModalStore(
    (state) => state.setOpenUpsertModelFieldModal,
  );

  const upsertModel = useProjectStore((state) => state.upsertModel);

  const setOpenConfigDrawer = useModalStore(
    (state) => state.setOpenConfigDrawer,
  );

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="targetHandle"
        style={{
          height: 8,
          width: 8,
        }}
      />
      <div className="flex justify-center">
        <ListGroup className="max-w-80 min-w-60 bg-slate-50  text-gray-900">
          <ListGroup.Item
            style={{
              backgroundColor:
                themeMode.mode == "dark" ? data.color.dark : data.color.light,
              color: "white",
            }}
            theme={{
              link: {
                base: "flex w-full text-sm items-center border-b border-gray-200 px-4 py-1 dark:border-gray-600",
              },
            }}
          >
            <div className="w-full flex justify-between gap-4 items-center">
              <p className="truncate">{data.value.name}</p>

              <div className="flex items-center gap-2">
                <Tooltip content="Add Field" placement="top" className="w-24">
                  <HiPlus
                    size={18}
                    onClick={() => {
                      setActiveModel(data.value);
                      setOpenUpsertModelFieldModal(true);
                    }}
                  />
                </Tooltip>
                {data.value.modelId != AUTH_APP_MODEL_ID && (
                  <Tooltip content="Config" placement="top" className="w-24">
                    <MdSettingsSuggest
                      size={18}
                      onClick={() => {
                        setActiveModel(data.value);
                        setOpenConfigDrawer({
                          open: true,
                          type: "MODEL",
                          configSchema: ModelConfigSchema,
                          config:
                            data.value.config ?? ModelConfigSchema.parse({}),
                        });
                      }}
                    />
                  </Tooltip>
                )}
                {data.value.modelId != AUTH_APP_MODEL_ID && (
                  <MdOutlineDelete
                    size={18}
                    onClick={() => {
                      setOpenConfirmBox({
                        open: true,
                        title: "Delete Model",
                        onConfirm: () => {
                          upsertModel({
                            model: ModelSchema.parse({
                              ...data.value,
                            }),
                            action: Action.DELETE,
                          });
                        },
                        onCancel: () => {},
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </ListGroup.Item>
          {data.value.fields.length === 0 ? (
            <ListGroup.Item>
              {data.value.modelId != AUTH_APP_MODEL_ID ? "No Fields" : "-"}{" "}
            </ListGroup.Item>
          ) : (
            data.value.fields.map((field) => (
              <FieldComponent
                key={field.fieldId}
                field={field}
                onClick={() => {
                  setActiveModel(data.value);
                  setActiveModelField(field);
                }}
              />
            ))
          )}
        </ListGroup>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="sourceHandle"
        style={{
          height: 8,
          width: 8,
          backgroundColor:
            themeMode.mode == "dark" ? data.color.dark : data.color.light,
        }}
      />
    </>
  );
}

interface IFieldComponent {
  field: FieldType;
  onClick: () => void;
}

const FieldComponent: React.FC<IFieldComponent> = (props: IFieldComponent) => {
  return (
    <ListGroup.Item
      onClick={props.onClick}
      data-drawer-target="drawer-navigation"
      data-drawer-placement="right"
      aria-controls="drawer-navigation"
      data-drawer-show="drawer-navigation"
      data-drawer-backdrop="true"
      theme={{
        link: {
          base: "flex w-full items-center border-b border-gray-200 px-4 py-1 dark:border-gray-600",
        },
      }}
    >
      <div className="w-full flex items-center">
        <div className="flex-none w-4">
          {Object.keys(RELATIONSHIP_FIELDS).includes(props.field.field.type) ? (
            <MdVpnKey size={14} color={baseDarkColor} />
          ) : (
            <TbLetterN size={14} color={baseDarkColor} />
          )}
        </div>
        <div className="mx-1 mr-2 truncate">
          <p className="text-sm">{props.field.name}</p>
        </div>
        <div className="shrink truncate" style={{ marginLeft: "auto" }}>
          <i className="text-xs">
            {getSingleFieldToSchemaMap(props.field.field.type)?.column_type}
          </i>
        </div>
      </div>
    </ListGroup.Item>
  );
};

interface IFieldListSelect {
  // selectedField: SingleFieldToSchemaMap | null;
  activeModelField: FieldType | null;
  setSelectedField: Dispatch<SetStateAction<SingleFieldToSchemaMap | null>>;
  fieldListMap: typeof RelationFieldToSchemaMap | typeof FieldToSchemaMap;
}
const FieldListSelect: React.FC<IFieldListSelect> = (
  props: IFieldListSelect,
) => {
  const [isActive, setIsActive] = useState<number | null>(null);

  const fields = props.fieldListMap.map((field, index) => {
    return (
      <div
        key={index}
        onClick={() => {
          setIsActive(index);
          props.setSelectedField(field);
        }}
        className={
          "p-3 cursor-pointer font-bold text-gray-900 rounded-lg bg-gray-200 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white " +
          (isActive == index ||
          (isActive === null &&
            props.activeModelField &&
            props.activeModelField.field.type == field.type)
            ? "text-white bg-green-500 dark:bg-green-600 dark:hover:bg-green-500 hover:bg-green-400"
            : "hover:bg-gray-100")
        }
      >
        <p className="text-xs lg:text-sm truncate">{field.type}</p>
      </div>
    );
  });

  return <div className="grid grid-cols-3 gap-3">{fields}</div>;
};

const FieldSelectModal: React.FC = () => {
  const [actionSuccessful, setActionSuccessful] = useState(false);
  const [selectedField, setSelectedField] =
    useState<SingleFieldToSchemaMap | null>(null);
  const upsertModelField = useProjectStore((state) => state.upsertModelField);
  const allowFieldName = useProjectStore((state) => state.allowFieldName);

  const activeModel: ModelType = useProjectStore((state) => state.activeModel)!;
  const activeModelField = useProjectStore((state) => state.activeModelField)!;

  const setActiveModelField = useProjectStore(
    (state) => state.setActiveModelField,
  );

  const [openUpsertModelFieldModal, setOpenUpsertModelFieldModal] =
    useModalStore((state) => [
      state.openUpsertModelFieldModal,
      state.setOpenUpsertModelFieldModal,
    ]);

  const FieldSchemaForm = FieldSchema.pick({ name: true, field: true });
  type FieldSchemaFormType = z.infer<typeof FieldSchemaForm>;

  const onSubmit: SubmitHandler<FieldSchemaFormType> = (data) => {
    if (selectedField !== null) {
      if (activeModelField === null) {
        //

        const newField = FieldSchema.parse({
          fieldId: nanoid(),
          modelId: activeModel.modelId,
          appId: activeModel.appId,
          field: data.field,
          name: data.name,
        });

        upsertModelField({
          field: newField,
          action: Action.ADD,
        });
        setActionSuccessful(true);
      } else {
        const updatedModelField = FieldSchema.parse({
          ...activeModelField,
          ...{ name: data.name, field: data.field },
        });
        upsertModelField({
          field: updatedModelField,
          action: Action.EDIT,
        });
        setActionSuccessful(true);
        setActiveModelField(updatedModelField);
      }
      setSelectedField(null);
      reset();
      resetField("name");
      setOpenUpsertModelFieldModal(false);
    }
  };

  const {
    register,
    setValue,
    resetField,
    reset,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<FieldSchemaFormType>({
    resolver: zodResolver(
      FieldSchemaForm.superRefine((entries, ctx) => {
        if (
          activeModelField == null &&
          !allowFieldName({ fieldName: entries.name, model: activeModel })
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Name Already Exist, No duplicates allowed.",
            path: ["name"],
          });
        }
      }),
      // .default(
      //   FieldSchemaForm.parse(
      //     activeModelField === null
      //       ? {}
      //       : {
      //           name: activeModelField.name,
      //           field: FieldToSchemaMap.find((field) => field.type === activeModelField.field.type)?.field,
      //         }
      //   )
      // )
    ),
  });

  useEffect(() => {
    if (selectedField != null) {
      setValue("field", selectedField.field.schema.parse({}));
      clearErrors("field");
    }
  }, [selectedField, setValue, clearErrors]);

  useEffect(() => {
    reset();
    resetField("name");
  }, [resetField, reset, actionSuccessful]);

  useEffect(() => {
    if (activeModelField != null) {
      const activeModelFieldSelector = FieldToSchemaMap.find(
        (field) => field.type === activeModelField.field.type,
      );

      if (activeModelFieldSelector) {
        setSelectedField(activeModelFieldSelector);
        setValue("name", activeModelField.name);
      }
    }
  }, [activeModelField, setValue]);

  // useEffect(() => {
  //   if (activeModelField !== null) {
  //     setValue("name", activeModelField.name);
  //     setValue("field", activeModelField.field);
  //   } else {
  //     resetField("name")
  //   }
  // }, [activeModelField, setValue, resetField]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal
          show={openUpsertModelFieldModal}
          onClose={() => {
            setOpenUpsertModelFieldModal(false);
            // setActiveModelField(null)
          }}
        >
          <Modal.Header>Select Field</Modal.Header>
          <Modal.Body className="overflow-y-auto">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="Model Field Name" />
              </div>
              <div className={errorTextClass}>
                {errors.name?.message && (
                  <p>{errors.name!.message.toString()}</p>
                )}
              </div>
              <TextInput
                id="text"
                placeholder="Enter Model Field Name"
                {...register("name")}
                required={true}
              />
            </div>
            <div className="my-4">
              <div className="mb-2 block">
                <Label htmlFor="type" value="Select Field Type" />
              </div>
              <div className={errorTextClass}>
                {errors.field?.message && (
                  <p>
                    {errors.field!.message.toString() == "Required"
                      ? "You must select a Field Type"
                      : errors.field!.message.toString()}
                  </p>
                )}
              </div>
              <FieldListSelect
                setSelectedField={setSelectedField}
                activeModelField={activeModelField}
                fieldListMap={FieldToSchemaMap}
                // selectedField={selectedField}
              />
            </div>
            {/* <div>
                <a
                  href="#"
                  className="inline-flex items-center text-xs font-normal text-gray-500 hover:underline dark:text-gray-400"
                >
                  Why do I need to connect with my wallet?
                </a>
              </div> */}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-full flex justify-end">
              <Button className="w-32" onClick={handleSubmit(onSubmit)}>
                {activeModelField === null ? "Add Field" : "Edit Field"}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </form>
    </>
  );
};

export { FieldListSelect, FieldSelectModal, SingleModelNode };
