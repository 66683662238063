import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Modal, TextInput } from "flowbite-react";
import { nanoid } from "nanoid";
import { FC, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ModelSchema, ModelType } from "../fields/field";
import { Action, useProjectStore } from "../model/store";
import { errorTextClass } from "../utils/contants";
import { z } from "zod";

interface IAddNewModelFormModal {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

const AddNewModelFormModal: FC<IAddNewModelFormModal> = (
  props: IAddNewModelFormModal,
): JSX.Element => {
  const upsertModel = useProjectStore((state) => state.upsertModel);
  const activeApp = useProjectStore((state) => state.activeApp);
  const activeModel = useProjectStore((state) => state.activeModel);
  const setActiveModel = useProjectStore((state) => state.setActiveModel);

  const allowModelName = useProjectStore((state) => state.allowModelName);

  const onSubmit: SubmitHandler<ModelType> = (data) => {
    if (activeApp !== null) {
      if (activeModel === null) {
        upsertModel({
          model: ModelSchema.parse({
            modelId: nanoid(),
            appId: activeApp.appId,
            name: data.name,
          }),
          action: Action.ADD,
        });
      } else {
        upsertModel({
          model: ModelSchema.parse({
            ...activeModel,
            ...{ name: data.name },
          }),
          action: Action.EDIT,
        });
      }
      reset();
      props.setOpenModal(false);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ModelType>({
    resolver: zodResolver(
      ModelSchema.pick({ name: true }).superRefine((entries, ctx) => {
        if (
          activeModel == null &&
          !allowModelName({ modelName: entries.name, app: activeApp! })
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              "Name already exist, no duplicate model allowed within app.",
            path: ["name"],
          });
        }
      }),
    ),
  });

  useEffect(() => {
    if (activeModel !== null) {
      setValue("name", activeModel.name);
    } else {
      reset();
    }
  }, [activeModel, setValue, reset]);

  return (
    <>
      <Modal
        show={props.openModal}
        size="md"
        popup
        onClose={() => {
          props.setOpenModal(false);
          setActiveModel(null);
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6 text-gray-900 dark:text-white">
            <h4 className="text-xl font-medium">
              {activeModel ? "Edit" : "New"} Model
            </h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                id="text"
                placeholder="Model Name"
                defaultValue={activeModel ? activeModel.name : ""}
                {...register("name")}
                required={true}
              />

              <div className={errorTextClass}>
                {errors.name?.message && (
                  <p>{errors.name!.message.toString()}</p>
                )}
              </div>

              <Button className="w-full my-3" type="submit">
                {activeModel ? "Update Model" : "Create Model"}
              </Button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { AddNewModelFormModal };
