import {
  Button,
  CustomFlowbiteTheme,
  Sidebar,
  useThemeMode,
} from "flowbite-react";
import { useState } from "react";
import { FaCircle } from "react-icons/fa";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import { HiPencilAlt, HiPlus } from "react-icons/hi";
import { MdSettingsSuggest } from "react-icons/md";
import { TbLetterM } from "react-icons/tb";
import { twMerge } from "tailwind-merge";
import { ProjectConfigSchema } from "../fields/field";
import { useModalStore, useProjectStore } from "../model/store";
import { AUTH_APP_ID, AUTH_APP_MODEL_ID, getAppColor } from "../utils/utils";
import { ProjectNameForm } from "./acceptproject";
import { AddNewAppForm } from "./add_new_app";
import { AddNewModelFormModal } from "./add_new_model";
import { NeedHelpAlert } from "./need_help";

const customSideBarTheme: CustomFlowbiteTheme["sidebar"] = {
  root: {
    collapsed: {
      on: "w-0",
      off: "w-64",
    },
    inner:
      "h-full overflow-y-auto overflow-x-hidden bg-gray-100 py-3 px-3 dark:bg-gray-800",
  },
  item: {
    icon: {
      base: "h-4 w-4",
    },
  },
  itemGroup: {
    base: "mt-4 border-t border-gray-200 pt-2 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700",
  },
};

function SideBar() {
  const project = useProjectStore((state) => state.project);
  const isEditMode = useProjectStore((state) => state.isEditMode);

  const setActiveApp = useProjectStore((state) => state.setActiveApp);
  const themeMode = useThemeMode();

  const [openUpsertModelModal, setOpenUpsertModelModal] = useModalStore(
    (state) => [state.openUpsertModelModal, state.setOpenUpsertModelModal],
  );

  const setOpenUpsertAppModal = useModalStore(
    (state) => state.setOpenUpsertAppModal,
  );

  const setOpenUpsertProjectModal = useModalStore(
    (state) => state.setOpenUpsertProjectModal,
  );

  const setActiveModel = useProjectStore((state) => state.setActiveModel);

  const setOpenConfigDrawer = useModalStore(
    (state) => state.setOpenConfigDrawer,
  );

  const [collapseSidebar, setCollapseSidebar] = useState(false);

  const appsList = project.apps.map((app, index) => {
    const appName = app.name;
    const color = getAppColor(index);

    return (
      <Sidebar.ItemGroup className="truncate" key={app.appId}>
        <Sidebar.Item
          icon={() => (
            <span
              style={{
                color: themeMode.mode === "dark" ? color.dark : color.light,
              }}
            >
              <FaCircle />
            </span>
          )}
          className="items-center truncate"
          key={app.appId + "-name"}
        >
          <div className="flex justify-between items-center">
            <p className="truncate text-sm">{appName}</p>
            <div className="cursor-pointer">
              {app.appId != AUTH_APP_ID && (
                <HiPencilAlt
                  size={18}
                  onClick={() => {
                    setActiveApp(app);
                    setOpenUpsertAppModal(true);
                  }}
                />
              )}
            </div>
          </div>
        </Sidebar.Item>
        {app.models.map((model) => {
          return (
            <Sidebar.Item
              icon={TbLetterM}
              className="items-center pl-8"
              key={model.modelId}
            >
              <div className="flex justify-between items-center">
                <p className="truncate text-sm">{model.name}</p>
                <div className="cursor-pointer">
                  {model.modelId != AUTH_APP_MODEL_ID && (
                    <HiPencilAlt
                      size={18}
                      onClick={() => {
                        setActiveApp(app);
                        setActiveModel(model);
                        setOpenUpsertModelModal(true);
                      }}
                    />
                  )}
                </div>
              </div>
            </Sidebar.Item>
          );
        })}
        {app.appId != AUTH_APP_ID && (
          <Sidebar.Item
            onClick={() => {
              setOpenUpsertModelModal(true);
              setActiveApp(app);
              setActiveModel(null);
            }}
            icon={HiPlus}
            className="cursor-pointer pl-8"
            key={app.appId + "-add"}
          >
            <p className="text-sm">Add Model</p>
          </Sidebar.Item>
        )}
      </Sidebar.ItemGroup>
    );
  });

  return (
    <div className={twMerge(["h-full flex", collapseSidebar ? "w-6" : "w-72"])}>
      {!collapseSidebar && (
        <Sidebar theme={customSideBarTheme} collapsed={collapseSidebar}>
          <Sidebar.Items>
            <div className="flex gap-1 items-center">
              <div className="grow w-3/4">
                <ProjectNameForm />
              </div>
              {project.name && (
                <div className="flex items-center gap-3">
                  {!isEditMode && (
                    <HiPencilAlt
                      size={18}
                      onClick={() => {
                        setOpenUpsertProjectModal(true);
                      }}
                    />
                  )}
                  <Button
                    size="xs"
                    onClick={() => {
                      setOpenConfigDrawer({
                        open: true,
                        type: "PROJECT",
                        configSchema: ProjectConfigSchema,
                        config: project.config ?? ProjectConfigSchema.parse({}),
                      });
                    }}
                  >
                    <MdSettingsSuggest size={12} />
                  </Button>
                </div>
              )}
            </div>
            <Sidebar.ItemGroup>
              <AddNewAppForm />
              {appsList}
            </Sidebar.ItemGroup>
          </Sidebar.Items>
          <div className="mt-48">
            <NeedHelpAlert />
          </div>

          <AddNewModelFormModal
            openModal={openUpsertModelModal}
            setOpenModal={setOpenUpsertModelModal}
          />
        </Sidebar>
      )}

      <div
        className="h-full w-4 bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 hover:dark:bg-gray-700 flex flex-col items-center justify-center cursor-pointer"
        onClick={() => setCollapseSidebar(!collapseSidebar)}
      >
        {collapseSidebar ? (
          <FaAnglesRight size={13} />
        ) : (
          <FaAnglesLeft size={13} />
        )}
      </div>
    </div>
  );
}

export { SideBar };
